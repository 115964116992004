import {MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatTabChangeEvent} from '@angular/material';
import {ChangeDetectorRef, Component, Inject, NgZone, ViewChild} from '@angular/core';
import {ActionItem} from "../_models/action";
import {MuuService} from "../_services/muu.service";
import {Szene} from "../_models/szene";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Bedingung} from "../_models/bedingung";
import {Sensor} from "../_models/sensor";
import {Aktion} from "../_models/aktion";


@Component({
  selector: 'modb-aktionen-dialog',
  template: `
    <h1 matDialogTitle>{{titleValue}}</h1>
    <mat-tab-group style="margin-top: 16px;" [(selectedIndex)]="aktion.typ_trigger" (selectedTabChange)="onLinkClick($event)">
      
      
      <!--Schalte Aktor X EIN/AUS-->
      <!--Sende Meldung PUSH/EMail-->
        <!--Sende Tageszusammenfassung-->
        <!--Sende Sensorwert-->
        <!--Sende Custom-Text-->
      <!--Setze Variable (virtuelle Variable: später)-->
      <!--Ändere Sensorintervall von Sensor X auf Y-->
      
      
      <mat-tab label="Aktor">
        <div class="flex-column" style="margin-top: 16px;">
          <div class="flex-row" style="align-items: center">            
            <mat-form-field style="margin-right: 8px;" flex-item>
              <mat-select [(ngModel)]="aktion.id_trigger" placeholder="">
                <mat-option *ngFor="let aktor of aktoren" [value]="aktor.id_sensoren">
                  {{ aktor.name_sensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-right: 8px;" flex-item>
              <mat-select #select2="matSelect" [(ngModel)]="aktion.trigger_port" placeholder="" (selectionChange)="calcHelper()">
                <mat-option [value]="0">einschalten</mat-option>
                <mat-option [value]="1">ausschalten</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Benachrichtigung">
        <!--TODO - Nur Uhrzeit dieser Szene erlauben?!-->
        <mat-form-field flex-item style="width: 175px !important;">
          <input matInput placeholder="Benachrichtigung via" disabled>
        </mat-form-field>

        <mat-form-field style="margin-right: 8px;">
          <mat-select [(ngModel)]="aktion.trigger_port" (selectionChange)="calcHelper()">
            <mat-option [value]="100">Push-Benachrichtigung</mat-option>
            <mat-option [value]="101">E-Mail</mat-option>
          </mat-select>
        </mat-form-field>
        
        <div *ngIf="aktion.trigger_port == 101" class="flex-row" style="align-items: center">
          <mat-form-field flex-item style="width: 175px !important;">
            <input matInput placeholder="Mailadresse:" disabled>
          </mat-form-field>
          <mat-form-field style="width:230px;">
            <input [(ngModel)]="aktion.trigger_name" matInput type="email">
            <!--<input (change)="calcHelper();" [(ngModel)]="aktion.trigger_value" matInput type="number">-->
          </mat-form-field>
          <span class="flex-item"></span>
          <button color="primary" mat-raised-button (click)="sendTestMail()">
            Testmail senden<mat-icon class="example-icon">mail</mat-icon>
          </button>
          
        </div>
      </mat-tab>
    </mat-tab-group>
    
    <mat-dialog-actions>
      <button mat-icon-button (click)="dialogRef.close(true)">
        <mat-icon style="color: #CCC">delete</mat-icon>
      </button>
      <span class="flex-item"></span>
      <button *ngIf="!hideCancel" mat-button (click)="dialogRef.close(false)">{{cancel_value}}</button>
      <button *ngIf="!hideSave" mat-button (click)="dialogRef.close(returnBedingung())">{{ok_value}}</button>
    </mat-dialog-actions>
  `,
})

export class AktionenDialogComponent {
  @ViewChild('select') private select: MatSelect;
  @ViewChild('select2') private select2: MatSelect;
  sensoren: Sensor[] = [];
  aktoren: Sensor[] = [];
  aktion: Aktion;
  titleValue: string;
  editvalue: string;
  description: string;
  color: string;
  placeholder = '';
  time_value = "00:00";
  time_minutes = 0;

  hideSave: boolean = false;
  hideCancel: boolean = false;
  cancel_value = 'Abbrechen';
  ok_value = 'Erstellen';
  szene: Szene;
  helper = "ist, dann...";
  option0 = "";
  option1 = "";
  option2 = "";
  option102 = "";
  option103 = "";
  option104 = "";

  constructor(
    public dialogRef: MatDialogRef<AktionenDialogComponent>,
    public muuService: MuuService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.aktion = data.aktion;
    this.sensoren = data.sensoren ? data.sensoren : [];
    this.aktoren = data.aktoren ? data.aktoren : [];
    this.ok_value = data.ok_value ? data.ok_value : "Erstellen";
    this.cancel_value = data.cancel_value ? data.cancel_value : "Abbrechen";
    this.titleValue = data.titleValue ? data.titleValue : "";
    this.hideSave = data.hideSave ? data.hideSave : false;
    this.hideCancel = data.hideCancel ? data.hideCancel : false;
    this.placeholder = data.placeholder ? data.placeholder : "";
    this.editvalue = data.editvalue ? data.editvalue : "";
    this.color = data.color ? data.color : "";
    this.description = data.description ? data.description : "";
    this.calcHelper();
  }
  ngOnInit() {
    console.log('onInit dialog: '+JSON.stringify(this.aktion));
    this.calcHelper();
  }
  calcHelper() {
    if (this.aktion.typ_trigger == 0) { //AKTOREN
    } else if (this.aktion.typ_trigger == 1) { //Benachrichtigungen
    }
  }

  sendTestMail() {
    if (this.aktion.typ_trigger == 0) { //AKTOREN
    } else if (this.aktion.typ_trigger == 1) { //Benachrichtigungen

      console.log('sendTestMail: '+JSON.stringify(this.aktion));
      this.muuService.sendTestMailFromAktion(this.aktion)
        .subscribe(result => {
            let newAktion = result;
            console.log('sendTestMail result : '+JSON.stringify(newAktion));
          },
          err => {
            console.error('There was an error sendTestMail: ' + err);
          });
    }
  }

  onLinkClick(event: MatTabChangeEvent) {
    this.calcHelper();
    console.log('tabChange: '+this.aktion.typ_trigger);
  }

  returnBedingung() {
    console.log('return aktion: '+JSON.stringify(this.aktion));
    return this.aktion;
  }
}
