import { Injectable, EventEmitter } from '@angular/core';
import { webSocket } from 'rxjs/webSocket'
import {WebSocketSubject} from "rxjs/internal/observable/dom/WebSocketSubject";
import {Observable, Observer, Subject, Subscription} from "rxjs/Rx";
interface Frame {
  type: string;
  data: any;
};

enum MessageType {
  Chat = 1,
  Nick,
  Error,
}

export interface Message {
  msgType(): MessageType;
  toString(): string;
}

export class ChatMessage implements Message {
  author: string;
  body: string;

  msgType(): MessageType {
    return MessageType.Chat;
  }

  toString(): string {
    return this.author + ': ' + this.body;
  }
}

export class NickMessage implements Message {
  oldName: string;
  newName: string;

  msgType(): MessageType {
    return MessageType.Nick;
  }

  toString(): string {
    if (this.newName === '' && this.oldName !== '') {
      return this.oldName + ' has left the channel';
    } else if (this.oldName !== '') {
      return this.oldName + ' has changed nick to ' + this.newName;
    }  else {
      return this.newName + ' has joined the channel';
    }
  }
}

export class ErrorMessage implements Message {
  errorCode: string;
  message: string;

  msgType(): MessageType {
    return MessageType.Error;
  }

  toString(): string {
    return 'Error (' + this.errorCode + '): ' + this.message;
  }
}

@Injectable()
export class SocketService {
  private socket: WebSocket;
  private listener: EventEmitter<any> = new EventEmitter();
  // private subject: WebSocketSubject<any>;
  private connected = false;
  public messages: Observable<Message>;
  private ws: Subject<any>;

  public constructor() {
    console.log('constructor SocketService');

    // this.ws = Observable.webSocket('wss://app.muucare.at/ws');
    // this.messages = makeHot(this.ws); //.map(parseFrame);//.filter(m => m != null);
    // this.ws.subscribe(
    //   (msg) => {
    //     console.log('sub message received: ' + JSON.stringify(msg));
    //
    //   },
    //   (err) => {
    //     console.error(err)
    //     //TODO Handle error - eventuell reconnect?!
    //   },
    //   () => console.log('sub complete')
    // );

    // this.subject = webSocket('wss://app.muucare.at/ws');
    this.subscribeToSocket();
  }

  private subscribeToSocket() {
    if (!this.connected) {
      const self = this;
      if (this.socket != null) {
        try {
          this.socket.close();
        } catch (Exception) {
        }
      }
      this.socket = new WebSocket("wss://fuchs.petermarkl.com/ws");

      this.socket.onopen = event => {
        this.connected = true;
        this.listener.emit({"type": "open", "data": event});
      };

      this.socket.onclose = event => {
        this.listener.emit({"type": "close", "data": event});
        this.connected = false;
        //TODO - try reconnect!
        setTimeout(function() {
          // Your code here
          self.checkConnection();
        }, 1000);
      };
      this.socket.onmessage = event => {
        this.listener.emit({"type": "message", "data": JSON.parse(event.data)});
      };
    } else {
      //TODO - eventuell Testnachricht schicken?

    }
    // console.log('subscribeToSocket hasError: ' + this.subject.hasError);
    // console.log('subscribeToSocket isStopped: ' + this.subject.isStopped);
    // console.log('subscribeToSocket closed: ' + this.subject.closed);

    // if (!this.connected) {
    //   this.connected = true;
    //   this.subject.subscribe(
    //     (msg) => {
    //       console.log('sub message received: ' + JSON.stringify(msg));
    //
    //     },
    //     (err) => {
    //       console.error(err)
    //       //TODO Handle error - eventuell reconnect?!
    //     },
    //     () => console.log('sub complete')
    //   );
    // }
  }

  public checkConnection() {
    console.log('checkConnection: '+this.connected);
    this.subscribeToSocket();
  }

  public send(data: string) {
    this.socket.send(data);
  }

  public close() {
    this.socket.close();
  }

  public getEventListener() {
    return this.listener;
  }

}

function parseFrame(frame: Frame): Message {
  if (frame.type === 'ChatMessage') {
    let msg = new ChatMessage();
    msg.author = frame.data.author;
    msg.body = frame.data.body;
    return msg;
  } else if (frame.type === 'NickMessage') {
    let msg = new NickMessage();
    msg.oldName = frame.data.old_name;
    msg.newName = frame.data.new_name;
    return msg;
  } else if (frame.type === 'ErrorMessage') {
    let msg = new ErrorMessage();
    msg.errorCode = frame.data.error_code;
    msg.message = frame.data.message;
    return msg;
  }
  return null;
}

function makeHot<T>(cold: Observable<T>): Observable<T> {
  let subject = new Subject();
  let refs = 0;
  return Observable.create((observer: Observer<T>) => {
    let coldSub: Subscription;
    if (refs === 0) {
      coldSub = cold.subscribe(o => subject.next(o));
    }
    refs++;
    let hotSub = subject.subscribe(observer);
    return () => {
      refs--;
      if (refs === 0) {
        coldSub.unsubscribe();
      }
      hotSub.unsubscribe();
    };
  });
}
