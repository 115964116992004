import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule, MatInputModule, MAT_DIALOG_DATA} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';
import {ActionItem} from "../_models/action";


declare var d3: any;

@Component({
  selector: 'modb-alarme',
  templateUrl: './alarme.component.html',
  styleUrls: ['./alarme.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})
export class AlarmeComponent implements OnInit {
  toggleval = 'standard';
  thi_actions: ActionItem[] = [];
  temp_actions: ActionItem[] = [];
  hum_actions: ActionItem[] = [];
  vent_actions: ActionItem[] = [];


  constructor(public muuService: MuuService,
              private router: Router,
              private headerService: HeaderService,
              public dialog: MatDialog) {

    this.thi_actions = [];
    this.muuService.addActionItem(this.thi_actions, 1, "primary", "PUSH-Nachricht");
    this.muuService.addActionItem(this.thi_actions, 2, "primary", "Ventilator 15 Minuten");
    this.vent_actions = [];
    this.muuService.addActionItem(this.vent_actions, 1, "primary", "PUSH-Nachricht");
    this.muuService.addActionItem(this.vent_actions, 3, "warn", "Ventilator ausschalten");
  }


  addThiAction(): void {
    this.addAction(this.thi_actions);
  }
  addTempAction(): void {
    this.addAction(this.temp_actions);
  }
  addHumAction(): void {
    this.addAction(this.hum_actions);
  }
  addVentAction(): void {
    this.addAction(this.vent_actions);
  }

  addAction(list): void {
    let dialogRef = this.dialog.open(AddActionDialog, {
      width: '250px',
      data: { list: list }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: '+result);
      if (result != null) {
        list.push(result);
      }
    });
  }

  removeAction(action : ActionItem, list :ActionItem[]) {
    // list.remove(action);
    list.splice(list.indexOf(action),1);
  }

  einschalten(value) {
    console.log('einschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 1;
  }
  ausschalten(value) {
    console.log('ausschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 0;
  }

  ngOnInit() {
    window.addEventListener('resize', function () {
      // self.plotData();
    });
    const headerData = new HeaderData();
    headerData.title = 'Alarme und Grenzwerte';
    headerData.id = 2;
    this.headerService.headerData.next(headerData);
  }
  openSensor(sensor) {
    console.log('openSensor event: ', sensor.id_sensoren);
    this.router.navigate(['/statistic/'+sensor.id_sensoren]);
  }
}

@Component({
  selector: 'add-action-dialog',
  template: `
    <h1 matDialogTitle>Aktion hinzufügen</h1>
    <div mat-dialog-content>
      <mat-chip-list class="mat-chip-list-stacked">
        <mat-chip *ngFor="let action of available_actions"
                 selected="true"
                 selectable="true"
                 removable="false"
                 (click)="dialogRef.close(action)"
                 color="{{action.color}}">
          {{action.name}}
        </mat-chip>
      </mat-chip-list>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="dialogRef.close(null)" tabindex="-1">Abbrechen</button>
    </div>

  `,
})

export class AddActionDialog {
  available_actions: ActionItem[] = [];

  constructor(
    public muuService: MuuService,
    public dialogRef: MatDialogRef<AddActionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.available_actions = [];

    let makePush = true;
    let make15 = true;
    let makeAus = true;
    let makeMail = true;
    let makeEin = true;

    data.list.forEach(function(action) {
      if (action.id == 1) makePush = false;
      if (action.id == 2) make15 = false;
      if (action.id == 3) {
        makeAus = false;
        makeEin = false;
      }
      if (action.id == 4) makeMail = false;
      if (action.id == 5) {
        makeEin = false;
        makeAus = false;
      }
    });

    if(makePush) this.muuService.addActionItem(this.available_actions, 1, "primary", "PUSH-Nachricht");
    if(make15) this.muuService.addActionItem(this.available_actions, 2, "primary", "Ventilator 15 Minuten");
    if(makeAus) this.muuService.addActionItem(this.available_actions, 3, "warn", "Ventilator ausschalten");
    if(makeEin) this.muuService.addActionItem(this.available_actions, 5, "primary", "Ventilator einschalten");
    if(makeMail) this.muuService.addActionItem(this.available_actions, 4, "primary", "E-Mail");
  }
  //
  // addActionItem(list, id, color, name) {
  //   let menu_item = new ActionItem();
  //   menu_item.id = id;
  //   menu_item.color = color;
  //   menu_item.name = name;
  //   list.push(menu_item);
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addAction(action): void {

    this.muuService.addActionItem(this.available_actions, action.id, action.color, action.name);
    this.dialogRef.close();
  }
}