import { Input } from '@angular/core';
import {Gateway} from './gateway';
import {SensorData} from "./sensorData";

export class BeeChart {
  chart_id: string;
  gateways: Gateway[];
  sensor_data: SensorData[];

  constructor (chartid: string) {
    this.chart_id = chartid;
  }


  // import {addPathToRoutes} from '@angular/cli/lib/ast-tools';
}
