import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AuthService} from '../auth/auth.service';
/**
 * Created by b3da on 01.06.17.
 */

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [ // components that we want to make available
  ],
  declarations: [ // components for use in THIS module
  ],
  providers: [ // singleton services
    AuthService,
  ]
})
export class ServiceModule { }
