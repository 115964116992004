import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {SigninComponent} from './auth/signin/signin.component';
import {AuthGuard} from './auth/auth-guard.service';
import {SensorComponent} from './sensor/sensor.component';
import {DashComponent} from './dash/dash.component';
import {AppComponent} from './app.component';
import {StatisticComponent} from './statistic/statistic.component';
import {SensorenComponent} from "./sensoren/sensoren.component";
import {AktorenComponent} from "./aktoren/aktoren.component";
import {AlarmeComponent} from "./alarme/alarme.component";
import {TimersComponent} from "./zeitsteuerung/timers.component";
import {SettingsComponent} from "./settings/settings.component";
import {DetailsComponent} from "./details/details.component";
import {EventsComponent} from "./events/events.component";

const appRoutes: Routes = [
  { path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dash',
        component: DashComponent,
      },{
        path: 'sensoren',
        component: SensorenComponent,
      },{
        path: 'aktoren',
        component: AktorenComponent,
      },{
        path: 'alarme',
        component: AlarmeComponent,
      },{
        path: 'events',
        component: EventsComponent,
      },{
        path: 'timers',
        component: TimersComponent,
      },{
        path: 'einstellungen',
        component: SettingsComponent,
      },{
        path: 'regler',
        component: SensorComponent,
        children: [
        {
          path: ':id',
          component: SensorComponent }
      ] },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
        data: {message: 'Page not found!'} },
      {
        path: 'statistic',
        component: StatisticComponent},
      {
        path: 'statistic/:id',
        component: StatisticComponent},
      {
        path: 'details/:id',
        component: DetailsComponent},
    ] },
  { path: 'signin', component: SigninComponent },
  { path: 'not-found', component: PageNotFoundComponent, data: {message: 'Page not found!'} },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
