import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from "../_services/muu.service";
import {MenuItem} from "../_models/menu";
import {Bedingung} from "../_models/bedingung";
import {Regel} from "../_models/regel";
import {RegelungsDialogComponent} from "../dialogs/regelungs-dialog.component";
import {Aktion} from "../_models/aktion";
import {MatDialog} from "@angular/material";
import {SensorStatus} from "../_models/sensorStatus";
import {QuestionDialogComponent} from "../dialogs/question-dialog.component";
import {AktionenDialogComponent} from "../dialogs/aktionen-dialog.component";

@Component({
  selector: 'modb-regel',
  templateUrl: './regel.component.html',
  styleUrls: ['./regel.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RegelComponent implements OnInit {
  @Input() regel: Regel;
  @Input() reihenfolge: number;
  @Output() editRegel: EventEmitter<any> = new EventEmitter();
  @Output() deleteRegel: EventEmitter<any> = new EventEmitter();
  @Output() deselectRegel: EventEmitter<any> = new EventEmitter();

  hovered = false;
  constructor(
    public dialog: MatDialog,
    private muuService: MuuService) {
  }
  ngOnInit() {
  }

  editThisRegel() {
    this.editRegel.emit(this.regel);
  }
  askDeleteRegel() {
    this.deleteRegel.emit(this.regel);
  }
  deselectThisRegel($event) {
    $event.stopPropagation();
    this.deselectRegel.emit(this.regel);
  }

  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }


  addBedingung(event) {
    // console.log('addRegelung: '+JSON.stringify(szene));

    const bedingung = new Bedingung();
    bedingung.id_regel = this.regel.id_regel;
    bedingung.id_trigger = 0;
    bedingung.typ_trigger = 0;
    bedingung.trigger_value = 0;
    bedingung.trigger_port = 0;
    bedingung.trigger_operator = 0;
    bedingung.operator = 0;
    bedingung.level = 0;
    bedingung.reihenfolge = 0;
    if (this.regel.bedingungen) {
      bedingung.reihenfolge = this.regel.bedingungen.length;
    } else {
      this.regel.bedingungen = [];
    }
    const self = this;

    this.muuService.createBedingung(bedingung)
      .subscribe(result => {
          let newBedingung = result;
          console.log('Bedingung: '+JSON.stringify(newBedingung));
          bedingung.id_bedingung = newBedingung.id_bedingung;
          this.regel.bedingungen.push(bedingung);
          //Bedingungen für aktive Regel updaten!
          this.editBedingung(bedingung);
        },
        err => {
          console.error('There was an error createBedingung: ' + err);
        });
  }

  editBedingung(bedingung) {
    // console.log('editBedingung: '+JSON.stringify(bedingung));
    const self = this;
    let sensoren = [];
    let aktoren = [];
    // console.log('constructor gateways : ', this.gateways);
    if (this.muuService.sensors != null) {
      this.muuService.sensors.forEach(function(sensor) {
          if (sensor.typ_sensor == 2) {
            aktoren.push(sensor);
          } else {
            sensoren.push(sensor);
          }
      });
    }
    const dialogRef = this.dialog.open(RegelungsDialogComponent, {
      width: "700px",
      data: {
        bedingung: bedingung,
        sensoren: sensoren,
        aktoren: aktoren,
        hasList: false,
        ok_value: "Speichern",
        titleValue: "Bedingung definieren:"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.askDeleteBedingung(bedingung);
      } else if (result != false) {
        // console.log('editBedingung:' + result);
        const newBed = result as Bedingung;


        let saveBedingung = result;
        this.muuService.updateBedingung(saveBedingung)
          .subscribe(bed => {
              // console.log('updateBedingung ende: '+JSON.stringify(bed));
              //Bedingung neu laden!!
              bedingung.typ_trigger  = newBed.typ_trigger;
              bedingung.trigger_value  = newBed.trigger_value;
              bedingung.trigger_port  = newBed.trigger_port;
              bedingung.trigger_operator  = newBed.trigger_operator;
              bedingung.id_trigger  = newBed.id_trigger;
              bedingung.operator  = newBed.operator;
              self.muuService.setBeschriebung(bedingung);
            },
            err => {
              console.error('There was an error (updateBedingung): ' + err);
            });
      }
    });
  }

  askDeleteBedingung(bedingung) {
    console.log('askDeleteBedingung: '+JSON.stringify(bedingung));
    const self = this;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        question: "Soll diese Bedingung wirklich gelöscht werden?",
        question_title: "Bedingung löschen",
      }
    });
    const idMySzene = bedingung.id_bedingung;
    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      if (result == true) {
        console.log('askDeleteBedingung id:' + bedingung.id_bedingung);
        this.muuService.deleteBedingung(bedingung)
          .subscribe(ergebnis => {
              for (let bed of this.regel.bedingungen) {
                if (bedingung.id_bedingung == bed.id_bedingung) {
                  var index = this.regel.bedingungen.indexOf(bed);
                  if (index > -1) {
                    console.log('deleteBedingung index: '+index);
                    this.regel.bedingungen.splice(index, 1);
                  }
                  break;
                }
              }
            },
            err => {
              console.error('There was an error askDeleteRegel: ' + err);
            });
      }
    });
  }

  addAktion(event) {
    // console.log('addRegelung: '+JSON.stringify(szene));
    const aktion = new Aktion();
    aktion.id_regel = this.regel.id_regel;
    aktion.id_trigger = 0;
    aktion.typ_trigger = 0;
    aktion.trigger_value = 0;
    aktion.trigger_port = 0;
    aktion.delay = 0;
    aktion.level = 0;
    aktion.reihenfolge = 0;
    if (this.regel.aktionen) {
      aktion.reihenfolge = this.regel.aktionen.length;
    } else {
      this.regel.aktionen = [];
    }
    const self = this;

    this.muuService.createAktion(aktion)
      .subscribe(result => {
          let newAktion = result;
          console.log('aktion: '+JSON.stringify(newAktion));
          aktion.id_aktionen = newAktion.id_aktionen;
          this.regel.aktionen.push(aktion);
          this.editAktion(aktion);
        },
        err => {
          console.error('There was an error createAktion: ' + err);
        });
  }


  editAktion(aktion) {
    console.log('editAktion: '+JSON.stringify(aktion));

    const self = this;
    let sensoren = [];
    let aktoren = [];
    // console.log('constructor gateways : ', this.gateways);
    if (this.muuService.sensors != null) {
      this.muuService.sensors.forEach(function(sensor) {
        if (sensor.typ_sensor == 2) {
          aktoren.push(sensor);
        } else {
          sensoren.push(sensor);
        }
      });
    }
    const dialogRef = this.dialog.open(AktionenDialogComponent, {
      width: "700px",
      data: {
        aktion: aktion,
        sensoren: sensoren,
        aktoren: aktoren,
        hasList: false,
        ok_value: "Speichern",
        titleValue: "Aktion definieren:"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.askDeleteAktion(aktion);
      } else if (result != false) {
        // console.log('editAktion:' + result);
        let myAktion = result as Aktion;
        this.muuService.updateAktion(myAktion)
          .subscribe(bed => {
              // console.log('updateAktion ende: '+JSON.stringify(bed));
              aktion.typ_trigger  = myAktion.typ_trigger;
              aktion.trigger_value  = myAktion.trigger_value;
              aktion.trigger_port  = myAktion.trigger_port;
              aktion.delay  = myAktion.delay;
              aktion.id_trigger  = myAktion.id_trigger;
              aktion.level  = myAktion.level;
              aktion.message  = myAktion.message;
              self.muuService.setBeschreibungAktion(aktion);

            },
            err => {
              console.error('There was an error (updateAktion): ' + err);
            });
      }
    });
  }

  askDeleteAktion(aktion: Aktion) {
    console.log('askDeleteAktion: '+JSON.stringify(aktion));
    const self = this;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        question: "Soll diese Aktion wirklich gelöscht werden?",
        question_title: "Aktion löschen",
      }
    });
    const idMySzene = aktion.id_aktionen;
    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      console.log('askDeleteAktion:' + result);
      if (result == true) {
        this.muuService.deleteAktion(aktion)
          .subscribe(ergebnis => {
              console.log('DeleteAktion ende: '+JSON.stringify(ergebnis));
              for (let akt of this.regel.aktionen) {
                if (aktion.id_aktionen == akt.id_aktionen) {
                  var index = this.regel.aktionen.indexOf(akt);
                  if (index > -1) {
                    this.regel.aktionen.splice(index, 1);
                  }
                  break;
                }
              }
            },
            err => {
              console.error('There was an error DeleteAktion: ' + err);
            });
      }
    });
  }
}
