import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {HeaderService} from '../../_services/header.service';
import {Subscription} from 'rxjs/Subscription';
import {HeaderData} from '../../_models/headerData';
import {AuthService} from "../../auth/auth.service";
import {HeaderMenuComponent} from "../header_menu/headermenu.component";

@Component({
  selector: 'modb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnDestroy, OnInit  {
  subscription: Subscription;
  title = '1';
  menu_items: HeaderMenuComponent[] = [];

  constructor(
    public authService: AuthService,
    private headerService: HeaderService) {
  }

  ngOnInit() {
    this.subscription = this.headerService.headerData.subscribe(
      (headerData: HeaderData) => {
        this.title = headerData.title;
        this.menu_items = headerData.menu_items;
        // console.log('headerdata: '+headerData.title);
      });
  }

  openMenu(menu) {
    // console.log('openMenu: '+JSON.stringify(menu));
    menu.action();
    // for (var i = 0, l = this.menu_items.length; i < l; i++) {
    //   var menu_item = this.menu_items[i];
    //   // menu_item.openMenu.emit(1);
    //   console.log('menu_item: '+JSON.stringify(menu_item));
    // }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
  onLogout() {
    this.authService.logout();
  }
}
