import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {AppConfig} from '../app.config';
import {AuthService} from '../auth/auth.service';
import {BeeEvent} from '../_models/events';
import {Sensor} from "../_models/sensor";
import {Gateway} from "../_models/gateway";
import {SensorData} from "../_models/sensorData";
import {Weather} from "../_models/weather/weather";
import {SensorStatus} from "../_models/sensorStatus";
import * as moment from 'moment';
import {timeHours, timeMinutes} from "d3-time";
import {MenuItem} from "../_models/menu";
import {ActionItem} from "../_models/action";
import {Regler} from "../_models/regler";
import {Szene} from "../_models/szene";
import {Regel} from "../_models/regel";
import {Bedingung} from "../_models/bedingung";
import {Aktion} from "../_models/aktion";
import {AktorData} from "../_models/aktorData";

@Injectable()
export class MuuService {
  gateways: Gateway[] = [];
  sensors: Sensor[] = [];
  regler: Regler[] = [];
  weather: Weather;
  sensorStatus: SensorStatus;
  started_dash = false;
  t_start = 0;
  t_end = 0;
  sensorVal = 0;
  server_offset: number = null;


  constructor(
    private http: Http,
    private router: Router,
    private authenticationService: AuthService,
    private config: AppConfig) {

    this.started_dash = true;
    this.t_start = new Date().getTime();

    if (this.server_offset == null) {
      const params: URLSearchParams = new URLSearchParams();
      // workaround - regler is no class instance
      this.http.get(this.config.apiUrl + '/time/servertime',
        this.getOptions(params))
        .toPromise()
        .then(res => {
          let server_time = res.json();
          const timeFormat = 'YYYY-MM-DD HH:mm:ss';
          let now = new Date();

          var ms = moment(now,"YYYY-MM-DD HH:mm:ss").diff(moment(server_time,"YYYY-MM-DD HH:mm:ss"));
          var d = moment.duration(ms);
          this.server_offset = parseInt(d.asHours().toFixed(0));
          console.log('this.server_offset : '+this.server_offset);
          // this.server_offset = moment(res.json()); // .format(timeFormat).valueOf();
        })
        .catch((error: Response) => {
          console.error('server_offset error: '+error.status);
        });
    }


    console.log('getMyData muu.service');
    this.getMyData();
  }


  getGrundString(grund) {
    let result = "";
    if (grund == 0) {
      result = "Manuell";
    } else if (grund == 1) {
      result = "Regelung";
    } else if (grund == 2) {
      result = "";
    } else if (grund == 3) {
      result = "Umschaltung Modus";
    } else if (grund == 4) {
      result = "Aktor-Neustart";
    } else if (grund == 5) {
      result = "Max. Einschaltzeit";
    } else if (grund == 6) {
      result = "Sicherheitsnummer Reset";
    }
    return result;
  }

  addHeaderMenuItem(icon, name, path, selected, action, header_data) {
    let menu_item = new MenuItem();
    menu_item.selected = selected;
    menu_item.divider = false;
    menu_item.icon = icon;
    menu_item.name = name;
    menu_item.path = path;
    menu_item.action = action;
    header_data.menu_items.push(menu_item);
  }

  addActionItem(list, id, color, name) {
    let menu_item = new ActionItem();
    menu_item.id = id;
    menu_item.color = color;
    menu_item.name = name;
    list.push(menu_item);
  }

  isSensorOk(signal_time, sendezeit) {
    let timeOffset = new Date().getTimezoneOffset();
    // console.log("1: "+timeOffset);
    timeOffset = this.server_offset; //new Date().getTimezoneOffset();
    // console.log("2: "+timeOffset);
    // timeOffset = (timeOffset / 60) * -1;
    var d = moment(signal_time).add(timeOffset, 'hours'); // moment.duration(my_ms);
    var duration = moment.duration(moment(new Date()).diff(moment(d)));

    let offline_zeit = 2* (sendezeit/60) +5; //45 Minuten war Standard - jetzt sendezeit (in sekunden) * 2 + 5
    // console.log('signal_time: '+signal_time+ "  sendezeit: "+ sendezeit+" offline_zeit: "+offline_zeit);

    // if (duration.get("minutes") + duration.get("hours")*60+ duration.get("days")*60*24 > offline_zeit) {
    //   return false;
    // }
    return true;
  }

  getTimeString(mins) {
    var m = moment().utcOffset(0);
    m.set({hour:mins/60,minute:mins%60,second:0,millisecond:0})
    m.toISOString();
    m.format();
    return moment(m).format("HH:mm");
  }

  getTimeStringDesc(mins) {
    var m = moment().utcOffset(0);
    m.set({hour:mins/60,minute:mins%60,second:0,millisecond:0})
    m.toISOString();
    m.format();
    if (mins > 59) {
      return moment(m).format("HH")+" Stunden und "+moment(m).format("mm")+" Minuten";
    } else {
      return moment(m).format("mm")+" Minuten";
    }
  }

  getSensorName(bedingung: Bedingung) {
    let myName = "";
    if (bedingung.typ_trigger == 2) {
      myName = "Uhrzeit";
    } else if (this.sensors != null) {
      this.sensors.forEach(function (sensor) {
        if (bedingung.id_trigger == sensor.id_sensoren) {
          // console.log('gefunden id_sensoren: ', sensor.id_sensoren, " trigger: "+bedingung.id_trigger);
          myName = sensor.name_sensor;
        }
      });
    }
    return myName;
  }



  getSensorNameAktion(aktion: Aktion) {
    let myName = "";
    if (aktion.typ_trigger == 1) {
      myName = "Benachrichtigung";
    } else if (this.sensors != null) {
      this.sensors.forEach(function (sensor) {
        if (aktion.id_trigger == sensor.id_sensoren) {
          // console.log('gefunden id_sensoren: ', sensor.id_sensoren, " trigger: "+bedingung.id_trigger);
          myName = sensor.name_sensor;
        }
      });
    }
    return myName;
  }

  setBeschriebung(bedingung: Bedingung) {
    if (this != undefined) {

      let trigger_name = this.getSensorName(bedingung);
      bedingung.beschreibung = "";

      if (bedingung.typ_trigger == 0) {
        /* *************************************
          SENSOR
         */
        if (bedingung.trigger_port == 0) {
          bedingung.beschreibung += "die Temperatur";
        } else if (bedingung.trigger_port == 1) {
          bedingung.beschreibung += "die Luftfeuchtigkeit";
        } else if (bedingung.trigger_port == 2) {
          bedingung.beschreibung += "der THI-Wert";
        } else if (bedingung.trigger_port == 3) {
          bedingung.beschreibung += "der Lichtsensor";
        } else if (bedingung.trigger_port == 4) {
          bedingung.beschreibung += "die Lagesensoren";
        }

        bedingung.beschreibung += " von " + trigger_name;

        //Größer oder kleiner
        if (bedingung.operator == 1) {
          bedingung.beschreibung += " "+bedingung.trigger_value.toFixed(2);
        } else if (bedingung.operator == 0) {
          bedingung.beschreibung += " "+bedingung.trigger_value.toFixed(2);
        } else if (bedingung.operator == 6) {
          bedingung.beschreibung += " sich um +/-"+bedingung.trigger_value.toFixed(2);
        } else if (bedingung.operator == 2) {
          bedingung.beschreibung += " vollkommene Dunkelheit";
        } else if (bedingung.operator == 3) {
          bedingung.beschreibung += " geringe Helligkeit ";
        } else if (bedingung.operator == 4) {
          bedingung.beschreibung += " extreme Helligkeit ";
        } else if (bedingung.operator == 5) {
          bedingung.beschreibung += " einen stark veränderten Wert ";
        } else if (bedingung.operator == 10) {
          bedingung.beschreibung += " eine leichte Positionsveränderung ";
        } else if (bedingung.operator == 11) {
          bedingung.beschreibung += " eine mittlere Positionsveränderung ";
        } else if (bedingung.operator == 12) {
          bedingung.beschreibung += " eine starke Positionsveränderung ";
        }


        if (bedingung.trigger_port == 0) {
          bedingung.beschreibung += "°C ";
        } else if (bedingung.trigger_port == 1) {
          bedingung.beschreibung += "% ";
        } else if (bedingung.trigger_port == 2) {
          bedingung.beschreibung += " ";
        } else if (bedingung.trigger_port == 3) {
          bedingung.beschreibung += " misst";
        } else if (bedingung.trigger_port == 4) {
          bedingung.beschreibung += " messen";
        }

        if (bedingung.operator == 0) {
          bedingung.beschreibung += " überschreitet";
        } else if (bedingung.operator == 1) {
          bedingung.beschreibung += " unterschreitet";
        } else if (bedingung.operator == 6) {
          bedingung.beschreibung += " ändert";
        }


      } else if (bedingung.typ_trigger == 1) {
        /* *************************************
          AKTOR
         */
        bedingung.beschreibung += trigger_name;

        if (bedingung.trigger_port == 100) {
          bedingung.beschreibung += " eingeschalten wird";
        } else if (bedingung.trigger_port == 101) {
          bedingung.beschreibung += " ausgeschalten wird";
        } else if (bedingung.trigger_port == 102) {
          bedingung.beschreibung += " für "+this.getTimeStringDesc(bedingung.trigger_value)+" eingeschalten ist";
        } else if (bedingung.trigger_port == 103) {
          bedingung.beschreibung += " für "+this.getTimeStringDesc(bedingung.trigger_value)+" ausgeschalten ist";
        } else if (bedingung.trigger_port == 104) {
          bedingung.beschreibung += " für "+this.getTimeStringDesc(bedingung.trigger_value)+" offline ist";
        }
      } else if (bedingung.typ_trigger == 2) {
        /* *************************************
          UHRZEIT
         */
        if (bedingung.trigger_port == 200) {
          bedingung.beschreibung += "es vor "+this.getTimeString(bedingung.trigger_value)+" Uhr ist";
        } else if (bedingung.trigger_port == 201) {
          bedingung.beschreibung += "es nach "+this.getTimeString(bedingung.trigger_value)+" Uhr ist";
        } else if (bedingung.trigger_port == 202) {
          bedingung.beschreibung += "es genau "+this.getTimeString(bedingung.trigger_value)+" Uhr ist";
        } else {
          bedingung.beschreibung += "noch nicht definiert";
        }
      }
    }
  }

  setBeschreibungAktion(aktion: Aktion) {

    let trigger_name = this.getSensorNameAktion(aktion);
    aktion.beschreibung = "";

    if (aktion.typ_trigger == 0) {
      /* *************************************
        SENSOR
       */
      aktion.beschreibung += trigger_name+ " ";
      if (aktion.trigger_port == 0) {
        aktion.beschreibung += "einschalten";
      } else if (aktion.trigger_port == 1) {
        aktion.beschreibung += "ausschalten";
      }
    } else if (aktion.typ_trigger == 1) {
      /* *************************************
        Benachrichtigung
       */
      aktion.beschreibung += trigger_name+ " ";
      if (aktion.trigger_port == 100) {
        aktion.beschreibung += "via Push-Benachrichtigung";
      } else if (aktion.trigger_port == 101) {
        aktion.beschreibung += "via E-Mail ("+aktion.trigger_name+")";
      }
    }
  }




  getMyData() {

    // console.error('getMyData ');

    this.getDashData()
      .subscribe(gateways => {
          this.gateways = gateways;
          this.sensors = [];
          // console.log('constructor gateways : ', this.gateways);
          var self = this;


          this.sensorStatus = new SensorStatus();
          if (gateways != null) {
            gateways.forEach(function(gateway) {
              if (gateway.sensors != null) {
                gateway.sensors.forEach(function (sensor) {
                  self.sensors.push(sensor);
                  self.sensorStatus.addSensor(sensor, self);
                  if (sensor.typ_sensor == 2) {
                    self.sensorVal = sensor.status;
                    // console.log('self.sensorVal: ', self.sensorVal);
                  }
                });
              }
            });
          }
          // console.log('constructor getDashData : ', this.gateways);
          this.t_end = new Date().getTime();
          // console.log('this.t_end -  this.t_start: ', this.t_end -  this.t_start);
        },
        err => {
          this.started_dash = false;
          console.error('There was an error: ' + err);
          this.authenticationService.logout();
        });
    // this.getWeather();
  }


  getWeather() {
    let now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    //nur einmal pro Stunde wetterdaten holen!
    const weather_hour = parseInt(localStorage.getItem('weather_hour') || '0');
    const weather_day = parseInt(localStorage.getItem('weather_day') || '0');

    // console.log('hour: '+hour+' weather_hour: '+weather_hour);

    if ((hour > weather_hour+1) || (hour < weather_hour) || (weather_day != day)) {
      this.getWeatherData()
        .subscribe(weather => {
            // console.log('weather: ', weather);
            this.weather = weather;
            localStorage.setItem('weather', JSON.stringify(weather));
            localStorage.setItem('weather_hour', ''+hour);
            localStorage.setItem('weather_day', ''+day);
          },
          err => {
            console.error('There was an error: ' + err);
          });
    } else {
      this.weather = JSON.parse(localStorage.getItem('weather'));
      // console.log('weather!!: '+this.weather);
    }
  }


  getOptions(params): RequestOptions {
    // add authorization header with jwt token
    // console.log('token: '+this.authenticationService.token);
    const headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    const options = new RequestOptions({ headers: headers});
    // const options = new RequestOptions({ headers: headers, withCredentials: true });
    options.params = params;
    return options;
  }

  setAktorValue(sensor: Sensor) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('device_id', '' + sensor.device_id);
    console.log("status: "+sensor.status);

    if (sensor.status == 1) {
      params.set('value', '0');
      sensor.status = 2;
    } else if (sensor.status == 2) {
      params.set('value', '0');
      sensor.status = 2;
    } else if (sensor.status == 0) {
      params.set('value', '1');
      sensor.status = 3;
    } else if (sensor.status == 3) {
      params.set('value', '1');
      sensor.status = 3;
    }
    params.set('grund', '0');

    // workaround - regler is no class instance
    let ob = this.http.post(this.config.apiUrl + '/sensoren/switch',
      "",
      this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        return false;
      })
      .catch((error: Response) => {
        console.error('switch error: '+error.status);
        this.authenticationService.logout();
      });
  }


  switchAktorModus(sensor: Sensor) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('device_id', '' + sensor.device_id);
    params.set('value', '2');
    params.set('grund', '0');
    // workaround - regler is no class instance

    let ob = this.http.post(this.config.apiUrl + '/sensoren/switch',
      "",
      this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        return false;
      })
      .catch((error: Response) => {
        console.error('switch error: '+error.status);
        this.authenticationService.logout();
      });
  }


  registerPush(pushInfo) {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/push',
      pushInfo,
      this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        // console.log('registerPush ok: '+response);
        return false;
      })
      .catch((error: Response) => {
        console.error('registerPush error: '+error.status);
      });
  }

  setSensorName(sensor: Sensor): Promise<boolean> {
    const params: URLSearchParams = new URLSearchParams();
    params.set('sensor', '' + sensor.id_sensoren); // 96 = 2 Tage
    // workaround - regler is no class instance
    // const sens = new Sensor();
    // sens.id_sensoren = sensor.id_sensoren;
    // sens.id_gateways = sensor.id_gateways;
    // sens.name_sensor = sensor.name_sensor;
    // sens.signal_time = sensor.signal_time;

    const body = JSON.stringify(sensor);
    // console.log('json body: '+body);
    return this.http.post(this.config.apiUrl + '/sensoren/' + sensor.id_sensoren,
        body,
        this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        return false;
      })
      .catch((error: Response) => {
        console.error('setSensorName error: '+error.status);
        return false;
    });
  }



  createRegler(regler: Regler): Promise<boolean> {
    const params: URLSearchParams = new URLSearchParams();
    // params.set('regler', '' + regler.id_sensoren); // 96 = 2 Tage
    // // workaround - regler is no class instance
    const body = JSON.stringify(regler);
    return this.http.post(this.config.apiUrl + '/regler',
      body,
      this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        console.log("createRegler response: "+ response);
        return true;
      })
      .catch((error: Response) => {
        console.error('createRegler error: '+error.status);
        return false;
      });
  }
  updateRegler(regler: Regler): Observable<Regler[]> {
    const body = JSON.stringify(regler);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/regler/'+regler.id_regler,
      body,
      this.getOptions(params))
      .map(res => (res.json() as Regler[]))
      .catch(this.handleError);
  }
  deleteRegler(regler: Regler): Observable<any> {
    const body = JSON.stringify(regler);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.delete(this.config.apiUrl + '/regler/'+regler.id_regler,
      this.getOptions(params))
      .map(res => res.json())
      .catch(this.handleError);
  }

  /* **********************************
  // SZENEN
  ************************************/
  createSzene(szene: Szene): Observable<Szene[]> {
    const body = JSON.stringify(szene);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/szene',
      body,
      this.getOptions(params))
      .map(res => (res.json() as Szene[]))
      .catch(this.handleError);
  }
  updateSzene(szene: Szene): Observable<Szene[]> {
    const body = JSON.stringify(szene);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/szene/'+szene.id_szenen,
      body,
      this.getOptions(params))
      .map(res => (res.json() as Szene[]))
      .catch(this.handleError);
  }
  deleteSzene(szene: Szene): Observable<any> {
    const body = JSON.stringify(szene);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.delete(this.config.apiUrl + '/szene/'+szene.id_szenen,
      this.getOptions(params))
      .map(res => res.json())
      .catch(this.handleError);
  }
  getSzenen(): Observable<Szene[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/szene', this.getOptions(params))
      // .map((response: Response) => this.mapSzenen(response.json()))
      .map(res => (res.json() as Szene[]))
      .catch(this.handleError);
  }
  getSzenenByRegler(id_regler): Observable<Szene[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/szene/'+id_regler, this.getOptions(params))
      // .map(res => (res.json() as Szene[]))
      .map(res => Szene.fromJSONArray(res.json()))
      .catch(this.handleError);
  }

  /* **********************************
  // Regeln
  ************************************/
  createRegel(regel: Regel): Observable<Regel> {
    const body = JSON.stringify(regel);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/regel',
      body,
      this.getOptions(params))
      .map(res => (res.json() as Regel))
      .catch(this.handleError);
  }


  updateRegel(regel: Regel): Observable<Regel[]> {
    const body = JSON.stringify(regel);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/regel/'+regel.id_regel,
      body,
      this.getOptions(params))
      .map(res => (res.json() as Regel[]))
      .catch(this.handleError);
  }
  deleteRegel(regel: Regel): Observable<any> {
    const body = JSON.stringify(regel);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.delete(this.config.apiUrl + '/regel/'+regel.id_regel,
      this.getOptions(params))
      .map(res => res.json())
      .catch(this.handleError);
  }
  getRegeln(): Observable<Regel[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/regel', this.getOptions(params))
    // .map((response: Response) => this.mapSzenen(response.json()))
      .map(res => (res.json() as Regel[]))
      .catch(this.handleError);
  }
  getRegelnBySzene(id_szene): Observable<Regel[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/regel/'+id_szene, this.getOptions(params))
      .map(res => (res.json() as Regel[]))
      .catch(this.handleError);
  }

  /* **********************************
  // Bedingungen
  ************************************/
  createBedingung(bedingung: Bedingung): Observable<Bedingung> {
    const body = JSON.stringify(bedingung);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/bedingung',
      body,
      this.getOptions(params))
      .map(res => (res.json() as Bedingung))
      .catch(this.handleError);
  }
  updateBedingung(bedingung: Bedingung): Observable<Bedingung[]> {
    const body = JSON.stringify(bedingung);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/bedingung/'+bedingung.id_bedingung,
      body,
      this.getOptions(params))
      .map(res => (res.json() as Bedingung[]))
      .catch(this.handleError);
  }
  deleteBedingung(bedingung: Bedingung): Observable<any> {
    const body = JSON.stringify(bedingung);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.delete(this.config.apiUrl + '/bedingung/'+bedingung.id_bedingung,
      this.getOptions(params))
      .map(res => res.json())
      .catch(this.handleError);
  }
  getBedingungn(): Observable<Bedingung[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/bedingung', this.getOptions(params))
    // .map((response: Response) => this.mapSzenen(response.json()))
      .map(res => (res.json() as Bedingung[]))
      .catch(this.handleError);
  }
  getBedingungnByRegel(id_regel): Observable<Bedingung[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/bedingung/'+id_regel, this.getOptions(params))
      .map(res => (res.json() as Bedingung[]))
      .catch(this.handleError);
  }


  /* **********************************
  // Aktionen
  ************************************/
  createAktion(aktion: Aktion): Observable<Aktion> {
    const body = JSON.stringify(aktion);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/aktionen',
      body,
      this.getOptions(params))
      .map(res => (res.json() as Aktion))
      .catch(this.handleError);
  }
  updateAktion(aktion: Aktion): Observable<Aktion[]> {
    const body = JSON.stringify(aktion);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/aktionen/'+aktion.id_aktionen,
      body,
      this.getOptions(params))
      .map(res => (res.json() as Aktion[]))
      .catch(this.handleError);
  }
  deleteAktion(aktion: Aktion): Observable<any> {
    const body = JSON.stringify(aktion);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.delete(this.config.apiUrl + '/aktionen/'+aktion.id_aktionen,
      this.getOptions(params))
      .map(res => res.json())
      .catch(this.handleError);
  }
  sendTestMailFromAktion(aktion: Aktion): Observable<any> {
    const body = JSON.stringify(aktion);
    const params: URLSearchParams = new URLSearchParams();
    return this.http.post(this.config.apiUrl + '/aktionen/mail',
      body,
      this.getOptions(params))
      .map(res => (res.json()))
      .catch(this.handleError);
  }


  getAktionn(): Observable<Aktion[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/aktionen', this.getOptions(params))
    // .map((response: Response) => this.mapSzenen(response.json()))
      .map(res => (res.json() as Aktion[]))
      .catch(this.handleError);
  }
  getAktionnByRegel(id_regel): Observable<Aktion[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/aktionen/'+id_regel, this.getOptions(params))
      .map(res => (res.json() as Aktion[]))
      .catch(this.handleError);
  }
  
  

  getGateways(): Observable<Gateway[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/gateways', this.getOptions(params))
      .map(res => (res.json() as Gateway[]))
      .catch(this.handleError);
  }

  getSensorData(myLimit, mySensor): Observable<SensorData[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.set('days', '' + myLimit); // 96 = 2 Tage
    params.set('sensor', '' + mySensor);

    return this.http.get(this.config.apiUrl + '/sensordata', this.getOptions(params))
      .map(
        res => (res.json() as SensorData[])
      )
      .catch(this.handleError);
  }

  getAktorData(myLimit, mySensor): Observable<AktorData[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.set('days', '' + myLimit); // 96 = 2 Tage
    params.set('sensor', '' + mySensor);

    return this.http.get(this.config.apiUrl + '/aktordata', this.getOptions(params))
      .map(
        res => (res.json() as AktorData[])
      )
      .catch(this.handleError);
  }

  getSensorsDash(): Observable<Sensor[]> {
    console.log("getSensorsDash start");
    const params: URLSearchParams = new URLSearchParams();

    return this.http.get(this.config.apiUrl + '/sensoren', this.getOptions(params))
      .map(
        res =>
          (res.json() as Sensor[]))

      .catch(this.handleError);
  }

  getDashData(): Observable<Gateway[]> {
    const params: URLSearchParams = new URLSearchParams();

    this.http.get(this.config.apiUrl + '/sensoren', this.getOptions(params))
      .toPromise()
      .then((response: Response) => {
        // console.log('getDashData response: '+JSON.stringify(response));
        return false;
      });

    return this.http.get(this.config.apiUrl + '/sensoren', this.getOptions(params))
      .map(res => (res.json() as Gateway[]))
      .catch(this.handleError);
  }

  getRegler(): Observable<Regler[]> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/regler', this.getOptions(params))
      .map(res => (res.json() as Regler[]))
      .catch(this.handleError);
  }

  getWeatherData(): Observable<Weather> {
    const params: URLSearchParams = new URLSearchParams();
    return this.http.get(this.config.apiUrl + '/weather', this.getOptions(params))
      .map(res => (res.json() as Weather))
      .catch(this.handleError);
  }

  // getApiariesLocal() {
  //   const params: URLSearchParams = new URLSearchParams();
  //   this.http.get(this.config.apiUrl + '/regler', this.getOptions(params))
  //     .map(res => res.json())
  //   // Subscribe to the observable to get the parsed people object and attach it to the
  //   // component
  //     .subscribe(people => this.people = people);
  //
  //   console.log(this.people);
  // }

  getEvent(myDays): Observable<BeeEvent[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.set('days', '' + myDays); // 96 = 2 Tage
    // params.set('hive', '' + myHive);
    return this.http.get(this.config.apiUrl + '/event', this.getOptions(params))
      .map(res => (res.json() as BeeEvent[]))
      .catch(this.handleError);
  }

  // getWeight(myLimit, myHive): Observable<Weight[]> {
  //   const params: URLSearchParams = new URLSearchParams();
  //   params.set('days', '' + myLimit); // 96 = 2 Tage
  //   params.set('skip', '0');
  //   params.set('hive', '' + myHive);
  //   return this.http.get(this.config.apiUrl + '/weight', this.getOptions(params))
  //     .map(res => (res.json() as Weight[]))
  //     .catch(this.handleError);
  // }
  //
  // getWeather(myLimit, myGateway): Observable<Weather[]> {
  //   const params: URLSearchParams = new URLSearchParams();
  //   params.set('days', '' + myLimit); // 96 = 2 Tage
  //   params.set('skip', '0');
  //   params.set('id_gateway', '' + myGateway);
  //   return this.http.get(this.config.apiUrl + '/weather', this.getOptions(params))
  //     .map(res => (res.json() as Weather[]))
  //     .catch(this.handleError);
  // }
  //

  // getWeatherData() {
  //   const params: URLSearchParams = new URLSearchParams();
  //   this.http.get(this.config.apiUrl + '/weather', this.getOptions(params))
  //     .map(res => res.json())
  //     .catch(this.handleError)
  //     // Subscribe to the observable to get the parsed people object and attach it to the
  //     // component
  //     .subscribe(weather => this.weather = weather)
  //   console.log(this.weather);
  // }

  getThiColor(value, light) {
    if (value > 89.5) {
      if (light) {
        return '#ef5350';
      }
      return '#b71c1c';
    } else if (value > 79.5) {
      if (light) {
        return '#ef9a9a';
      }
      return '#e53935';
    } else if (value > 71.5) {
      if (light) {
        return '#ffcc80';
      }
      return '#fb8c00';
    } else if (value > 67.5) {
      if (light) {
        return '#fff59d';
      }
      return '#fdd835';
    }  else  {
      if (light) {
        return '#a5d6a7';
      }
      return '#1d5f8b';
    }
  }


  getSzeneColor(value) {
    if (value = 0) {
      return '#F44336';
    } else if (value = 1) {
      return '#F44336';
    } else if (value = 2) {
      return '#9C27B0';
    } else if (value = 3) {
      return '#3F51B5';
    } else if (value = 4) {
      return '#2196F3';
    } else if (value = 5) {
      return '#00BCD4';
    } else if (value = 6) {
      return '#009688';
    } else if (value = 7) {
      return '#4CAF50';
    } else if (value = 8) {
      return '#FFEB3B';
    } else if (value = 9) {
      return '#FFC107';
    } else if (value = 10) {
      return '#FF9800';
    } else if (value = 11) {
      return '#795548';
    }
  }


  private handleError (error: Response | any) {
    console.error('handleError');
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    if (this) {
      this.router.navigate(['/dash']);
    }
    return Promise.reject(errMsg);
  }
}
