import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BeeChart} from '../_models/chart';
import {CalcService} from '../_services/calc.service';

declare var d3: any;

@Component({
    selector: 'modb-chart',
    templateUrl: './chart.component.html',
    // template: `
    //    <h1>test</h1>
    //      eret`,
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  @Input() beechart: BeeChart;
  @Output() openApiary: EventEmitter<any> = new EventEmitter();
    name = 'chart 1';

  constructor(
    private calcService: CalcService) {

  }

  ngOnInit() {
    console.log('ngOnInit beechart.chart_id: '+this.beechart.chart_id);
    this.createChart();
  }

  createChart() {
    // TODO - Smartphone besser darstellen
    const w = document.getElementById(this.beechart.chart_id).clientWidth;
    const h = .45 * w;;
    const margin = {
      top: 10,
      bottom: 70 ,
      left: 60,
      right: 60
    };
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;


    d3.select('#'+this.beechart.chart_id+'>svg').remove();
    const svg = d3.select('#'+this.beechart.chart_id).append('svg')
      .attr('id', 'd3chart')
      .attr('width', w)
      .attr('height', h);

    const chart = svg.append('g')
      .classed('display', true)
      .attr('transform', 'translate(' + margin.left + ' , ' + margin.top + ')');
    console.log('width: '+width+' height: '+height);
  }


}
