import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule, MatInputModule, MAT_DIALOG_DATA} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';
import {ActionItem} from "../_models/action";
import {Szene} from "../_models/szene";
import {BeeEvent} from "../_models/events";


declare var d3: any;

@Component({
  selector: 'modb-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EventsComponent implements OnInit {
  toggleval = 'standard';
  events: BeeEvent[] = [];

  constructor(public muuService: MuuService,
              private router: Router,
              private headerService: HeaderService,
              public dialog: MatDialog) {

  }

  ngOnInit() {
    window.addEventListener('resize', function () {
      // self.plotData();
    });
    const headerData = new HeaderData();
    headerData.title = 'Ereignisse';
    headerData.id = 2;
    this.headerService.headerData.next(headerData);
    this.loadEvents();
  }


  loadEvents() {
    const self = this;
    this.muuService.getEvent(14)
      .subscribe(events => {
          console.log('getEvent ende: '+JSON.stringify(events));
          if (events != null) {
            self.events = events;
          } else {
            self.events = [];
          }
        },
        err => {
          console.error('There was an error: ' + err);
          // self.szenen = [];
          // self.initChart('chart'+self.regler.id_regler);
          // self.drawChart();
        });


    // this.exeEleRef.opened.subscribe(x => {
    //   // do something
    //   console.log('ngAfterViewInit opened id: '+JSON.stringify(x));
    // })
  }
}
