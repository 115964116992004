import { Pipe, PipeTransform } from '@angular/core';
import {Regel} from "../_models/regel";

@Pipe({
  name: 'selectedregelpipe'
  // ,pure: false
})
export class SelectedRegelPipe implements PipeTransform {
  transform(items: Regel[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.selected);
  }
}