import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CalcService} from "../../_services/calc.service";
import {MuuService} from "../../_services/muu.service";
import {MenuItem} from "../../_models/menu";

@Component({
  selector: 'modb-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MenuComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Output() openMenu: EventEmitter<any> = new EventEmitter();
  hovered = false;

  constructor(
    private muuService: MuuService) {
  }

  ngOnInit() {
  }

  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

  openThisMenu() {
    this.openMenu.emit(this.menuItem);
  }


  // editSensorName(event) {
  //   event.stopPropagation();
  //
  //   const dialogRef = this.dialog.open(AddApiaryDialogComponent);
  //   dialogRef.componentInstance.location_playceholder = 'Standort bearbeiten';
  //   dialogRef.componentInstance.ok_value = 'Speichern';
  //   dialogRef.componentInstance.edit = true;
  //   dialogRef.componentInstance.editvalue = this.regler.name_sensor;
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != false) {
  //
  //       const apiaryname = result;
  //       console.log('apiaryname:' + apiaryname);
  //       // TODO - Namen speichern
  //       this.regler.name_sensor = apiaryname;
  //       const res = this.muuService.setSensorName(this.regler);
  //
  //       // res is a promise!!!
  //       console.log('res setSensorName:' + res);
  //     } else {
  //       console.error('abbruch');
  //     }
  //
  //   });
  // }
}
