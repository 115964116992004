import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import {AuthService} from '../auth/auth.service';
import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {AppConfig} from '../app.config';
import * as moment from 'moment';
import {Subject} from 'rxjs/Subject';
import {HeaderData} from '../_models/headerData';

declare var d3: any;
@Injectable()
export class HeaderService {
  headerData = new Subject();
}
