import {Pipe, PipeTransform} from '@angular/core';
import {Sensor} from '../_models/sensor';
// The pipe class implements the PipeTransform interface's
// transform method that accepts an input value and an optional array of parameters and returns the transformed value.

// We tell Angular that this is a pipe by applying the @Pipe decorator which we import from the core Angular library.
@Pipe({
  // The @Pipe decorator takes an object with a name_sensor property whose value is the pipe name_sensor that
  // we'll use within a template expression. It must be a valid JavaScript identifier. Our pipe's name_sensor is orderby.
  name: 'orderbyweight'
})

export class OrderByWeight implements PipeTransform {
  transform(array:Array<Sensor>, args?) {
    // Check if array exists, in this case array contains articles and args is an array that has 1 element : !id
    if(array) {
      // get the first element
      const orderByValue = args
      // check if exclamation point
      // console.log('orderByValue',orderByValue);

      if (orderByValue == 'standard') {
        array.sort(function (a, b) {
          return a.id_sensoren - b.id_sensoren;
        });
      } else if (orderByValue == 'name') {
        array.sort(function (a, b) {
          if ( b.name_sensor > a.name_sensor ) {
            return -1;
          }
          if ( b.name_sensor < a.name_sensor ) {
            return 1;
          }
          return 0;
        });
      } else if (orderByValue == 'highest48')   {
        array.sort(function (a, b) {
          // if ((a.sensor_data != null) && (b.sensor_data != null)) {
          //   if ((a.sensor_data.length > 0) && (b.sensor_data.length > 0)) {
          //     return b.status[0].change48 - a.status[0].change48;
          //   }
          // }
          return 0;
        });
      } else {
        array.sort(function (a, b) {
          // if ((a.status != null) && (b.status != null)) {
          //   if ((a.status.length > 0) && (b.status.length > 0)) {
          //     return b.status[0].change24 - a.status[0].change24;
          //   }
          // }
          return 0;
        });
      }
      return array;
    }
    //
  }
}
