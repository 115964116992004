import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from "../../_services/muu.service";
import {MenuItem} from "../../_models/menu";

@Component({
  selector: 'modb-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderMenuComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Output() openMenu: EventEmitter<any> = new EventEmitter();
  hovered = false;

  constructor(
    private muuService: MuuService) {
  }

  ngOnInit() {
  }

  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

  openThisHeadermenu() {
    this.openMenu.emit(this.menuItem);
  }
}
