import {ViewChild, Component, ElementRef, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import Chart from 'chart.js';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {log} from 'util';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import {HeaderService} from '../_services/header.service';
import {MenuItem} from "../_models/menu";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'modb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HeaderService]
})

export class HomeComponent implements OnInit, OnDestroy {
  _subscription;
  menu_items: MenuItem[] = [];

  constructor(
    public authService: AuthService,
    private router: Router,
    private muuService: MuuService,
    private _overlayContainer: OverlayContainer) {

    this.menu_items = [];
    this.addMenuItem("dashboard", "Übersicht", "dash", true);
    this.addMenuItem("event_note", "Ereignisse", "events", false);
    // this.addMenuItem("timeline", "Statistik", "statistic/5", false);
    // this.addMenuItem("wifi_tethering", "Sensoren", "sensoren", false);
    // this.addMenuItem("toys", "Aktoren", "aktoren", false);
    // this.addMenuItem("ac_unit", "Aktoren", "actors", false);
    // this.addMenuItem("wb_incandescent", "Aktoren", "actors", false);
    // this.addMenuItem("wb_cloudy", "Wetter", "weather", false);
    this.addMenuDivider("Einstellungen");
    // this.addMenuItem("settings", "Allgemein", "einstellungen", false);
    // this.addMenuItem("notifications_active", "Alarme", "alarme", false);
    this.addMenuItem("timer", "Steuerung", "timers", false);
  }

  openMenu(menu) {
    for (var i = 0, l = this.menu_items.length; i < l; i++) {
      var menu_item = this.menu_items[i];
      menu_item.selected = false;
    }
    menu.selected = true;
    this.router.navigate(['/'+menu.path]);
  }

  addMenuItem(icon, name, path, selected) {
    let menu_item = new MenuItem();
    menu_item.selected = selected;
    menu_item.divider = false;
    menu_item.icon = icon;
    menu_item.name = name;
    menu_item.path = path;
    this.menu_items.push(menu_item);
  }

  addMenuDivider(name) {
    let menu_item = new MenuItem();
    menu_item.selected = false;
    menu_item.icon = "";
    menu_item.name = name;
    menu_item.divider = true;
    this.menu_items.push(menu_item);
  }

  ngOnInit() {
    // this.reloadData();
    this.router.navigate(['/dash']);
  }

  ngOnDestroy() {
    // this._subscription.unsubscribe();
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 720) {
      return true;
    } else {
      return false;
    }
  }

  onLogout() {
    this.authService.logout();
  }
}
