import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from "../_services/muu.service";
import {MenuItem} from "../_models/menu";
import {Bedingung} from "../_models/bedingung";
import {Aktion} from "../_models/aktion";

@Component({
  selector: 'modb-aktion',
  templateUrl: './aktion.component.html',
  styleUrls: ['./aktion.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AktionComponent implements OnInit {
  @Input() aktion: Aktion;
  @Input() vorschau: number;
  @Input() reihe: number;
  @Output() editAktion: EventEmitter<any> = new EventEmitter();
  @Output() deleteAktion: EventEmitter<any> = new EventEmitter();
  gueltig: boolean;

  constructor(
    private muuService: MuuService) {
    this.gueltig = false;
  }

  ngOnInit() {
    this.updateBeschreibung();
  }
  askDeleteAktion() {
    this.deleteAktion.emit(this.aktion);
  }
  editThisAktion() {
    this.editAktion.emit(this.aktion);
  }

  updateBeschreibung() {
    if (this.aktion != undefined) {
      this.muuService.setBeschreibungAktion(this.aktion);
    }

  }
}
