import { Input } from '@angular/core';

export class Bedingung {
  id_bedingung: number;
  id_regel: number;
  id_trigger: number; //id_sensoren, id_aktoren usw
  typ_trigger: number; //sensor = 0, aktor = 1, zeit = 2
  trigger_value: number; //Wert
  trigger_port: number; //Temperatur, Luftfeuchtigkeit, THI
  trigger_operator: number; // > oder <
  operator: number; //UND / ODER
  level: number;
  reihenfolge: number;
  last_change: string;
  beschreibung: string;

  constructor () {
    this.beschreibung = "";
  }
}