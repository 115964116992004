import { Component } from '@angular/core';

@Component({
    selector: 'modb-hive',
    templateUrl: './hive.component.html',
    // template: `
    //    <h1>test</h1>
    //      eret`,
    styleUrls: ['./hive.component.css']
})
export class HiveComponent {
    name = 'hive 1';
}
