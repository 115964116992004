import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from '../_services/muu.service';
import {CalcService} from '../_services/calc.service';
import {Sensor} from "../_models/sensor";
import {Weather} from "../_models/weather/weather";

@Component({
  selector: 'modb-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
  ]
})

export class WeatherComponent implements OnInit {
  @Input() weather: Weather;
  @Output() openApiary: EventEmitter<any> = new EventEmitter();
  hovered = false;
  fullPath = '';
  picclass = 'picbuchberg';
  t_start = 0;
  t_end = 0;

  constructor(
    private calcService: CalcService,
    private muuService: MuuService) {
    this.fullPath = '../../img/regler.jpg';
  }

  ngOnInit() {
    const self = this;
    // if (this.regler.sensor_data == undefined) {
    //   this.regler.sensor_data = [];
    // }
    //
    // if (this.regler.id_gateways == 1001) {
    //   this.picclass = 'picholzwaage';
    // }
    //
    // this.t_start = new Date().getTime();
    // if (this.regler.sensor_data != null) {
    //   this.t_end = new Date().getTime();
    //   console.log('timediff regler! ', this.t_end -  this.t_start);
    // }
  }
  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

  openThisApiary() {
      // this.openSensor.emit(this.regler);
  }
}
