import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';

@Component({
  selector: 'modb-add-apiary-dialog',
  template: ` 
    <mat-form-field>
       <input [(ngModel)]="namevalue" matInput placeholder="{{placeholder}}" value="">
    </mat-form-field> 
    <br />
    <button type="button" mat-button
            (click)="dialogRef.close(false)">{{cancel_value}}</button>
    <button type="button" mat-raised-button
             (click)="dialogRef.close(returnName())">{{ok_value}}</button>
  `,
})

export class AddEditDialogComponent {
  namevalue: string;
  placeholder = '';
  cancel_value = 'Abbrechen';
  ok_value = 'Erstellen';
  edit = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.ok_value = data.ok_value ? data.ok_value : "Erstellen";
    this.cancel_value = data.cancel_value ? data.cancel_value : "Abbrechen";
    this.namevalue = data.namevalue ? data.namevalue : "";
    this.placeholder = data.placeholder ? data.placeholder : "";
  }

  returnName() {
    console.log('returnname: '+this.namevalue);
    return this.namevalue;
  }
}
