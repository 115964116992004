import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Compiler } from '@angular/core';

import { AuthService } from '../auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'modb-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SigninComponent implements OnInit {
    model: any = {};
    loading = false;
    error = '';

    constructor(
        private router: Router,
        private authService: AuthService,
        private _compiler: Compiler) { }

    ngOnInit() {
      console.log('SigninComponent ngOnInit - echt?');

    }

    onSignin(form: NgForm) {
        console.log('onSignin neeeeeeew');
        const email = form.value.email;
        const password = form.value.password;

        this.loading = true;
        this.authService.signinUser(email, password)
            .subscribe(result => {
                if (result === true) {
                    // login successful
                    console.log('this._compiler.clearCache()');
                    this._compiler.clearCache();
                    this.router.navigate(['/dash']);
                } else {
                    // login failed
                    this.error = 'Username or password is incorrect';
                    this.loading = false;
                }
            });
        // if (this.authService.signinUser(email, password)) {
        //     console.log('token should be ok');
        // } else {
        //     console.log('no token');
        // }
    }

}