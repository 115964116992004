import {MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatTabChangeEvent} from '@angular/material';
import {ChangeDetectorRef, Component, Inject, NgZone, ViewChild} from '@angular/core';
import {ActionItem} from "../_models/action";
import {MuuService} from "../_services/muu.service";
import {Szene} from "../_models/szene";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Bedingung} from "../_models/bedingung";
import {Sensor} from "../_models/sensor";


@Component({
  selector: 'modb-regelungs-dialog',
  template: `
    <h1 matDialogTitle>{{titleValue}}</h1>
    <mat-tab-group style="margin-top: 16px;" [(selectedIndex)]="bedingung.typ_trigger" (selectedTabChange)="onLinkClick($event)">
      
      <mat-tab label="Sensoren">
        <div class="flex-column" style="margin-top: 16px;">
          <div class="flex-row" style="align-items: center">
            <mat-form-field flex-item style="width: 60px !important;">
              <input matInput placeholder="Wenn" disabled>
            </mat-form-field>
            <mat-form-field style="margin-right: 8px;" flex-item>
              <mat-select [(ngModel)]="bedingung.trigger_port" placeholder="" (selectionChange)="calcHelper();">
                <mat-option [value]="0">die Temperatur</mat-option>
                <mat-option [value]="1">die Luftfeuchtigkeit</mat-option>
                <mat-option [value]="2">der THI-Wert</mat-option>

              </mat-select>
            </mat-form-field>
            <mat-form-field flex-item style="width: 45px !important;">
              <input matInput placeholder="von" disabled>
            </mat-form-field>
            <mat-form-field style="margin-right: 8px; width: auto;" flex-item>
              <mat-select [(ngModel)]="bedingung.id_trigger" placeholder="Sensor">
                <mat-option *ngFor="let sensor of sensoren" [value]="sensor.id_sensoren">
                  {{ sensor.name_sensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="bedingung.trigger_port < 3" class="flex-row" style="align-items: center">

            <mat-form-field style="margin-right: 8px; width: auto;">
              <mat-select #select="matSelect" [(ngModel)]="bedingung.operator">
                <mat-option [value]="0">{{this.option0}}</mat-option>
                <mat-option [value]="1">{{this.option1}}</mat-option>
                <mat-option [value]="6">{{this.option2}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="bedingung.trigger_port < 3" class="flex-row" style="align-items: center">
            <br />
          </div>
          
          <div *ngIf="bedingung.trigger_port < 3" class="flex-row" style="align-items: center">
            <mat-form-field flex-item style="width: 130px !important;">
              <input matInput placeholder="Werteinstellung:" disabled>
            </mat-form-field>
            <mat-form-field style="width: 100px;">
              <input (change)="calcHelper();" [(ngModel)]="bedingung.trigger_value" matInput OnlyNumber="true" type="number">
            </mat-form-field>

            <mat-form-field flex-item style="width: 30px !important;">
              <input matInput [placeholder]="helper" disabled>
            </mat-form-field>
            
            <mat-form-field *ngIf="bedingung.trigger_port == 2" flex-item style="width: 150px !important;">
              <input matInput [placeholder]="thi_info" disabled>              
            </mat-form-field>
          </div>
          
          <div *ngIf="bedingung.trigger_port == 3" class="flex-row" style="align-items: center">
            <mat-form-field style="margin-right: 8px; width: auto;">
              <mat-select [(ngModel)]="bedingung.operator">
                <mat-option [value]="2">vollkommene Dunkelheit</mat-option>
                <mat-option [value]="3">geringe Helligkeit</mat-option>
                <mat-option [value]="4">extreme Helligkeit</mat-option>
                <mat-option [value]="5">einen stark veränderten Wert</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field flex-item style="width: 100px !important;">
              <input matInput placeholder="misst" disabled>
            </mat-form-field>
          </div>

          <div *ngIf="bedingung.trigger_port == 4" class="flex-row" style="align-items: center">
            <mat-form-field style="margin-right: 8px; width: 300px;">
              <mat-select [(ngModel)]="bedingung.operator">
                <mat-option [value]="10">eine leichte Positionsveränderung</mat-option>
                <mat-option [value]="11">eine mittlere Positionsveränderung</mat-option>
                <mat-option [value]="12">eine starke Positionsveränderung</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field flex-item style="width: 100px !important;">
              <input matInput placeholder="messen" disabled>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      
      
      <mat-tab label="Aktoren">
        <div class="flex-column" style="margin-top: 16px;">
          <div class="flex-row" style="align-items: center">
            <mat-form-field flex-item style="width: 60px !important;">
              <input matInput placeholder="Wenn" disabled>
            </mat-form-field>
            
            <mat-form-field style="margin-right: 8px;" flex-item>
              <mat-select [(ngModel)]="bedingung.id_trigger" placeholder="">
                <mat-option *ngFor="let aktor of aktoren" [value]="aktor.id_sensoren">
                  {{ aktor.name_sensor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex-row" style="align-items: center">
            <mat-form-field style="margin-right: 8px; width: auto;" flex-item>
              <mat-select #select2="matSelect" [(ngModel)]="bedingung.trigger_port" placeholder="" (selectionChange)="calcHelper()">
                <mat-option [value]="100">eingeschalten wird</mat-option>
                <mat-option [value]="101">ausgeschalten wird</mat-option>
                <mat-option [value]="102">{{option102}}</mat-option>
                <mat-option [value]="103">{{option103}}</mat-option>
                <mat-option [value]="104">{{option104}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="flex-row" style="align-items: center">            
            <!--<mat-form-field>-->
              <!--<input [(ngModel)]="bedingung.trigger_value" matInput OnlyNumber="true" type="number">-->
            <!--</mat-form-field>-->

            <mat-form-field flex-item style="width: 80px !important;">
              <input matInput placeholder="Dauer:" disabled>
            </mat-form-field>            
            <mat-form-field style="width: 80px;">
              <input [(ngModel)]="time_value" (change)="calcHelper();" matInput type="time">
            </mat-form-field>
            <mat-form-field flex-item style="width: 250px !important;">
              <input matInput [placeholder]="helper" disabled>
            </mat-form-field>
          </div>
        </div>
        
      </mat-tab>

      <mat-tab label="Uhrzeit">
        <!--TODO - Nur Uhrzeit dieser Szene erlauben?!-->
        <mat-form-field flex-item style="width: 80px !important;">
          <input matInput placeholder="Wenn es" disabled>
        </mat-form-field>

        <mat-form-field style="margin-right: 8px; width: 110px;">
          <mat-select [(ngModel)]="bedingung.trigger_port" (selectionChange)="calcHelper()">
            <mat-option [value]="200">vor</mat-option>
            <mat-option [value]="201">nach</mat-option>
            <mat-option [value]="202">genau</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 80px;">
          <input [(ngModel)]="time_value" (change)="calcHelper();" matInput type="time">
        </mat-form-field>

        <mat-form-field flex-item style="width: 110px !important;">
          <input matInput placeholder="Uhr ist" disabled>
        </mat-form-field>


      </mat-tab>
    </mat-tab-group>

    <mat-dialog-actions>
      <button mat-icon-button (click)="dialogRef.close(true)">
        <mat-icon style="color: #CCC">delete</mat-icon>
      </button>
      <span class="flex-item"></span>
      <button *ngIf="!hideCancel" mat-button (click)="dialogRef.close(false)">{{cancel_value}}</button>
      <button *ngIf="!hideSave" mat-button (click)="dialogRef.close(returnBedingung())">{{ok_value}}</button>
    </mat-dialog-actions>
  `,
})
// <!--<mat-option [value]="3">der Lichtsensor</mat-option>-->
// <!--<mat-option [value]="4">die Lagesensoren</mat-option>-->

export class RegelungsDialogComponent {
  @ViewChild('select') private select: MatSelect;
  @ViewChild('select2') private select2: MatSelect;
  sensoren: Sensor[] = [];
  aktoren: Sensor[] = [];
  bedingung: Bedingung;
  titleValue: string;
  editvalue: string;
  description: string;
  color: string;
  placeholder = '';
  time_value = "00:00";
  time_minutes = 0;

  hideSave: boolean = false;
  hideCancel: boolean = false;
  colorVisible: boolean = false;
  descriptionVisible: boolean = false;
  cancel_value = 'Abbrechen';
  ok_value = 'Erstellen';
  szene: Szene;
  helper = "ist, dann...";
  option0 = "";
  option1 = "";
  option2 = "";
  option102 = "";
  option103 = "";
  option104 = "";
  thi_info = "";

  constructor(
    public dialogRef: MatDialogRef<RegelungsDialogComponent>,
    public muuService: MuuService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.bedingung = data.bedingung;
    this.sensoren = data.sensoren ? data.sensoren : [];
    this.aktoren = data.aktoren ? data.aktoren : [];
    this.ok_value = data.ok_value ? data.ok_value : "Erstellen";
    this.cancel_value = data.cancel_value ? data.cancel_value : "Abbrechen";
    this.titleValue = data.titleValue ? data.titleValue : "";
    this.hideSave = data.hideSave ? data.hideSave : false;
    this.hideCancel = data.hideCancel ? data.hideCancel : false;

    this.colorVisible = data.colorVisible ? data.colorVisible : false;
    this.descriptionVisible = data.descriptionVisible ? data.descriptionVisible : false;
    this.placeholder = data.placeholder ? data.placeholder : "";
    this.editvalue = data.editvalue ? data.editvalue : "";
    this.color = data.color ? data.color : "";
    this.description = data.description ? data.description : "";

    this.szene = data.szene ? data.szene : null;
    if (this.szene != null) {

      console.log('this.szene: '+JSON.stringify(this.szene));
    }
    this.calcHelper();
  }

  ngOnInit() {
    // this.firstFormGroup = this._formBuilder.group({
    //   firstCtrl: ['', Validators.required]
    // });
    // this.secondFormGroup = this._formBuilder.group({
    //   secondCtrl: ['', Validators.required]
    // });
    console.log('onInit dialog: '+JSON.stringify(this.bedingung));
    // this.bedingung.operator = 1;

    if (this.bedingung.typ_trigger > 0) {
      this.time_value = "";
      // let hours = this.bedingung.trigger_value / 60;
      // hours = Math.floor(hours);
      // let mins = this.bedingung.trigger_value % 60;
      // if (hours > 9) {
      //   this.time_value = hours+":";
      // } else {
      //   this.time_value = "0"+hours+":";
      // }
      // if (mins > 9) {
      //   this.time_value += mins;
      // } else {
      //   this.time_value += "0"+mins;
      // }

      this.time_value = this.muuService.getTimeString(this.bedingung.trigger_value);
      this.time_minutes = this.bedingung.trigger_value;
    } else {
      this.time_value = "00:00";
    }
    this.calcHelper();
    console.log('onInit this.time_value: '+this.time_value);
  }

  calcHelper() {
    if (this.bedingung.typ_trigger == 0) {
      if (this.bedingung.trigger_port == 0) {
        this.helper = "°C ";
      } else if (this.bedingung.trigger_port == 1) {
        this.helper = "% ";
      } else if (this.bedingung.trigger_port == 2) {
        this.helper = "";
      } else {
        this.helper = "";
      }

      if ((this.bedingung.trigger_value >= 69) && (this.bedingung.trigger_value < 72)) {
        this.thi_info = "milder Hitzestress";
      } else if ((this.bedingung.trigger_value >= 72) && (this.bedingung.trigger_value < 80)) {
        this.thi_info = "mäßiger Hitzestress";
      } else if ((this.bedingung.trigger_value >= 80) && (this.bedingung.trigger_value < 90)) {
        this.thi_info = "starker Hitzestress";
      } else if (this.bedingung.trigger_value >= 90) {
        this.thi_info = "Gefahr";
      } else {
        this.thi_info = "kein Hitzestress";
      }

      this.option0 = "den Wert von "+this.bedingung.trigger_value+this.helper+" überschreitet";
      this.option1 = "den Wert von "+this.bedingung.trigger_value+this.helper+" unterschreitet";
      this.option2 = "sich um den Wert von +/-"+this.bedingung.trigger_value+this.helper+" ändert";
      if (this.select) {
        this.select.focus();
      }
    } else if (this.bedingung.typ_trigger == 1) {
      this.option102 = "für die angegebene Dauer eingeschalten ist";
      this.option103 = "für die angegebene Dauer ausgeschalten ist";
      this.option104 = "für die angegebene Dauer offline ist";

      // console.log('stunden: '+this.time_value.slice(0,2));
      // console.log('minuten: '+this.time_value.slice(3,5));
      let mins = parseInt(this.time_value.slice(3,5))
      let hours = parseInt(this.time_value.slice(0,2))
      this.time_minutes = hours*60 + mins;

      this.helper = hours+" Stunden und "+mins+" Minuten";
    } else if (this.bedingung.typ_trigger == 2) {
      let mins = parseInt(this.time_value.slice(3,5))
      let hours = parseInt(this.time_value.slice(0,2))
      this.time_minutes = hours*60 + mins;
      this.helper = "";
    }
  }

  onLinkClick(event: MatTabChangeEvent) {
    this.calcHelper();
    console.log('tabChange: '+this.bedingung.typ_trigger);
  }


  returnBedingung() {
    if (this.bedingung.typ_trigger > 0) {
      this.bedingung.trigger_value = this.time_minutes;
    }

    console.log('this.time_minutes: '+this.time_minutes);
    console.log('this.typ_trigger: '+this.bedingung.typ_trigger);
    console.log('returnBedingung: '+JSON.stringify(this.bedingung));
    return this.bedingung;
  }
}
