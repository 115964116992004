import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {ActionItem} from "../_models/action";
import {MuuService} from "../_services/muu.service";
import {Szene} from "../_models/szene";

@Component({
  selector: 'modb-create-new-dialog',
  template: `
    <h1 matDialogTitle>{{titleValue}}</h1>
    <mat-dialog-content>
      <mat-form-field>
        <input (keyup.enter)="dialogRef.close(returnEdit())" [(ngModel)]="editvalue" matInput
               placeholder="{{placeholder}}" value="">
      </mat-form-field>
      <mat-form-field *ngIf="colorVisible">
        <mat-select [(ngModel)]=color placeholder="Farbe" disableRipple>
          <mat-option style="background-color: #F44336" value="#F44336">#F44336</mat-option>
          <mat-option style="background-color: #9C27B0" value="#9C27B0">#9C27B0</mat-option>
          <mat-option style="background-color: #3F51B5" value="#3F51B5">#3F51B5</mat-option>
          <mat-option style="background-color: #2196F3" value="#2196F3">#2196F3</mat-option>
          <mat-option style="background-color: #00BCD4" value="#00BCD4">#00BCD4</mat-option>
          <mat-option style="background-color: #009688" value="#009688">#009688</mat-option>
          <mat-option style="background-color: #FFEB3B" value="#FFEB3B">#FFEB3B</mat-option>
          <mat-option style="background-color: #FFC107" value="#FFC107">#FFC107</mat-option>
          <mat-option style="background-color: #FF9800" value="#FF9800">#FF9800</mat-option>
          <mat-option style="background-color: #795548" value="#795548">#795548</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="descriptionVisible">
        <input [(ngModel)]=description matInput placeholder="Beschreibung">
      </mat-form-field>      
      <div *ngIf="hasList" mat-dialog-content>
        <mat-chip-list class="mat-chip-list-stacked">
          <mat-chip *ngFor="let action of available_actions"
                    selected="true"
                    selectable="true"
                    removable="false"
                    (click)="dialogRef.close(action)"
                    color="{{action.color}}">
            {{action.name}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-item"></span>
      <button *ngIf="!hideCancel" mat-button (click)="dialogRef.close(false)">{{cancel_value}}</button>
      <button *ngIf="!hideSave" mat-button (click)="dialogRef.close(returnEdit())">{{ok_value}}</button>
    </mat-dialog-actions>
  `,
})

export class CreateNewDialogComponent {
  available_actions: ActionItem[] = [];
  titleValue: string;
  editvalue: string;
  description: string;
  color: string;
  placeholder = '';
  hasList: boolean = false;
  hideSave: boolean = false;
  hideCancel: boolean = false;
  colorVisible: boolean = false;
  descriptionVisible: boolean = false;
  cancel_value = 'Abbrechen';
  ok_value = 'Erstellen';
  szene: Szene;

  constructor(
    public dialogRef: MatDialogRef<CreateNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.hasList = data.hasList;
    if (this.hasList) {
      this.available_actions = data.list;
    }
    this.ok_value = data.ok_value ? data.ok_value : "Erstellen";
    this.cancel_value = data.cancel_value ? data.cancel_value : "Abbrechen";
    this.titleValue = data.title;
    this.hideSave = data.hideSave ? data.hideSave : false;
    this.hideCancel = data.hideCancel ? data.hideCancel : false;

    this.colorVisible = data.colorVisible ? data.colorVisible : false;
    this.descriptionVisible = data.descriptionVisible ? data.descriptionVisible : false;
    this.placeholder = data.placeholder ? data.placeholder : "";
    this.editvalue = data.editvalue ? data.editvalue : "";
    this.color = data.color ? data.color : "";
    this.description = data.description ? data.description : "";

    this.szene = data.szene ? data.szene : null;
    if (this.szene != null) {

      console.log('this.szene: '+JSON.stringify(this.szene));
    }
  }

  returnEdit() {
    if (this.szene != null) {
      this.szene.name_szene = this.editvalue;
      this.szene.color = this.color;
      this.szene.description = this.description;
      return this.szene;
    }
    console.log('returnname: '+this.editvalue);
    return this.editvalue;
  }
}
