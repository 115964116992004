import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MuuService} from './_services/muu.service';
import Chart from 'chart.js';
import * as moment from 'moment';
import {log} from 'util';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule} from '@angular/material';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/pairwise';

@Component({
  selector: 'modb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  private _routeScrollPositions: {[url: string]: number}[] = [];
  private _subscriptions: Subscription[] = [];
  private _orderOfNavigation: string[] = [];
  private _orderOfNavigationIndex: number = 0;
  private _wentBackOrForward: boolean = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this._subscriptions.push(
      // save or restore scroll position on route change
      this.router.events.pairwise().subscribe(([prevRouteEvent, currRouteEvent]) => {

        if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          this._routeScrollPositions[prevRouteEvent.url] = window.pageYOffset;

          if ( this._orderOfNavigationIndex === 0 && this._orderOfNavigation.length == 0 ) {
            this._orderOfNavigation.push(prevRouteEvent.url);
          }; // do this the first time only to capture initial page route url

          if ( currRouteEvent.url === this._orderOfNavigation[this._orderOfNavigationIndex-1] ) { // Going back?
            this._orderOfNavigationIndex--;
            this._wentBackOrForward = true;
          } else if ( currRouteEvent.url === this._orderOfNavigation[this._orderOfNavigationIndex+1] ) { // Going forward?
            this._orderOfNavigationIndex++;
            this._wentBackOrForward = true;
          } else {
            if ( this._orderOfNavigationIndex != this._orderOfNavigation.length-1 ) {
              // Index NOT equal to last index of _orderOfNavigation?
              this._orderOfNavigation.splice(this._orderOfNavigationIndex+1); // Delete all after current index
            }
            this._wentBackOrForward = false;
            this._orderOfNavigationIndex++;
            this._orderOfNavigation.push(currRouteEvent.url);
          }

        }
        if (currRouteEvent instanceof NavigationEnd) {
          if ( this._wentBackOrForward ) { // If went back or forward?
            window.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
          } else {
            window.scrollTo(0, 0);
          }
        };
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
