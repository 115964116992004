import { Input } from '@angular/core';
import {DataBlock} from "./dataBlock";
import {DataPoint} from "./dataPoint";
import {Alert} from "./alerts";

export class Weather {
  latitude: number;
  longitude: number;
  timezone: string;
  offset: number;
  apicalls: number;
  code: number;
  currently: DataPoint;
  minutely: DataBlock;
  hourly: DataBlock;
  daily: DataBlock;
  alerts: Alert[];
}
