import { Input } from '@angular/core';

export class DataPoint {
  summary: string;
  icon: string;
  precipType: string;
  time: number;
  sunriseTime: number;
  sunsetTime: number;
  precipIntensity: number;
  precipIntensityMax: number;
  precipIntensityMaxTime: number;
  precipProbability: number;
  precipAccumulation: number;
  temperature: number;
  temperatureMin: number;
  temperatureMinTime: number;
  temperatureMax: number;
  temperatureMaxTime: number;
  apparentTemperature: number;
  apparentTemperatureMin: number;
  apparentTemperatureMinTime: number;
  apparentTemperatureMax: number;
  apparentTemperatureMaxTime: number;
  nearestStormBearing: number;
  nearestStormDistance: number;
  dewPoint: number;
  windSpeed: number;
  windBearing: number;
  cloudCover: number;
  humidity: number;
  pressure: number;
  visibility: number;
  ozone: number;
  moonPhase: number;
}
