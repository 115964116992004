import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {AppConfig} from '../app.config';

@Injectable()
export class AuthService {

  token: string;
  mail: string;

  constructor(private router: Router, private http: Http, private config: AppConfig) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.mail = currentUser && currentUser.email;
  }

  signupUser(email: string, password: string) {
    // firebase.auth().createUserWithEmailAndPassword(email, password)
    //   .catch(
    //     error => console.log(error)
    //   )
  }

  signinUser(email: string, password: string) {
      console.log('this.token ', this.token);
      console.log('signinUser this.config.apiUrl: ', this.config.apiUrl);

      return this.http.post(this.config.apiUrl + '/login', JSON.stringify({ email: email, password: password }))
          .map((response: Response) => {
              // login successful if there's a jwt token in the response

              console.log('response.json() ', response.json());
              const token = response.json() && response.json().token;
              if (token) {
                  // set token property
                  this.token = token;
                  this.mail = email;

                  // store username and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify({ email: email, token: token }));
                  // return true to indicate successful login
                  return true;
              } else {
                  // return false to indicate failed login
                  return false;
              }
          })
          .catch(this.handleError);
  }

  logout() {
    this.token = null;
    localStorage.removeItem('currentUser');
    this.router.navigate(['/signin']);
  }


  isAuthenticated() {
    return this.token != null;
  }

  private handleError (error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
      console.error('handleError');
    let errMsg: string;
    if (error instanceof Response) {
        const body = error.json() || '';
        const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
        errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Promise.reject(errMsg);
  }
}
