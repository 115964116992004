import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import {AuthService} from '../auth/auth.service';
import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {AppConfig} from '../app.config';
import * as moment from 'moment';
import {SensorData} from "../_models/sensorData";

declare var d3: any;
@Injectable()
export class CalcService {
  people;

  constructor(
    private http: Http,
    private router: Router,
    private authenticationService: AuthService,
    private config: AppConfig) {
  }

  // getHiveStatus(weight_data: SensorData[], offset: number, factor: number): SensorStatus {
  //   const timeFormat = 'DD.MM.YYYY HH:mm';
  //   const timeFormatTwo = 'HH:mm - DD.MMM';
  //   const timeFormatDay = 'dddd';
  //   const timeFormatLast = 'HH:mm';
  //   let timeOffset = new Date().getTimezoneOffset();
  //   timeOffset = (timeOffset / 60) * -1;
  //   const timeNow = new Date();
  //
  //   moment.locale('de');
  //   let d3Array = weight_data.map(function (sensordata) {
  //     return {
  //       signal_time: moment(sensordata.signal_time).add(timeOffset, 'hours').format(timeFormat),
  //       // signal_time: moment(weight.signal_time).add(2, 'hours').format(timeFormat),
  //       my_time: new Date(sensordata.signal_time).getTime()+ +(timeOffset*60*60*1000),
  //
  //       temperature: +sensordata.temp1
  //     };
  //   });
  //   d3Array = d3Array.sort(function(a, b) { return a.my_time - b.my_time; });
  //
  //   const hiveStatus = new SensorStatus;
  //   // TODO - letzte Meldung --> online-status berechnen
  //   // lastStatusTime: string;
  //   // lastStatus: number;
  //   // status: number;
  //   hiveStatus.lastStatus =d3.max(d3Array, function (d) {
  //     return d.my_time;
  //   });
  //   const changeBarrier = 0.1;
  //
  //   const tempTime = moment.duration(timeNow.getTime() - hiveStatus.lastStatus, 'milliseconds');
  //   if (tempTime.hours() < 1) {
  //     hiveStatus.statusDiff = tempTime.minutes()+'m';
  //   } else {
  //     hiveStatus.statusDiff = ''+tempTime.hours()+'h '+tempTime.minutes()+'m';
  //   }
  //   hiveStatus.status = tempTime.hours() >= 5 ? 0 : 1;
  //   // hiveStatus.statusDiff =moment(timeNow.getTime()).format(timeFormat);
  //   hiveStatus.lastStatusTime = moment(hiveStatus.lastStatus).format(timeFormatTwo);
  //
  //   const myTodaySix = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate(), 6, 0, 0);
  //   let sixToNow = d3Array.filter(function (sensorData) {
  //     // true => kommt ins array, false wird ignoriert
  //     return myTodaySix.getTime() < sensorData.my_time; // new Date(weight.signal_time).getTime();
  //   });
  //
  //   // console.log('myTodaySix.getTime(): ',moment(myTodaySix.getTime()).format(timeFormat));
  //   if (sixToNow.length > 1) {
  //     // console.log('sixToNow[0].weight1: ', sixToNow[0].weight+' w2: '+sixToNow[sixToNow.length-1].weight);
  //     const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
  //     hiveStatus.change24 = myChange;
  //     hiveStatus.max24 = d3.max(d3Array, function (d) {
  //         return d.temperature;
  //       });
  //     hiveStatus.min24 = d3.min(d3Array, function (d) {
  //         return d.temperature;
  //       });
  //     if (myChange > changeBarrier) {
  //       hiveStatus.trending24 = 2;
  //     } else if (myChange < -changeBarrier) {
  //       hiveStatus.trending24 = 0;
  //     } else {
  //       hiveStatus.trending24 = 1;
  //     }
  //     hiveStatus.day24 = moment(myTodaySix).format(timeFormatDay);
  //   } else {
  //     hiveStatus.change24 = 0;
  //     hiveStatus.max24 = 0;
  //     hiveStatus.min24 = 0;
  //     hiveStatus.trending24 = 1;
  //     hiveStatus.day24 = '';
  //   }
  //   const newItem2 = {};
  //
  //   // gestern
  //   const myYesterdaySix = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()-1, 6, 0, 0);
  //   sixToNow = d3Array.filter(function (sensorData) {
  //     // true => kommt ins array, false wird ignoriert
  //     return (myYesterdaySix.getTime() < sensorData.my_time) && (myTodaySix.getTime() > sensorData.my_time) ;
  //   });
  //
  //   if (sixToNow.length > 1) {
  //     // console.log('sixToNow[0].weight1: ', sixToNow[0].weight+' w2: '+sixToNow[sixToNow.length-1].weight);
  //     const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
  //     hiveStatus.change48 =myChange;
  //     hiveStatus.max48 =d3.max(d3Array, function (d) {
  //         return d.temperature;
  //       });
  //     hiveStatus.min48 =d3.min(d3Array, function (d) {
  //         return d.temperature;
  //       });
  //     if (myChange > changeBarrier) {
  //       hiveStatus.trending48 = 2;
  //     } else if (myChange < -changeBarrier) {
  //       hiveStatus.trending48 = 0;
  //     } else {
  //       hiveStatus.trending48 = 1;
  //     }
  //     hiveStatus.day48 = moment(myYesterdaySix).format(timeFormatDay);
  //   } else {
  //     hiveStatus.change48 = 0;
  //     hiveStatus.max48 = 0;
  //     hiveStatus.min48 = 0;
  //     hiveStatus.trending48 = 1;
  //     hiveStatus.day48 = '';
  //   }
  //
  //   // vorgestern
  //   const myDay3Six = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()-2, 6, 0, 0);
  //   sixToNow = d3Array.filter(function (sensorData) {
  //     return (myDay3Six.getTime() < sensorData.my_time) && (myYesterdaySix.getTime() > sensorData.my_time) ;
  //   });
  //   if (sixToNow.length > 1) {
  //     // console.log('sixToNow[0].weight1: ', sixToNow[0].weight+' w2: '+sixToNow[sixToNow.length-1].weight);
  //     const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
  //     hiveStatus.change72 =myChange;
  //     if (myChange > changeBarrier) {
  //       hiveStatus.trending72 = 2;
  //     } else if (myChange < -changeBarrier) {
  //       hiveStatus.trending72 = 0;
  //     } else {
  //       hiveStatus.trending72 = 1;
  //     }
  //     hiveStatus.day72 = moment(myDay3Six).format(timeFormatDay);
  //   } else {
  //     hiveStatus.change72 = 0;
  //     hiveStatus.trending72 = 1;
  //     hiveStatus.day72 = '';
  //   }
  //
  //   const myDay4Six = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()-3, 6, 0, 0);
  //   sixToNow = d3Array.filter(function (sensorData) {
  //     return (myDay4Six.getTime() < sensorData.my_time) && (myDay3Six.getTime() > sensorData.my_time) ;
  //   });
  //   if (sixToNow.length > 1) {
  //     // console.log('sixToNow[0].weight1: ', sixToNow[0].weight+' w2: '+sixToNow[sixToNow.length-1].weight);
  //     const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
  //     hiveStatus.change96 =myChange;
  //     if (myChange > changeBarrier) {
  //       hiveStatus.trending96 = 2;
  //     } else if (myChange < -changeBarrier) {
  //       hiveStatus.trending96 = 0;
  //     } else {
  //       hiveStatus.trending96 = 1;
  //     }
  //     hiveStatus.day96 = moment(myDay4Six).format(timeFormatDay);
  //   } else {
  //     hiveStatus.change96 = 0;
  //     hiveStatus.trending96 = 1;
  //     hiveStatus.day96 = '';
  //   }
  //
  //
  //   hiveStatus.id_hive = 0;
  //   hiveStatus.name = '';
  //   return hiveStatus;
  // }
}
