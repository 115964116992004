import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';
import {OrderByWeight} from '../_pipes/orderbyweight';


declare var d3: any;

@Component({
  selector: 'modb-sensoren',
  templateUrl: './sensoren.component.html',
  styleUrls: ['./sensoren.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})
export class SensorenComponent implements OnInit {
  toggleval = 'standard';
  direction = "column";
  t_start = 0;
  t_end = 0;
  // sensorVal = 0;

  constructor(public muuService: MuuService,
              private router: Router,
              private headerService: HeaderService) {

  }

  ngOnInit() {
    window.addEventListener('resize', function () {
      // self.plotData();
    });
    const headerData = new HeaderData();
    headerData.title = 'Sensoren';
    headerData.id = 2;
    this.headerService.headerData.next(headerData);
  }

  openSensor(sensor) {
    console.log('openSensor event: ', sensor.id_sensoren);
    this.router.navigate(['/statistic/'+sensor.id_sensoren]);
  }
}