import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import {now} from "moment";
import {SensorStatus} from "../../_models/sensorStatus";
import {CalcService} from "../../_services/calc.service";
import {MuuService} from "../../_services/muu.service";

declare var d3: any;

@Component({
  selector: 'modb-sensorstatus',
  templateUrl: './sensorstatus.component.html',
  styleUrls: ['./sensorstatus.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SensorStatusComponent implements OnInit {
  @Input() sensorStatus: SensorStatus;
  @Output() openApiary: EventEmitter<any> = new EventEmitter();
  hovered = false;
  constructor(
    public muuService: MuuService) {
  }

  ngOnInit() {
  }

  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

}
