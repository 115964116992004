import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CalcService} from '../_services/calc.service';
import {HeaderData} from '../_models/headerData';
import {HeaderService} from '../_services/header.service';
import {BeeChart} from '../_models/chart';
import {zoom} from 'd3-zoom';
import {BeeEvent} from "../_models/events";
import {SensorData} from "../_models/sensorData";
import {AddEditDialogComponent} from "../dialogs/add-appiary-dialog.component";
import {MatDialog} from "@angular/material";
import {Sensor} from "../_models/sensor";
import {AktorData} from "../_models/aktorData";

declare var d3: any;


@Component({
  selector: 'modb-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticComponent implements OnInit {
  data_array: SensorData[] = [];
  aktor_array: AktorData[] = [];
  event_array: BeeEvent[] = [];
  weightchart: BeeChart;

  d3Array;
  d3AktorArray;

  brutChecked = false;
  humChecked = true;
  humRelChecked = true;
  tempChecked = true;
  scaleChecked = false;
  vcChecked = true;
  sensorValChecked = true;
  aktorValChecked = false;

  adminMode = true;
  showPoints = false;
  lineStyle = 0;
  mySensor = 0;
  myAktor = 0;
  myGateway = 0;
  myLimit = 7;
  weightLowestDay = 10000000;
  weightHighestTwoDay = 0;
  weightLowestTwoDay = 10000000;
  weightHighestWeek = 0;
  weightLowestWeek = 10000000;
  weightHighestDay = 0;
  riseDay = 0;
  riseWeek = 0;
  chart;
  chart_data;
  div;
  mouseOverTime = '';
  mouseWeight = '';
  mouseThi = '';
  mouseTempWeather = '';
  mouseHum = '';
  mouseHumRel = '';
  mouseMax = '';
  mouseVol = '';
  mouseSensorVal = '';

  scaleIndex = 0;
  thiIndex = 0;
  tempIndex = 0;
  humIndex = 0;
  humRelIndex = 0;
  levelIndex = 0;
  maxIndex = 0;
  volIndex = 0;
  sensorValIndex = 0;

  statisticItems = [];

  @ViewChild('donut') donut: ElementRef;

  constructor(
    public authService: AuthService,
    private muuService: MuuService,
    private calcService: CalcService,
    private route: ActivatedRoute,
    private myElement: ElementRef,
    public dialog: MatDialog,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.weightchart = new BeeChart('weightchart');

    this.route.params.subscribe((params: Params) => {
      this.mySensor = params['id'];

      const self = this;
      if (this.muuService.sensors != null) {
        this.muuService.sensors.forEach(function (sensor){
          if (sensor != null) {
            if (sensor.id_sensoren == self.mySensor) {
              self.addHeaderData(sensor);
            }
          }
        });
      }

      this.reloadData();
      window.addEventListener('resize',function() {
        self.plotData();
      });
      this.div = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);
    });
  }

  addHeaderData(sensor: Sensor) {
    const self = this;
    const headerData = new HeaderData();
    headerData.title = 'Sensor: '+sensor.name_sensor; //+" ("+sensor.device_id+")" ;
    headerData.id = 1;
    headerData.menu_items = [];

    this.muuService.addHeaderMenuItem(
      "add",
      "Hinzufügen",
      "statistic",
      false,
      function(): number {
        self.menuAction(2, sensor);
        return 2;
      }, headerData);

    this.muuService.addHeaderMenuItem(
      "edit",
      "Bearbeiten",
      "statistic",
      false,
      function(): number {
        self.menuAction(1, sensor);
        return 1;
      }, headerData);


    this.headerService.headerData.next(headerData);
    // console.log('self.myHive: ', this.mySensor);
  }

  menuAction(aufruf, sensor) {
    if (aufruf == 1) {
      console.log('Sensor bearbeiten');
      // this.addRegler();

      const self = this;
      const dialogRef = this.dialog.open(AddEditDialogComponent, {
        width: '250px',
        data: {
          hasList: false,
          title: "Steuerung bearbeiten",
          ok_value: "Speichern",
          placeholder: "Sensorname bearbeiten",
          namevalue: sensor.name_sensor
        }
      });
      // dialogRef.componentInstance.placeholder = 'Sensorname bearbeiten';
      // dialogRef.componentInstance.ok_value = 'Speichern';
      // dialogRef.componentInstance.edit = true;
      // dialogRef.componentInstance.namevalue = sensor.name_sensor;

      dialogRef.afterClosed().subscribe(result => {
        if (result != false) {
          const apiaryname = result;
          sensor.name_sensor = apiaryname;
          const res = this.muuService.setSensorName(sensor);
          console.log('res setSensorName:' + res);
          self.addHeaderData(sensor);
        } else {
          console.error('abbruch');
        }
      });

      // const dialogRef = this.dialog.open(AddEditDialogComponent);
      // dialogRef.afterClosed().subscribe(result => {
      //   const apiaryname = result;
      //   console.log('apiaryname:' + apiaryname);
      //   // TODO Service - createApiary
      //   // this.openSnackBar('Neuen Standort erstellen: ' + apiaryname);
      // });
    } else if (aufruf == 2) {
      console.log('Aktor zu Statistik hinzufügen');

    } else {
      console.log('unknown aufruf menuAction');
    }
  }

  reloadData() {
    // console.log('reloadData this.regler: ', this.mySensor);
    // this.muuService.getEvent(this.myLimit, this.myHive)
    //   .subscribe(events => {
    //       this.event_array = events;
    //       console.log('event_array loaded: '+events);


    const self = this;

    this.muuService.getSensorData(this.myLimit, this.mySensor)
      .subscribe(sensorData => {
          this.data_array = sensorData;
          // console.log('sensorData 1 loaded: '+JSON.stringify(sensorData));
          this.prepareData();
          this.plotData();

          if (this.muuService.sensors != null) {
            self.myAktor = 0;

            self.muuService.sensors.forEach(function (sensor){

              if (self.myAktor == 0) {
                if (sensor != null) {
                  if (sensor.typ_sensor == 2) {
                    self.myAktor = sensor.id_sensoren;
                    console.log('aktor gefunden - den laden wir einfach mal');

                    self.muuService.getAktorData(self.myLimit, sensor.id_sensoren)
                      .subscribe(aktorData => {
                          self.aktor_array = aktorData;
                          self.prepareAktorData();
                          if (self.aktorValChecked) {

                            self.plotData();
                          }
                          // console.log('aktorData loaded: '+JSON.stringify(self.d3AktorArray));
                        },
                        err => {
                          console.error('There was an error: ' + err);
                          // this.authService.logout();
                        });
                  }
                }
              }
            });
          }
        },
        err => {
          console.error('There was an error: ' + err);
          // this.authService.logout();
        });


    //   },
    //     err => {
    //       console.error('There was an error getEvent: ' + err);
    //       this.authService.logout();
    //     });
  }


  prepareData() {
    // console.log("prepareData");
    const timeFormat = 'DD.MM.YYYY HH:mm';
    let timeOffset = new Date().getTimezoneOffset();
    timeOffset = (timeOffset / 60) * -1;

    timeOffset = this.muuService.server_offset;
    // TODO Daten müssen am Server richtig abgespeichert werden und schon richtig gesendet, bzw mit Timezone gesendet!
    // Waage steht in Zeitzone X
    // Sortierung nach signal_time
    let startTemp = 0;
    if (this.data_array.length > 0) {
      startTemp = +this.data_array[this.data_array.length-1].temp1;
    }

    // console.log(this.data_array);
    this.d3Array = this.data_array.map(function (sensorData) {
      // let d3Array = this.weight_array.map(function (weight) {
      return {
        signal_time: moment(sensorData.signal_time).add(timeOffset, 'hours').format(timeFormat),
        // signal_time: moment(weight.signal_time).add(2, 'hours').format(timeFormat),
        my_time: new Date(sensorData.signal_time).getTime() + +(timeOffset * 60 * 60 * 1000),
        temperature: +sensorData.temp1,
        temperature2: +sensorData.temp2,
        voltage: +sensorData.voltage,
        sensor_val: +sensorData.sensor_val,
        humidity: +(sensorData.humidity > 100 ? 100 : sensorData.humidity),
        humidity_rel: +sensorData.heat_index,
        pressure: +sensorData.pressure,
        thi: +sensorData.thi,
        heat_index: +sensorData.heat_index,
        light_max: +sensorData.light_max,
        light_min: +sensorData.light_min,
        tempDiff: sensorData.temp1 - +startTemp,
        thi1: + Math.round(((0.8*sensorData.temp1)+((sensorData.humidity / 100)* (sensorData.temp1-14,4))+46.4)),
        thi2: + Math.round(((0.8*sensorData.temp2)+((sensorData.humidity / 100)* (sensorData.temp2-14,4))+46.4)),
        hasEvent: 0,
        draw_weight: 1
      };
    });

    this.d3Array = this.d3Array.sort(function (a, b) {
      return a.my_time - b.my_time;
    });



    // Events berechnen und beachten!
    // define which change in weight in a defined timewindow should be considered as EVENT
    const weightBarriere = 1; // weightchange in grams
    const timeWindow = 1000 * 60 * 60; // size of timewindow in ms

    // Loop durch array
    // let index, len;
    //
    // let currentEvent = new BeeEvent();
    // if (this.event_array == null) {
    //   // noch keine Events am Server eingetragen!
    //   this.event_array = [];
    // }
    //
    // let makeIt = false;
    // for (index = 0, len = this.d3Array.length; index < len; ++index) {
    //   if (index == 0) {
    //     // Start-Event! solls immer geben!
    //     // muss Startgewicht mitloggen!
    //     currentEvent.event_start = this.d3Array[index].signal_time;
    //     currentEvent.start_weight = this.d3Array[index].weight;
    //     currentEvent.end_weight = this.d3Array[index].weight;
    //     currentEvent.highest = this.d3Array[index].weight;
    //     currentEvent.lowest = this.d3Array[index].weight;
    //     currentEvent.start_time = this.d3Array[index].my_time;
    //     currentEvent.end_time = this.d3Array[index].my_time;
    //     currentEvent.event_end = this.d3Array[index].signal_time;
    //     currentEvent.event_diff =0;
    //     currentEvent.end_reason = 0;
    //
    //     // this.event_array.push(currentEvent);
    //     currentEvent = null;
    //   } else {
    //     // mit Hilfe von Events offset und + / - ausrechnen für jedes element
    //
    //
    //     if (currentEvent == null) { // falls kein event mehr im Gange
    //
    //       if ( Math.abs(Math.abs(this.d3Array[index].weight) - Math.abs(this.d3Array[index-1].weight)) > weightBarriere) {
    //         // Neue events erkennen -> ist Zeit zum letzten Element < timeWindow dann prüfen of gewichtsunterschied > weightBarriere
    //         makeIt = true;
    //         if ( this.d3Array[index].my_time - this.d3Array[index-1].my_time > timeWindow) {
    //           // Zeitabstand zu groß! Sollte eigentlich nie passieren --> hier gab es Aufzeichnungsfehler!!
    //           // TODO Aufzeichnugnsfehler anzeigen
    //           makeIt = false;
    //           console.error('größer: '+this.d3Array[index]);
    //           // Falls Gewichtsunterschied aber größer weightBarriere * 3 ist, dann trotzdem melden!
    //           if ( Math.abs(Math.abs(this.d3Array[index].weight) - Math.abs(this.d3Array[index-1].weight)) > weightBarriere*3) {
    //             makeIt = true;
    //           }
    //         }
    //
    //         if (makeIt == true) {
    //           console.error('EVENT ' + this.d3Array[index].weight + ' w2: ' + Math.abs(this.d3Array[index - 1].weight));
    //           currentEvent = new BeeEvent();
    //           currentEvent.event_start = this.d3Array[index - 1].signal_time;
    //           currentEvent.start_weight = this.d3Array[index - 1].weight;
    //           currentEvent.end_weight = this.d3Array[index - 1].weight;
    //           currentEvent.start_time = this.d3Array[index - 1].my_time;
    //           currentEvent.end_time = this.d3Array[index - 1].my_time;
    //           currentEvent.event_end = this.d3Array[index - 1].signal_time;
    //           currentEvent.event_diff = this.d3Array[index].weight - this.d3Array[index - 1].weight;
    //           currentEvent.event_end = this.d3Array[index - 1].signal_time;
    //           currentEvent.highest = this.d3Array[index - 1].weight;
    //           currentEvent.lowest = this.d3Array[index - 1].weight;
    //           this.d3Array[index].draw_weight = 1;
    //         }
    //       }
    //       // falls ja --> neues event erstellen und weiter mit loop
    //     } else {
    //       console.log('currentEvent running: '+currentEvent);
    //     }
    //
    //     // TODO - falls es zu regnen beginnt, oder schön wird plötzlich, sind starke schwankungen möglich!
    //     // bei Start von starkem Regen kommen alle Bienen zurück und Gewicht kann sehr startk ansteigen!
    //     // Erkennbar durch -> Anstieg Luftfeuchtigkeit, Temparaturverfall!
    //     // starke Schwankungen Luftfeuchtigkeit = Regen!?
    //
    //     if (currentEvent != null) { // ist ein event im Gange??
    //       if ( this.d3Array[index].weight > currentEvent.highest) {
    //         currentEvent.highest = this.d3Array[index].weight;
    //       }
    //       if ( this.d3Array[index].weight < currentEvent.lowest) {
    //         currentEvent.lowest = this.d3Array[index].weight;
    //       }
    //
    //       // falls wieder Ausgangsgewicht --> event abschließen und zum event-array hinzufügen
    //       if ( Math.abs(this.d3Array[index].weight - currentEvent.start_weight) < weightBarriere) {
    //         console.log('event end - start_weight!');
    //         currentEvent.end_weight = this.d3Array[index].weight;
    //         currentEvent.end_time = this.d3Array[index].my_time;
    //         currentEvent.event_end = this.d3Array[index].signal_time;
    //         currentEvent.end_reason = 1;
    //         this.event_array.push(currentEvent);
    //         currentEvent = null;
    //       } else
    //       // falls Event schon > 1 Stunde oder so --> abschließen (kann später korrigiert werden) und zum event-array hinzufügen
    //       if (this.d3Array[index].my_time - currentEvent.start_time > timeWindow) {
    //         console.log('event end - time!');
    //         currentEvent.end_weight = this.d3Array[index].weight;
    //         currentEvent.end_time = this.d3Array[index].my_time;
    //         currentEvent.event_end = this.d3Array[index].signal_time;
    //         currentEvent.end_reason = 0;
    //         this.event_array.push(currentEvent);
    //         currentEvent = null;
    //       }
    //       // falls nochmal große Schwankung aber nicht Ausgangsgewicht --> Zeit nachbessern, Zarge, Honig, hinzu, weg als Vorschlag
    //     }
    //
    //
    //
    //   }
    //
    //   // TODO Tagesstatistik berechnen -> array erstellen
    //   // TODO Stundenstatistik erstellen -> array erstellen
    // }
    //
    // // Event-Array muss wieder sortiert werden, falls ich was hinzugefügt habe!

  }


  prepareAktorData() {
    // console.log("prepareAktorData");
    const timeFormat = 'DD.MM.YYYY HH:mm';
    let timeOffset = new Date().getTimezoneOffset();
    timeOffset = (timeOffset / 60) * -1;
    timeOffset = this.muuService.server_offset;

    // console.log(this.data_array);
    this.d3AktorArray = this.aktor_array.map(function (sensorData) {
      // let d3Array = this.weight_array.map(function (weight) {
      return {
        signal_time: moment(sensorData.signal_time).add(timeOffset, 'hours').format(timeFormat),
        // signal_time: moment(weight.signal_time).add(2, 'hours').format(timeFormat),
        my_time: new Date(sensorData.signal_time).getTime() + +(timeOffset * 60 * 60 * 1000),
        status: +sensorData.status,
        grund: +sensorData.grund,
      };
    });

    this.d3AktorArray = this.d3AktorArray.sort(function (a, b) {
      return a.my_time - b.my_time;
    });

    // last.my_time = last.my_time;

    if (this.d3AktorArray[this.d3AktorArray.length-1].my_time < this.d3Array[this.d3Array.length-1].my_time) {


      let last = this.d3AktorArray[this.d3AktorArray.length-1];
      console.log("last: "+JSON.stringify(last));
      var dummyLast: any = {};
      dummyLast.signal_time = this.d3Array[this.d3Array.length-1].signal_time; //last.signal_time;
      dummyLast.my_time = this.d3Array[this.d3Array.length-1].my_time;//last.my_time + 1000;
      dummyLast.status = last.status;
      dummyLast.grund = last.grund;
      this.d3AktorArray.push(dummyLast);

    }

    // this.d3AktorArray[this.d3AktorArray.length-1].my_time = this.d3Array[this.d3Array.length-1].my_time;
    // this.d3AktorArray[this.d3AktorArray.length-1].signal_time = this.d3Array[this.d3Array.length-1].signal_time;
  }


  plotData() {
    const timeNow = new Date();
    let minTime = d3.min(this.d3Array, function (d) {
      return d.my_time;
    });
    let maxTime = d3.max(this.d3Array, function (d) {
      return d.my_time;
    });

    if (this.d3AktorArray) {

      let minTime2 = d3.min(this.d3AktorArray, function (d) {
        return d.my_time;
      });
      let maxTime2 = d3.max(this.d3AktorArray, function (d) {
        return d.my_time;
      });
      if (minTime2 < minTime) minTime = minTime2;
      if (maxTime2 > maxTime) maxTime = maxTime2;
    }


    let minVal = d3.min(this.d3Array, function (d) {
      return d.temperature;
    });
    let maxVal = d3.max(this.d3Array, function (d) {
      return d.temperature;
    });

    let minThi = d3.min(this.d3Array, function (d) {
      return d.thi;
    });
    let maxThi = d3.max(this.d3Array, function (d) {
      return d.thi;
    });
    let minHum = d3.min(this.d3Array, function (d) {
      return d.humidity;
    });
    let maxHum = d3.max(this.d3Array, function (d) {
      return d.humidity;
    });
    if (minHum < minThi) minThi = minHum;
    if (maxHum > maxThi) maxThi = maxHum;

    let minHumRel = d3.min(this.d3Array, function (d) {
      return d.humidity_rel;
    });
    let maxHumRel = d3.max(this.d3Array, function (d) {
      return d.humidity_rel;
    });


    let minValTemp = d3.min(this.d3Array, function (d) {
        return d.temperature2;
    });
    let maxValTemp = d3.max(this.d3Array, function (d) {
        return d.temperature2;
    });

    let minVoltage = d3.min(this.d3Array, function (d) {
      return d.voltage;
    });
    let maxVoltage = d3.max(this.d3Array, function (d) {
      return d.voltage;
    });
    let minSensorVal = d3.min(this.d3Array, function (d) {
      return d.sensor_val;
    });
    let maxSensorVal = d3.max(this.d3Array, function (d) {
      return d.sensor_val;
    });

    if (minSensorVal == maxSensorVal) {
      this.sensorValChecked = false;
    }

    // console.log('minVal: ' + minVal);
    // console.log('maxVal: ' + maxVal);
    // console.log('maxValTemp: ' + maxValTemp);
    // console.log('minValTemp: ' + minValTemp);
    // console.log('lowHighVal: ' + lowHighVal);


    let w = 0;
    if (document.getElementById('d3chartdiv') != null) {
      w = document.getElementById('d3chartdiv').clientWidth;
    }

    let h = w * 0.6; // .39 * w;

    let margin = {
      top: 10,
      bottom: 70,
      left: 60,
      right: 70
    };

    let isSmall = false;
    if (w < 500) {
      isSmall = true;
      h = 500;
      margin = {
        top: 30,
        bottom: 70,
        left: 20,
        right: 20
      };
    }
    // console.log("w: "+w+" h: "+h);

    const heightZoom = 60;
    const heightWeight = (h - heightZoom) * 0.2;

    const width = w - margin.left - margin.right;
    const height = (h-heightWeight-heightZoom) - margin.top - margin.bottom;

    // minTime = minTime - 1000 * 60 * 60;
    // maxTime = maxTime + 1000 * 60 * 60;

    const diff = (maxVal - minVal) / 10;
    let diffTemp = (maxValTemp - minValTemp) / 10;

    if (diffTemp < 0.5) {
      diffTemp = 3;
    }
    minVal = +minVal - +diff;
    maxVal = +maxVal + +diff;
    minThi = +minThi - +diff;
    maxThi = +maxThi + +diff;
    minSensorVal = +minSensorVal - +diff;
    maxSensorVal = +maxSensorVal + +diff;
    minValTemp = +minValTemp - +diffTemp;
    maxValTemp  = +maxValTemp  + +diffTemp;


    if (this.sensorValChecked) {
      if (minValTemp > minSensorVal) {
        minValTemp = minSensorVal;
      } else {
        minSensorVal = minValTemp;
      }

      if (maxValTemp < maxSensorVal) {
        maxValTemp = maxSensorVal;
      } else {
        maxSensorVal = maxValTemp;
      }
    }
    minVoltage = +minVoltage - +diff;
    maxVoltage = +maxVoltage + +diff;

    const dateParser = d3.timeParse('%d.%m.%Y %H:%M');

    const yScaleWeight = d3.scaleLinear()
      .domain([minVal, maxVal])
      .range([height, 0]);

    const yScaleAktor = d3.scaleLinear()
      .domain([0, 1])
      .range([height-1, height-60]);

    const yScaleVoltage = d3.scaleLinear()
      .domain([minVoltage, maxVoltage])
      .range([height, 0]);

    const yScaleSensorVal = d3.scaleLinear()
      .domain([minSensorVal, maxSensorVal])
      .range([height, 0]);


    const yScaleThi = d3.scaleLinear()
      .domain([minThi, maxThi])
      .range([height, 0]);


    const yScaleHumRel = d3.scaleLinear()
    .domain([minHumRel, maxHumRel])
    .range([height, 0]);


    const yScaleTemp = d3.scaleLinear()
      .domain([minValTemp, maxValTemp])
      .range([height, 0]);

    // const yScaleHum = d3.scaleLinear()
    //   .domain([0.0, 100.0])
    //   .range([height, 0]);

    const noiseScale = d3.scaleLinear()
      .domain([0, 100])
      .range([height, 0]);
    const maxScale = d3.scaleLinear()
      .domain([3.5, 4.2])
      .range([height, 0]);

    const xScale = d3.scaleTime()
      .domain([minTime, maxTime])
      // .domain(d3.extent(this.d3Array, function(d) {
      //   return dateParser(d.signal_time);
      // }))
      .range([0, width]);


    d3.select('#d3chartdiv>svg').remove();

    const svg = d3.select('#d3chartdiv').append('svg')
      .attr('id', 'd3chart')
      .attr('width', w)
      .attr('height', h);

    const chart = svg.append('g')
      .classed('display', true)
      .attr('transform', 'translate(' + margin.left + ' , ' + (margin.top ) + ')');

    chart.append('linearGradient')
      .attr('id', 'temperature-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0).attr('y1', yScaleThi(67))
      .attr('x2', 0).attr('y2', yScaleThi(72))
      .attr('x3', 0).attr('y3', yScaleThi(80))
      .selectAll('stop')
      .data([
        {offset: '0%', color: '#4CAF50'},
        {offset: '33%', color: '#fdd835'},
        {offset: '67%', color: '#fb8c00'},
        {offset: '100%', color: '#e53935'}
      ])
      .enter().append('stop')
      .attr('offset', function(d) { return d.offset; })
      .attr('stop-color', function(d) { return d.color; });


    d3.timeFormatDefaultLocale({
      dateTime: '%A, der %e. %B %Y, %X',
      date: '%d.%m.%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'], // unused
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      shortMonths: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    });

    // let xAxis = d3.axisBottom()
    //   .scale(scaleX);
    const yAxis = d3.axisLeft()
      .scale(yScaleThi)
      .ticks(8);

    const yAxisHumRel = d3.axisLeft()
      .scale(yScaleHumRel)
      .ticks(8);

    const yAxisTemp = d3.axisRight()
      .scale(yScaleTemp)
      .ticks(8);

    let xAxis;
    const lastWeek = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()-6, 23, 59, 59);

    if (isSmall) {
      xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(6)
        .tickFormat(function(date) {
            // if (d3.timeYear(date) < date) {
          if (lastWeek < date) {
              return d3.timeFormat('%A %H:%M')(date);
            } else {
              return d3.timeFormat('%a %d.%B %H:%M')(date);
            }
          }
        );
    } else {

      xAxis = d3.axisBottom()
        .scale(xScale)
        // .ticks(d3.timeHour, 2)
        .ticks(12)
        // .tickValues(
        //   xScale.domain().filter(function(d,i){
        //     return d.date
        //   }))
        .tickFormat(function(date) {
            if (lastWeek < date) {
              return d3.timeFormat('%A %H:%M')(date);
            } else {
              return d3.timeFormat('%a %d.%B %H:%M')(date);
            }
          }
        );
    }

    const yGridLines = d3.axisLeft()
      .scale(yScaleThi)
      .tickSize(-width, 0, 0)
      .tickFormat('')
      .ticks(8);

    chart.append('g')
      .classed('gridline', true)
      .call(yGridLines);

    chart.append('g')
      .classed('x axis axis--x', true)
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', -10)
      .attr('dy', 10)
      .attr('transform', 'translate(0,0) rotate(-45)');

    chart.append('g')
      .classed('y axis weightAxis', true)
      // .attr('transform', 'translate(0,0)')
      .attr('transform', 'translate(0,0)')
      .call(yAxis);

    chart.append('g')
      .classed('y axis tempAxis', true)
      .attr('transform', 'translate(' + (width) +',0)')
      .call(yAxisTemp);

      chart.append('g')
      .classed('y axis humRelAxis', true)
      .attr('transform', 'translate(' + (width) +',0)')
      .call(yAxisHumRel);



    chart.append('g')
      .classed('thiAxisNew', true)
      .append('text')
      .attr('transform', 'translate(0,' + (height/2) +') rotate(-90)')
      .attr('dy', -40)
      .style('text-anchor', 'middle')
      .text('THI-Wert / Luftfeuchtigkeit');

    // chart.append('g')
    //   .classed('weightAxis', true)
    //   .append('text')
    //   .attr('transform', 'translate(0,' + (height/2) +') rotate(-90)')
    //   .attr('dy', -40)
    //   .style('text-anchor', 'middle')
    //   .text('THI');

    chart.append('g')
      .classed('tempAxis', true)
      .append('text')
      .attr('dy', -40)
      .attr('transform', 'translate(' + (width) +',' + (height/2) +') rotate(90)')
      .style('text-anchor', 'middle')
      .text('Temperatur (°C) / abs. Luftfeuchte g/m³');
    const myTimeFormat = d3.timeFormat('%a %d.%B %H:%M');


    let index = 0;
    const dateLines = [];

    this.thiIndex = -1;
    this.scaleIndex = -1;
    this.tempIndex = -1;
    this.humIndex = -1;
    this.humRelIndex = -1;
    this.volIndex = -1;
    this.maxIndex = -1;
    this.sensorValIndex = -1;

    let line;
    let vcLine;
    let humLine;
    let thiLine;
    let humRelLine;
    let tempLine;
    let tempLine2;
    let aktorLine;

    const circleSelection = chart.selectAll('circle').data(this.event_array);
    const circleElements = circleSelection.enter().append('ellipse');
    circleElements.data();


    let kurvenStil = d3.curveCatmullRom; //curveBasis;
    if (this.lineStyle == 1) {
      kurvenStil = d3.curveCardinal;
    } else if (this.lineStyle == 2) {
      kurvenStil = d3.curveLinear;
    } else if (this.lineStyle == 3) {
      kurvenStil = d3.curveStep;
    } else if (this.lineStyle == 4) {
      kurvenStil = d3.curveBasis;//curveCatmullRom;
    } else if (this.lineStyle == 5) {
      kurvenStil = d3.curveMonotoneX;
    } else if (this.lineStyle == 6) {
      kurvenStil = d3.curveStepBefore;
    } else if (this.lineStyle == 7) {
      kurvenStil = d3.curveStepAfter;
    }


    // ==========================
    // GEWICHT
    if (this.scaleChecked) {
      line = d3.line()
        .defined(function(d){
          return d.draw_weight == 1; // d.weight != null;
        })
        .x(function(d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function(d){
          return yScaleWeight(d.temperature);
        }) // https://cemrajc.github.io/d3-curve-comparison/
        .curve(kurvenStil); // curveMonotoneX); // curveMonotoneX curveCardinal curveCatmullRom);


      this.scaleIndex = index;
      index++;
      const path = chart.selectAll('.weightline')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('weightline line', true);
      dateLines.push(path);

      chart.selectAll('.weightline')
        .style('fill', 'none')
        // .style('stroke', '#666')
        .attr('d', function(d) {
          return line(d);
        });
      chart.selectAll('.weightline')
        .data( [this.d3Array])
        .exit()
        .remove();

      // console.error('this.event_array: '+this.event_array);
      circleElements.attr('r', '16px')
        .attr('stroke', 'red')
        .attr('fill', 'rgba(255, 0, 0, 0.04)')
        .attr('stroke-width', '1')
        .attr('rx', '15px')
        .attr('ry', function (d) {
          return (height/(maxVal-minVal)) * (Math.abs(Math.abs(d.highest)-Math.abs(d.lowest))/2)+ 20;
        })
        .attr('cx', function (d) { return (xScale(dateParser(d.event_start)) +xScale(dateParser(d.event_end) ))/2; })
        .attr('cy', function (d) {
          console.log('d.start_weight: '+JSON.stringify(d));
          return yScaleWeight(d.lowest + Math.abs(Math.abs(d.highest)-Math.abs(d.lowest))/2);
        });
    }



    // ==========================
    // Brut-Temperatur
    if (this.humRelChecked) {
      humRelLine = d3.line()
        .x(function(d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function(d){
          return yScaleHumRel(d.humidity_rel);
        })
        .curve(kurvenStil); // curveCardinal);
      this.humRelIndex = index;
      index++;
      const pathhum = chart.selectAll('.humrelline')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('humrelline line', true);
      dateLines.push(pathhum);
      chart.selectAll('.humrelline')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function(d) {
          return humRelLine(d);
        });
      chart.selectAll('.humrelline')
        .data( [this.d3Array])
        .exit()
        .remove();


      //Punkte thi
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3Array);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        // .attr('r', '2px')
        // .attr('stroke', 'red')
          .attr('fill', '#4CAF50')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            // console.log('d.start_weight: '+JSON.stringify(d));
            return yScaleHumRel(d.humidity_rel);
          });
      }
    }

    // ==========================
    // Temperatur
    if (this.tempChecked) {
      tempLine = d3.line()
        .x(function (d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function (d) {
          return yScaleTemp(d.temperature2);
        })
        .curve(kurvenStil); // curveCardinal);

      this.tempIndex = index;
      index++;
      const path3 = chart.selectAll('.templine')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('templine line', true);
      dateLines.push(path3);
      chart.selectAll('.templine')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function (d) {
          return tempLine(d);
        });
      chart.selectAll('.templine')
        .data([this.d3Array])
        .exit()
        .remove();


      //Punkte
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3Array);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        // .attr('r', '2px')
        // .attr('stroke', 'red')
          .attr('fill', '#2196F3')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            // console.log('d.start_weight: '+JSON.stringify(d));
            return yScaleTemp(d.temperature2);
          });
      }
      //Zweite Linie
      // tempLine2 = d3.line()
      //   .x(function (d) {
      //     return xScale(dateParser(d.signal_time));
      //   })
      //   .y(function (d) {
      //     return yScaleTemp(d.temperature2);
      //   })
      //   .curve(kurvenStil); // curveCardinal);
      //
      // this.tempIndex = index;
      // index++;
      // const path4 = chart.selectAll('.templine2')
      //   .data([this.d3Array])
      //   .enter()
      //   .append('path')
      //   .classed('templine2 line', true);
      // dateLines.push(path4);
      // chart.selectAll('.templine2')
      //   .style('fill', 'none')
      //   // .style('stroke', '#EB6565')
      //   .attr('d', function (d) {
      //     return tempLine2(d);
      //   });
      // chart.selectAll('.templine2')
      //   .data([this.d3Array])
      //   .exit()
      //   .remove();



    }


    // ==========================
    // Humidity
    if (this.humChecked) {
      humLine = d3.line()
        .x(function (d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function (d) {
          return yScaleThi(d.humidity);
          // return yScaleHum(d.humidity);
        })
        .curve(kurvenStil); // curveCardinal);
      this.humIndex = index;
      index++;
      const path4 = chart.selectAll('.humline')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('humline line', true);
      dateLines.push(path4);
      chart.selectAll('.humline')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function (d) {
          return humLine(d);
        });
      chart.selectAll('.humline')
        .data([this.d3Array])
        .exit()
        .remove();

      //Punkte hum
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3Array);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        // .attr('r', '2px')
        // .attr('stroke', 'red')
          .attr('fill', '#9C27B0')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            // console.log('d.start_weight: '+JSON.stringify(d));
            return yScaleThi(d.humidity);
          });
      }
    }

    // ==========================
    // VOLTAGE
    if (this.vcChecked) {
      vcLine = d3.line()
        .x(function (d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function (d) {
          return yScaleVoltage(d.voltage);
        })
        .curve(kurvenStil); // curveCardinal);
      this.volIndex = index;
      index++;
      const path4 = chart.selectAll('.vcline')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('vcline line', true);
      dateLines.push(path4);
      chart.selectAll('.vcline')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function (d) {
          return vcLine(d);
        });
      chart.selectAll('.vcline')
        .data([this.d3Array])
        .exit()
        .remove();

      //Punkte vc
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3Array);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        // .attr('r', '2px')
        // .attr('stroke', 'red')
          .attr('fill', '#CDDC39')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            return yScaleVoltage(d.voltage);
          });
      }
    }



    // ==========================
    // AKTOR
    if (this.aktorValChecked && (this.d3AktorArray != undefined)) {
      var area = d3.area()
        .x(function(d) { return xScale(dateParser(d.signal_time)); })
        .y0(height)
        .y1(function(d) { return yScaleAktor(d.status); })
        .curve(d3.curveStepAfter);;

      chart.append("path")
        .data([this.d3AktorArray])
        .attr("class", "area-aktor")
        .attr("d", area);

      aktorLine = d3.line()
        .x(function (d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function (d) {
          return yScaleAktor(d.status);
        })
        .curve(d3.curveStepAfter); // curveCardinal);

      // this.volIndex = index;
      // index++;
      const path11 = chart.selectAll('.aktorline')
        .data([this.d3AktorArray])
        .enter()
        .append('path')
        .classed('aktorline line', true);
      // dateLines.push(path11);
      chart.selectAll('.aktorline')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function (d) {
          return aktorLine(d);
        });
      chart.selectAll('.aktorline')
        .data([this.d3AktorArray])
        .exit()
        .remove();

      //Punkte sensorval
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3AktorArray);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        .attr('r', '2px')
        .attr('stroke', 'red')
          .attr('fill', '#3F51B5')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            return yScaleAktor(d.status);
          });
      }
    }



    // ==========================
    // SENSOR_VAL
    if (this.sensorValChecked) {
      vcLine = d3.line()
        .x(function (d) {
          return xScale(dateParser(d.signal_time));
        })
        .y(function (d) {
          return yScaleSensorVal(d.sensor_val);
        })
        .curve(kurvenStil); // curveCardinal);
      this.sensorValIndex = index;
      index++;
      const path4 = chart.selectAll('.sensorValline')
        .data([this.d3Array])
        .enter()
        .append('path')
        .classed('sensorValline line', true);
      dateLines.push(path4);
      chart.selectAll('.sensorValline')
        .style('fill', 'none')
        // .style('stroke', '#EB6565')
        .attr('d', function (d) {
          return vcLine(d);
        });
      chart.selectAll('.sensorValline')
        .data([this.d3Array])
        .exit()
        .remove();


      //Punkte sensorval
      if (this.showPoints) {
        const circlesSelection =
          chart.selectAll('circle')
            .data(this.d3Array);
        const circles = circlesSelection.enter().append('ellipse');
        circles.data();
        // console.error('this.event_array: '+this.event_array);
        circles
        // .attr('r', '2px')
        // .attr('stroke', 'red')
          .attr('fill', '#3F51B5')
          .attr('fill', '#333333')
          // .attr('stroke-width', '1')
          .attr('rx', '1px')
          .attr('ry', '1px')
          .attr('cx', function (d) {
            return xScale(dateParser(d.signal_time));
          })
          .attr('cy', function (d) {
            return yScaleSensorVal(d.sensor_val);
          });
      }
    }
    this.levelIndex = index;
    index++;


    //TODO curveStepBefore für Aktor-kurve





    // ZOOM

    // const zoom = d3.zoom()
    //   .scaleExtent([1, 32])
    //   .translateExtent([[0, 0], [width, height]])
    //   .extent([[0, 0], [width, height]])
    //   .on('zoom', zoomed);
    //
    // function zoomed() {
    //   const t = d3.event.transform, xt = t.rescaleX(x);
    //   g.select('.area').attr('d', area.x(function(d) { return xt(d.date); }));
    //   g.select('.axis--x').call(xAxis.scale(xt));
    // }




    // const zoomChart = svg.append('g')
    //   .classed('display', true)
    //   .attr('transform', 'translate(' + margin.left + ' , ' + (margin.top + height + heightWeight ) + ')');

    // const
    //   x2 = d3.scaleTime().range([0, width]),
    //   y2 = d3.scaleLinear().range([heightZoom, 0]);
    // x2.domain(xScale.domain());
    // const
    //   xAxis2 = d3.axisBottom(x2);
    //
    //
    // const brush = d3.brushX()
    //   .extent([[margin.left, margin.top], [width-margin.left-margin.right, heightZoom]])
    //   .on('brush end', brushed);
    //
    // const zoom = d3.zoom()
    //   .scaleExtent([1, Infinity])
    //   .translateExtent([[margin.left, margin.top], [width-margin.left-margin.right, height]])
    //   .extent([[margin.left, margin.top], [width-margin.left-margin.right, height]])
    //   .on('zoom', zoomed);
    //
    // zoomChart.append('g')
    //   .attr('class', 'axis axis--x')
    //   .attr('transform', 'translate(0,' + heightZoom + ')')
    //   .call(xAxis2);
    //
    // zoomChart.append('g')
    //   .attr('class', 'brush')
    //   .call(brush)
    //   .call(brush.move, x2.range());
    //
    // svg.append('rect')
    //   .attr('class', 'zoom')
    //   .attr('width', width)
    //   .attr('height', heightZoom)
    //   .attr('transform', 'translate(' + margin.left + ',' + (margin.top+height+heightWeight) + ')')
    //   .call(zoom);
    //
    // svg.append('defs').append('clipPath')
    //   .attr('id', 'clip')
    //   .append('rect')
    //   .attr('width', width)
    //   .attr('height', height);
    //
    //
    // function zoomed() {
    //   if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'brush') {
    //     return; // ignore zoom-by-brush
    //   }
    //   const t = d3.event.transform;
    //   xScale.domain(t.rescaleX(x2).domain());
    //   chart.select('.weightline').attr('d', line);
    //   chart.select('.vcline').attr('d', vcLine);
    //   chart.select('.humline').attr('d', humLine);
    //   chart.select('.templine').attr('d', tempLine);
    //   chart.select('.brutline').attr('d', thiLine);
    //   chart.select('.axis--x').call(xAxis);
    //   zoomChart.select('.brush').call(brush.move, xScale.range().map(t.invertX, t));
    // }
    //
    // function brushed() {
    //   if (d3.event.sourceEvent && d3.event.sourceEvent.type === 'zoom') {
    //     return;
    //   } // ignore brush-by-zoom
    //   const s = d3.event.selection || x2.range();
    //   xScale.domain(s.map(x2.invert, x2));
    //   chart.select('.weightline').attr('d', line);
    //   chart.select('.vcline').attr('d', vcLine);
    //   chart.select('.humline').attr('d', humLine);
    //   chart.select('.templine').attr('d', tempLine);
    //   chart.select('.brutline').attr('d', thiLine);
    //   chart.select('.axis--x').call(xAxis);
    //   svg.select('.zoom').call(zoom.transform, d3.zoomIdentity
    //     .scale(width / (s[1] - s[0]))
    //     .translate(-s[0], 0));
    // }




    const mouseG = chart.append('g')
      .attr('class', 'mouse-over-effects');

    // this is the vertical line
    mouseG.append('path')
      .attr('class', 'mouse-line')
      .style('stroke', '#bbb')
      .style('stroke-width', '1px')
      .style('opacity', '0');

    let lines: any;
    lines = document.getElementsByClassName('line');
    const mousePerLine = mouseG.selectAll('.mouse-per-line')
      .data(dateLines)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    const self = this;
    // the circle
    mousePerLine.append('circle')
      .attr('r', 3)
      .style('fill', function(d, i) {
        if (i == self.scaleIndex) {
          return '#009688';
        } else if (i == self.thiIndex) {
          return '#4CAF50';
          // return '#D50000';
        } else if (i == self.tempIndex) {
          return '#2196F3';
        } else if (i == self.humIndex) {
          return '#9C27B0';
        } else if (i == self.humRelIndex) {
          return '#1b5783';
        } else if (i == self.levelIndex) {
          return '#3F51B5';
        } else if (i == self.maxIndex) {
          return '#CDDC39';
        } else if (i == self.volIndex) {
          return '#CDDC39';
        } else if (i == self.sensorValIndex) {
          return '#3F51B5';
        }
      })
      .style('stroke-width', '1px')
      .style('opacity', '0');

    // the text
    mousePerLine.append('text')
      .attr('transform', 'translate(10,3)');
    // rect to capture mouse movements
    mouseG.append('svg:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', function() { // on mouse out hide line, circles and text
        d3.select('.mouse-line')
          .style('opacity', '0');
        d3.selectAll('.mouse-per-line circle')
          .style('opacity', '0');
        d3.selectAll('.mouse-per-line text')
          .style('opacity', '0');
      })
      .on('mouseover', function() { // on mouse in show line, circles and text
        d3.select('.mouse-line')
          .style('opacity', '1');
        d3.selectAll('.mouse-per-line circle')
          .style('opacity', '1');
        d3.selectAll('.mouse-per-line text')
          .style('opacity', '1');
      })
      .on('mousemove', function() { // mouse moving over canvas
        const mouse = d3.mouse(this);
        d3.select('.mouse-line')
          .attr('d', function() {
            let d = 'M' + mouse[0] + ',' + height;
            d += ' ' + mouse[0] + ',' + 0;
            return d;
          });
        d3.selectAll('.mouse-per-line')
          .attr('transform', function(d, i) {
            self.mouseOverTime = myTimeFormat(xScale.invert(mouse[0]));
            let pos;
            let beginning = 0,
              end = lines[i].getTotalLength(),
              target = null;
            while (true) {
              target = Math.floor((beginning + end) / 2);
              pos = lines[i].getPointAtLength(target);
              if ((target === end || target === beginning) && pos.x !== mouse[0]) {
                break;
              }
              if (pos.x > mouse[0]) {
                end = target;
              } else if (pos.x < mouse[0]) {
                beginning = target;
              } else {
                break; // position found
              }
            }

            if (i == self.thiIndex) {
              // Bei Brutraum nicht y verwenden
              // d3.select(this).select('text')
              //   .text(yTemp.invert(pos.y).toFixed(2)+' °C');
              self.mouseThi = yScaleThi.invert(pos.y).toFixed(2)+'';
            } else if (i == self.tempIndex) {
              // Bei Brutraum nicht y verwenden
              self.mouseTempWeather = yScaleTemp.invert(pos.y).toFixed(2)+' °C';
            } else if (i == self.humIndex) {
              // Bei Brutraum nicht y verwenden
              // self.mouseHum = yScaleHum.invert(pos.y).toFixed(2)+' %';
              self.mouseHum = yScaleThi.invert(pos.y).toFixed(2)+' %';

            } else if (i == self.humRelIndex) {
              // Bei Brutraum nicht y verwenden
              // self.mouseHum = yScaleHum.invert(pos.y).toFixed(2)+' %';
              self.mouseHumRel = yScaleHumRel.invert(pos.y).toFixed(2)+' g/m³';

            } else if (i == self.maxIndex) {
              // Bei Brutraum nicht y verwenden
              self.mouseMax = yScaleThi.invert(pos.y).toFixed(2)+' Vc';
              // self.mouseMax = yScaleHum.invert(pos.y).toFixed(2)+' Vc';
            } else if (i == self.scaleIndex) {
              // Bei Brutraum nicht y verwenden
              self.mouseWeight = yScaleWeight.invert(pos.y).toFixed(2)+'';
              // d3.select(this).select('text')
              //   .html(y.invert(pos.y).toFixed(2)+' kg');
            } else if (i == self.volIndex) {
              // Bei Brutraum nicht y verwenden
              self.mouseVol = yScaleVoltage.invert(pos.y).toFixed(2)+'V';
              // d3.select(this).select('text')
              //   .html(y.invert(pos.y).toFixed(2)+' kg');
            } else if (i == self.sensorValIndex) {
              self.mouseSensorVal = yScaleSensorVal.invert(pos.y).toFixed(2)+' °C';
            }
            return 'translate(' + mouse[0] + ',' + pos.y +')';
          });
      });


    // Statistik berechnen
    const newItem = {};
    self.statisticItems = [];
    const myTodaySix = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate(), 6, 0, 0);
    let sixToNow = this.d3Array.filter(function (sensorData) {
      // true => kommt ins array, false wird ignoriert
      return myTodaySix.getTime() < sensorData.my_time; // new Date(weight.signal_time).getTime();
    });

    // console.log('myTodaySix.getTime(): ',moment(myTodaySix.getTime()).format(timeFormat));
    newItem ['name'] = 'Gewicht (seit 06:00)'; // time.getTime();
    newItem ['hint'] = 'Gewichtsänderung 06:00 bis jetzt'; // time.getTime();
    const changeBarrier = 0.1;

    if (sixToNow.length > 1) {
      const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
      if (myChange > 0) {
        newItem ['change'] = '+' +myChange.toFixed(2)+' kg';
      } else {
        newItem ['change'] = '' +myChange.toFixed(2)+' kg';
      }
      newItem ['max'] = ''+d3.max(sixToNow, function (d) {
        return d.temperature;
      });
      newItem ['min'] = ''+d3.min(sixToNow, function (d) {
          return d.temperature;
        });
      newItem ['delta'] = '';
      if (myChange > changeBarrier) {
        newItem ['trending'] = 2;
      } else if (myChange < -changeBarrier) {
        newItem ['trending'] = 0;
      } else {
        newItem ['trending'] = 1;
      }
      // console.log('sixToNow[0].weight 1: ', newItem ['min']+' w2: '+newItem ['max']);
      self.statisticItems.push(newItem);
    } else {
      console.log('noch keine Daten vor 6 Uhr!');
      newItem ['change'] = '0 kg';
      newItem ['max'] = '-';
      newItem ['min'] = '-';
      newItem ['delta'] = '-';
      newItem ['trending'] = 1;
      self.statisticItems.push(newItem);
    }



    const newItem2 = {};
    // console.log('myTodaySix.getTime(): ',moment(myTodaySix.getTime()).format(timeFormat));
    newItem2 ['name'] = 'Gewicht gestern (bis heute 06:00)'; // time.getTime();
    newItem2 ['hint'] = 'Gewichtsänderung 06:00 bis jetzt'; // time.getTime();

    const myYesterdaySix = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate()-1, 6, 0, 0);
    sixToNow = this.d3Array.filter(function (weight) {
      // true => kommt ins array, false wird ignoriert
      return (myYesterdaySix.getTime() < weight.my_time) && (myTodaySix.getTime() > weight.my_time) ;
    });

    if (sixToNow.length > 1) {
      const myChange = (+sixToNow[sixToNow.length-1].temperature - +sixToNow[0].temperature);
      if (myChange > 0) {
        newItem2 ['change'] = '+' +myChange.toFixed(2)+' kg';
      } else {
        newItem2 ['change'] = '' +myChange.toFixed(2)+' kg';
      }
      newItem2 ['max'] = ''+d3.max(sixToNow, function (d) {
          return d.temperature;
        });
      newItem2 ['min'] = ''+d3.min(sixToNow, function (d) {
          return d.temperature;
        });
      newItem2 ['delta'] = '';
      if (myChange > changeBarrier) {
        newItem2 ['trending'] = 2;
      } else if (myChange < -changeBarrier) {
        newItem2 ['trending'] = 0;
      } else {
        newItem2 ['trending'] = 1;
      }
      // console.log('sixToNow[0].weight 2: ', newItem2 ['min']+' w2: '+newItem2 ['max']);
      self.statisticItems.push(newItem2);
    } else {
      console.log('noch keine Daten vor 6 Uhr!');
      newItem2 ['change'] = '0 kg';
      newItem2 ['max'] = '-';
      newItem2 ['min'] = '-';
      newItem2 ['delta'] = '-';
      newItem2 ['trending'] = 1;
      self.statisticItems.push(newItem2);
    }

    this.riseWeek = this.weightHighestDay - this.weightHighestWeek;
    this.riseDay = this.weightHighestDay - this.weightHighestTwoDay;




    /* ===========================================
        WEIGHT- Chart
    ============================================= */
    // d3.select('#d3chartdiv2>svg').remove();
    // const svg2 = d3.select('#d3chartdiv2').append('svg')
    //   .attr('id', 'd3chart2')
    //   .attr('width', w)
    //   .attr('height', h);
    //
    // const chart2 = svg2.append('g')
    //   .classed('display', true)
    //   .attr('transform', 'translate(' + margin.left + ' , ' + margin.top + ')');
    //
    //
    // // const xAxis = d3.axisBottom()
    // //   .scale(xScale)
    // //   // .ticks(d3.timeHour, 2)
    // //   .ticks(12)
    // //   .tickFormat(d3.timeFormat('%a %d.%B %H:%M'));
    //
    //
    //
    //
    // minVal = d3.min(this.d3Array, function (d) {
    //   return d.weightDiff;
    // });
    // maxVal = d3.max(this.d3Array, function (d) {
    //   return d.weightDiff;
    // });
    // minVal = +minVal - +diff;
    // maxVal = +maxVal + +diff;
    // const yScaleBarWeight = d3.scaleLinear()
    //   .domain([minVal, maxVal])
    //   .range([height, 0]);
    //
    // const yBarWeight = d3.axisLeft()
    //   .scale(yScaleBarWeight)
    //   .tickSize(-width, 0, 0)
    //   .tickFormat('')
    //   .ticks(8);
    //
    // chart2.append('g')
    //   .classed('gridline', true)
    //   .call(yGridLines);
    //
    // chart2.append('g')
    //   .classed('x axis', true)
    //   // .attr('transform', 'translate(0,0)')
    //   .attr('transform', 'translate(0,' + height + ')')
    //   .call(xAxis)
    //   .selectAll('text')
    //   .style('text-acnhor', 'end')
    //   .attr('dx', -40)
    //   .attr('dy', 0)
    //   .attr('transform', 'translate(0,0) rotate(-45)');
    //
    // chart2.append('g')
    //   .classed('y axis weightAxis', true)
    //   // .attr('transform', 'translate(0,0)')
    //   .attr('transform', 'translate(0,0)')
    //   .call(yAxis);
    //
    // chart2.append('g')
    //   .classed('weightAxis', true)
    //   .append('text')
    //   .attr('transform', 'translate(0,' + (height/2) +') rotate(-90)')
    //   .attr('dy', -40)
    //   .style('text-anchor', 'middle')
    //   .text('Gewicht (kg)');
  }

  reloadWithLimit(limit, event) {
    const sections = this.myElement.nativeElement.querySelectorAll('.mat-chip');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('mat-chip-selected');
    }
    event.target.classList.add('mat-chip-selected'); // To ADD
    console.log('myElement: ', event.srcElement);
    this.myLimit = limit;
    this.reloadData();
  }
}





function join(lookupTable, mainTable, lookupKey, mainKey, select) {
  const l = lookupTable.length,
    m = mainTable.length,
    lookupIndex = [],
    output = [];
  for (let i = 0; i < l; i++) { // loop through l items
    const row = lookupTable[i];
    lookupIndex[row[lookupKey]] = row; // create an index for lookup table
  }
  for (let j = 0; j < m; j++) { // loop through m items
    const y = mainTable[j];
    const x = lookupIndex[y[mainKey]]; // get corresponding row from lookupTable
    output.push(select(y, x)); // select only the columns you need
  }
  return output;
};

// const d3Array = last24hours.map(function (weight) {
//   return {
//     signal_time: moment(weight.signal_time).add(2, 'hours').format(timeFormat),
//     my_time: new Date(weight.signal_time).getTime(),
//     weight: ((+weight.weight1) / (22.24348751 * 1000)).toFixed(3)
//   };
// });
//
//
// let minVal = d3.min(d3Array, function (d) {
//   return d.weight;
// });
// let maxVal = d3.max(d3Array, function (d) {
//   return d.weight;
// });
// const lowHighVal = d3.extent(d3Array, function (d) {
//   return d.weight;
// });
// let minTime = d3.min(d3Array, function (d) {
//   return d.my_time;
// });
// let maxTime = d3.max(d3Array, function (d) {
//   return d.my_time;
// });
//
// // console.log('minVal: ' + minVal);
// // console.log('maxVal: ' + maxVal);
// // console.log('minTime: ' + minTime);
// // console.log('maxTime: ' + maxTime);
// // console.log('lowHighVal: ' + lowHighVal);
// const w = 1000;
// const h = 500;
// const margin = {
//   top: 60,
//   bottom: 40,
//   left: 60,
//   right: 20
// };
//
// const width = w - margin.left - margin.right;
// const height = h - margin.top - margin.bottom;
//
// minTime = minTime - 1000 * 60 * 60;
// maxTime = maxTime + 1000 * 60 * 60;
// const diff = (maxVal - minVal) / 10;
// minVal = +minVal - +diff;
// maxVal = +maxVal + +diff;
//
//
// let scaleX = d3.scaleTime()
//   .domain([minTime, maxTime])
//   .range([0, width]);
// // let scaleX = d3.scaleLinear()
// //   .domain([minTime, maxTime])
// //   .range([0, width]);
//
// let scaleY = d3.scaleLinear()
//   .domain([minVal, maxVal])
//   .range([height, 0]);
//
// d3.select('svg').remove();
// let svg = d3.select('#d3chartdiv').append('svg')
//   .attr('id', 'd3chart')
//   .attr('width', w)
//   .attr('height', h);
//
// let chart = svg.append('g')
//   .classed('display', true)
//   .attr('transform', 'translate(' + margin.left + ' , ' + margin.top + ')');
//
// this.plot.call(svg, {
//   data: d3Array
// });
//
// let xAxis = d3.axisBottom()
//   .scale(scaleX);
// let yAxis = d3.axisLeft()
//   .scale(scaleY);
// let yGridLines = d3.axisLeft()
//   .scale(scaleY)
//   .tickSize(-width, 0, 0)
//   .tickFormat('');
//
// chart.append('g')
//   .call(yGridLines)
//   .style('fill', 'none')
//   .style('stroke', 'orange')
//   .classed('gridline', true);
//
//
// // enter()
// chart.selectAll('.bar')
//   .data(d3Array)
//   .enter()
//   .append('rect')
//   .classed('bar', true)
//   .on('mouseover', function (d, i) {
//     d3.select(this).style('fill', 'yellow');
//   })
//   .on('mouseout', function(d, i) {
//     d3.select(this).style('fill', 'FF0000');
//   });
//
//
// // einschalten()
// chart.selectAll('.bar')
//   .transition()
//   .duration(200)
//   // .ease('bounce')
//   .attr('x', function(data, index) {
//     return scaleX(data.my_time);
//   })
//   .attr('y', function(data, index) {
//     return scaleY(data.weight);
//   })
//   .attr('height', '2')
//   .attr('width', '2')
//   .style('fill', function (d, i) {
//     return '#FF0000';
//   });
//
// // exit()
// chart.selectAll('.bar')
//   .data(d3Array)
//   .exit()
//   .remove();
//
// const dateParser = d3.timeParse('%d.%m.%Y %H:%M');
//
// // d3Array.forEach(function (newWeight) {
// //   // console.log(newWeight.signal_time);
// //   console.log(newWeight.signal_time);
// //   console.log(dateParser(newWeight.signal_time));
// // });
//
//
// chart.selectAll('.bar-label')
//   .data(d3Array)
//   .enter()
//   .append('text')
//   .classed('point-label', true)
//   .attr('x', function(data, index) {
//     return scaleX(data.my_time);
//   })
//   .attr('y', 20)
//   .text(function(d, i) {
//     return ''; // d.weight;
//   });
//
// chart.append('g')
//   .classed('x axis', true)
//   // .attr('transform', 'translate(0,0)')
//   .attr('transform', 'translate(0,' + height + ')')
//   .call(xAxis)
//   .selectAll('text')
//   .style('text-acnhor', 'end')
//   .attr('dx', -16)
//   .attr('dy', 16)
//   .attr('transform', 'translate(0,0) rotate(-45)');
//
// chart.append('g')
//   .classed('y axis', true)
//   // .attr('transform', 'translate(0,0)')
//   .attr('transform', 'translate(0,0)')
//   .call(yAxis);
//
// chart.select('.y.axis')
//   .append('text')
//   .attr('x', 0)
//   .attr('y', 0)
//   .style('text-anchor', 'middle')
//   .attr('transform', 'translate(0,' + height/2+ ') rotate(-90)')
//   .text('Temperatur');
