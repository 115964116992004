import {
  AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';
import {OrderByWeight} from '../_pipes/orderbyweight';
import {SocketService} from "../_services/socket.service";
import {PushNotificationsService} from "ng-push";
import {Subscription} from "rxjs/Rx";


declare var d3: any;

@Component({
  selector: 'modb-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})

export class DashComponent implements OnInit, AfterViewInit {
  @ViewChild('pushinput') pushinput;
  toggleval = 'standard';
  direction = "column";
  t_start = 0;
  t_end = 0;
  // sensorVal = 0;
  public messages: Array<any>;
  // public messages: Subject<Message>;

  private counterSubscription: Subscription;

  constructor(public authService: AuthService,
              public muuService: MuuService,
              private myElement: ElementRef,
              public dialog: MatDialog,
              private router: Router,
              public snackBar: MatSnackBar,
              private headerService: HeaderService,
              private socketService: SocketService,
              private _pushNotifications: PushNotificationsService) {
    this.askPermission();
    this.messages = [];
    // this.websocket.send("test");
    // this.websocket.getEventListener().

  }

  ngOnDestroy(){
    if(this.counterSubscription){
      this.counterSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.loadData();
    const self = this;
    window.addEventListener('resize', function () {
      // self.plotData();
    });
    const headerData = new HeaderData();
    headerData.title = 'Übersicht';
    headerData.id = 1;
    this.headerService.headerData.next(headerData);

  }

  ngAfterViewInit() {
    const self = this;
    // this.counterSubscription = this.socketService.messages.subscribe(msg => {
    //   console.info("NEW message: "+msg.toString());
    // });

    // this.socketService.checkConnection();
    this.counterSubscription = this.socketService.getEventListener().subscribe(event => {
      if(event.type == "message") {
        let data = event.data.content;
        this.handleNotification(data, self);
        if(event.data.sender) {
          data = event.data.sender + ": " + data;
        }
        console.info("socket message: "+data);
        this.messages.push(data);
      }
      if(event.type == "close") {
        console.error("The socket connection has been closed");
        this.messages.push("/The socket connection has been closed");
      }
      if(event.type == "open") {
        console.log("The socket connection has been established, mail: "+ this.authService.token);
        this.messages.push(this.authService.token, "/The socket connection has been established");
      }
    });

    // console.log("pushinput: "+this.pushinput.nativeElement.value);
    this.pushinput.nativeElement.addEventListener("pushevent",
      function(e) {
        this.handleNotification(e.detail, self);
      });
  }

  private handleNotification(msg, self: this) {
    console.log("handleNotification: " + JSON.stringify(msg));
    var res = msg.split("/");
    if (res[0] == "1") {
      let status = "";
      //Push-Nachricht anzeigen
      if (res[1] == "1") {
        status = "eingeschalten";
      } else {
        status = "ausgeschalten";
      }
      let id_sensor = parseInt(res[2]);

      let modus = 0;
      let grund = 0;
      let signal_time = "";
      if (res.length > 3) {
        // console.log("res.length > 3")
        modus = parseInt(res[5]);
        grund = parseInt(res[4]);
        signal_time = res[3];

        //TODO grund muss geändert werden!!
        // console.log("res.length > 3 - grund: " + grund + " modus: " + modus);
      }

      for (let i = 0; i < self.muuService.sensors.length; i++) {
        if (self.muuService.sensors[i].id_sensoren == id_sensor) {
          console.log("aktor " + self.muuService.sensors[i].name_sensor + " " + status);

          if (res.length > 3) {
            self.muuService.sensors[i].modus = modus;
            self.muuService.sensors[i].grund = grund;
            self.muuService.sensors[i].signal_time_aktor = signal_time;

            if (modus == 0) {
              self.muuService.sensors[i].checked = true;
            } else if (modus == 1) {
              self.muuService.sensors[i].checked = false;
            }
          }

          if (res[1] == "1") {
            self.muuService.sensors[i].status = 1;
          } else {
            self.muuService.sensors[i].status = 0;
          }
        }
      }
    } else {
      console.log("sw hat falsche Daten geschickt")
    }
  }

  pushevent(event) {
    console.log('event: '+JSON.stringify(event));
    this.muuService.sensorVal = 1;
  }

  einschalten(value) {
    console.log('einschalten: '+JSON.stringify(value));
    this.muuService.sensorVal = 1;
  }
  ausschalten(value) {
    console.log('ausschalten: '+JSON.stringify(value));
    this.muuService.sensorVal = 0;
  }

  registerServiceWorker() {
    return navigator.serviceWorker.register('sw.js')
      .then(function(registration) {
        // console.log('Service worker successfully registered.');
        return registration;
      })
      .catch(function(err) {
        console.error('Unable to register service worker.', err);
      });
  }

  askPermission() {
    const self = this;
    return new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
      .then(function(permissionResult) {
        if (permissionResult !== 'granted') {
          console.error('permissionResult NOT granted');
          // throw new Error('We weren\'t granted permission.');
        } else {
          // console.log('permissionResult granted');
          self.subscribeUserToPush();
        }
      });
  }

  subscribeUserToPush() {
    const self = this;
    return this.registerServiceWorker()
      .then(function(registration) {

        const subscribeOptions = {
          userVisibleOnly: true,
          // applicationServerKey: self.urlBase64ToUint8Array(
          //   'BEl62iUYgUivxIkv69yViEuiBIa-Ib9-SkvMeAtA3LFgDzkrxZJjSgSnfckjBJuBkr3qBUYIHBQFLXYp5Nksh8U'
          // )
          applicationServerKey: self.urlBase64ToUint8Array(
            'BAnYGYW-1cdNSLEO09NFahVfDwdomDrKWLxTOkAiW31MI-6-K2qDUlniF1gntNoOD16-CPSvOfVamINE0dl8rPw'
          )
        };
        if (typeof registration !== "undefined") {

          registration.update();

          // console.log('subscribe registration - unregister');
          // registration.unregister();
          return registration.pushManager.subscribe(subscribeOptions);
        } else {
          console.error('registration failed..');
          // registration.unregister();
        }
      })
      .then(function(pushSubscription) {
        // console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
        self.muuService.registerPush(JSON.stringify(pushSubscription));
        return pushSubscription;
      });
  }

  toggleDirection() {
    let next = (DIRECTIONS.indexOf(this.direction) +1 ) % DIRECTIONS.length;
    this.direction = DIRECTIONS[next];
  }


  loadData() {
    this.t_start = new Date().getTime();
    // console.log('getMyData loadData');
    if (!this.muuService.started_dash) {
      console.log('loadData in dash notwendig');
      this.muuService.getMyData();
    } else {
      //TODO nur bei Develoepr-mode
      console.log('loadData in dash - nicht notwendig');
      this.muuService.getMyData();
    }

    // this.muuService.getGateways()
    //   .subscribe(apiaries => {
    //       this.t_end = new Date().getTime();
    //       // console.log('getGateways: ', this.t_end -  this.t_start, ' sensors: '+this.muuService.sensors);
    //       // for (var regler of this.muuService.sensors) {
    //       //   // console.log(regler);
    //       // }
    //     },
    //     err => {
    //       console.error('There was an error: ' + err);
    //     });
  }

  reloadData() {
    this.muuService.started_dash = false;
    this.loadData();
  }

  openSensor(sensor) {
    // console.log('openSensor event: ', regler.id_sensoren);
    this.router.navigate(['/statistic/'+sensor.id_sensoren]);
  }

  openDetails(sensor) {
    // console.log('openSensor event: ', regler.id_sensoren);
    this.router.navigate(['/details/'+sensor.id_sensoren]);
  }

  addApiary() {
    const dialogRef = this.dialog.open(AddEditDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      // console.log('apiaryname:' + apiaryname);
      // TODO Service - createApiary
      this.openSnackBar('Neuen Standort erstellen: ' + apiaryname);
    });
  }

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  openSnackBar(snackmessage) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
    });
  }
}

@Component({
  selector: 'snack-bar-component-example-snack',
  template: `
     <span style="color: white">
        erstelle Standort... (geht noch nicht)
   </span>
  `,
})


export class SnackbarComponent {
  constructor(public snackBar: MatSnackBar) {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}

const DIRECTIONS = ['column', 'column-reverse'];
