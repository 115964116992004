import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';


declare var d3: any;

@Component({
  selector: 'modb-aktoren',
  templateUrl: './aktoren.component.html',
  styleUrls: ['./aktoren.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})
export class AktorenComponent implements OnInit {
  toggleval = 'standard';

  constructor(public muuService: MuuService,
              private router: Router,
              private headerService: HeaderService) {

  }

  einschalten(value) {
    console.log('einschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 1;
  }
  ausschalten(value) {
    console.log('ausschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 0;
  }

  ngOnInit() {
    window.addEventListener('resize', function () {
      // self.plotData();
    });
    const headerData = new HeaderData();
    headerData.title = 'Aktoren';
    headerData.id = 2;
    this.headerService.headerData.next(headerData);
  }
  openSensor(sensor) {
    console.log('openSensor event: ', sensor.id_sensoren);
    this.router.navigate(['/statistic/'+sensor.id_sensoren]);
  }
}