import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';

@Component({
  selector: 'modb-question-dialog',
  template: ` 
    
    <h2 mat-dialog-title>{{question_title}}</h2>
    <mat-dialog-content>{{question}}</mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-item"></span>
      <button mat-button mat-dialog-close>Nein</button>
      <!-- Can optionally provide a result for the closing dialog. -->
      <button mat-button [mat-dialog-close]="true">Ja</button>
    </mat-dialog-actions>
  `,
// <mat-form-field>
// <input [(ngModel)]="editvalue" matInput placeholder="{{location_playceholder}}" value="">
// <!--<button type="button" mat-raised-button-->
// <!--(click)="dialogRef.close(returnName())">{{ok_value}}</button>-->
//   </mat-form-field>
})

export class QuestionDialogComponent {
  question_title = 'Szene löschen';
  question = 'Soll die Szene wirklich gelöscht werden?';
  cancel = 'Abbrechen';
  ok_value = 'Erstellen';

  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.question = data.question ? data.question : "";
    this.question_title = data.question_title ? data.question_title : "";

  }
}
