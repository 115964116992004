import { Input } from '@angular/core';
import {Sensor} from './sensor';

export class Gateway {
  id_gateways: number;
  id_account: number;
  device_id: string;
  create_time: string;
  name_gateway: string;
  lat: number;
  lng: number;
  sensors: Sensor[];
  change_time: string;
  constructor () {
  }
}
