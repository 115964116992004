import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (localStorage.getItem('currentUser')) {
          // logged in so return true
          return true;
      }
      // not logged in so redirect to login page
      this.router.navigate(['/signin']);
      return false;
      // return this.authService.isAuthenticated();
  }
}
