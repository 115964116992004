import { Input } from '@angular/core';
import {SensorData} from "./sensorData";
import {AktorData} from "./aktorData";

export class Sensor {
  id_sensoren: number;
  id_gateways: number;
  name_sensor: string;
  device_id: string;
  signal_time: string;
  typ_sensor: number;
  status: number;
  modus: number;
  checked: boolean;
  sf: number;
  tx: number;
  nr: number;
  lopy: string;
  fw: string;
  ean: string;
  ean_lopy: string;
  grund: number;
  time: number;
  signal_time_aktor: string;
  sensor_data: SensorData[];
  aktor_data: AktorData[];
}

