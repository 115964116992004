import { Input } from '@angular/core';
import {Aktion} from "./aktion";
import {Bedingung} from "./bedingung";

export class Regel {
  id_regel: number;
  id_szenen: number;
  bedingungen: Bedingung[];
  aktionen: Aktion[];
  description: string;
  selected: boolean;

  constructor () {
    this.description = "";
    this.bedingungen = [];
    this.aktionen = [];
    this.selected = false;
  }
}