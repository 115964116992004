import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from '../_services/muu.service';
import {CalcService} from '../_services/calc.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Sensor} from "../_models/sensor";
import * as moment from 'moment';
import {now} from "moment";
import {AddEditDialogComponent} from "../dialogs/add-appiary-dialog.component";
// import * as d3 from "d3";

declare var d3: any;

@Component({
  selector: 'modb-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})

export class SensorComponent implements OnInit, AfterViewInit {
  @Input() sensor: Sensor;
  @Input() sensorVal: number;
  @Output() openApiary: EventEmitter<any> = new EventEmitter();
  @Output() openDetails: EventEmitter<any> = new EventEmitter();

  hovered = false;
  hoveredBar = false;
  statusOk = true;
  thi_chart = undefined;
  thi_start = 50;
  thi_end = 99;
  thi_radius_out = 54;
  thi_radius_in = 40;
  thi_radius_in_simple = 40;
  thi_radius_overlay = 36;
  width = 0;
  height = 0;
  start_angle = -(Math.PI -(Math.PI / 4));
  end_angle = (Math.PI -(Math.PI / 4));
  total_angle = this.end_angle * 2;
  total_count = this.thi_end - this.thi_start + 1;
  draw_simple = true;
  svg = undefined;

  constructor(
    public muuService: MuuService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // let timeOffset = new Date().getTimezoneOffset();
    // timeOffset = (timeOffset / 60) * -1;
    // console.log(timeOffset);
    // var d = moment(this.regler.sensor_data[0].signal_time).add(timeOffset, 'hours'); // moment.duration(my_ms);
    // var duration = moment.duration(moment(new Date()).diff(moment(d)));
    // if (duration.get("minutes") + duration.get("hours")*60 > 45) {
    //   this.statusOk = false;
    // }

    this.statusOk = true;
    if (this.sensor != null) {
      if (this.sensor.modus == 1) {
        this.sensor.checked = false;
      } else {
        this.sensor.checked = true;
      }
    }

    if (this.sensor.sensor_data != null) {
      this.statusOk = this.muuService.isSensorOk(this.sensor.sensor_data[0].signal_time, this.sensor.time);
      // this.sensor.signal_time = this.sensor.sensor_data[0].signal_time;
      this.sensor.grund = 0;
    }

    if (this.sensor.aktor_data != null) {
      // this.statusOk = this.muuService.isSensorOk(this.sensor.aktor_data[0].signal_time);
      // console.log('typ: '+this.sensor.typ_sensor+ "  this.statusOk: "+ this.statusOk);
      this.statusOk = this.muuService.isSensorOk(this.sensor.signal_time, this.sensor.time);
      // console.log('typ: '+this.sensor.typ_sensor+ "  this.statusOk: "+ this.statusOk);
      // this.sensor.signal_time = this.sensor.aktor_data[0].signal_time;
      this.sensor.signal_time_aktor = this.sensor.aktor_data[0].signal_time;
      this.sensor.grund = this.sensor.aktor_data[0].grund;
    }

    if (this.sensor.typ_sensor == 1) {

    } else if (this.sensor.typ_sensor == 2) {

    }
    this.statusOk = true;
  }

  ngAfterViewInit() {
    // this.regler.sensor_data[0].thi = 72;
    if (document.getElementById('chart'+this.sensor.id_sensoren) == null) {
      return
    }

    this.initChart('chart'+this.sensor.id_sensoren);
    if (this.draw_simple) {
      this.drawSimpleChart();
    } else {
      this.drawChart();
    }
  }


  initChart(chartId) {
    d3.select(chartId).remove();

    this.width = document.getElementById(chartId).clientWidth;
    this.height = document.getElementById(chartId).clientHeight;
    this.svg = d3.select('#'+chartId).append('svg')
      .attr('id', 'd3chart')
      .attr('width', this.width)
      .attr('height', this.height);


  }

  drawChartPart(value, svg, full_color) {
    const color = this.muuService.getThiColor(value, true);

    const startAngle = this.getStartAngle(value);
    const endAngle = startAngle + this.getAngleLen();

    var arc = d3.arc()
      .innerRadius(this.thi_radius_in)
      .outerRadius(this.thi_radius_out)
      .startAngle(startAngle)
      .endAngle(endAngle+0.01)
      .cornerRadius(0);

    svg.append("path")
      .style("fill", color)
      .style('opacity', function () {
        if (full_color) {
          return '1';
        } else {
          return '1';
        }
      })
      .attr("d", arc)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');
  }

  drawChartPartOverlay(svg) {
    const color = this.muuService.getThiColor(this.sensor.sensor_data[0].thi, true);
    const startAngle = this.start_angle;
    const endAngle = this.getStartAngle(this.sensor.sensor_data[0].thi) + this.getAngleLen();

    var arcBack = d3.arc()
      .innerRadius(this.thi_radius_overlay)
      .outerRadius(this.thi_radius_in+1)
      .startAngle(this.start_angle)
      .endAngle(this.end_angle+0.01);

    var backgroundValue = svg.append("path")
      .style('fill', '#000')
      .style('opacity', '0.2')
      .attr("d", arcBack)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');


    var arc = d3.arc()
      .innerRadius(this.thi_radius_overlay)
      .outerRadius(this.thi_radius_in+1)
      .startAngle(startAngle)
      .endAngle(endAngle)
      .cornerRadius(0);

    svg.append("path")
      .style("fill", '#000')
      // .style('stroke', '#999')
      // .style('stroke', '#000')
      // .style('stroke-width', '2px')
      .style('opacity', '0.4')
      .attr("d", arc)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');
  }

  getStartAngle(value) {
    let val = ((this.total_angle / (this.total_count)) * (value-this.thi_start));
    val = this.start_angle + val;
    return val;
  }

  // berechnet 1 Stufenlänge am Kreis
  getAngleLen() {
    let val = ((this.total_angle / (this.total_count)));
    return val;
  }

  percToDeg = function(perc) {
    return perc * 360;
  };
  percToRad = function(perc) {
    return this.degToRad(this.percToDeg(perc));
  };
  degToRad = function(deg) {
    return deg * Math.PI / 180;
  };


  drawSimpleChart() {
    const color = this.muuService.getThiColor(this.sensor.sensor_data[0].thi, true);
    const startAngle = this.start_angle;
    const endAngle = this.getStartAngle(this.sensor.sensor_data[0].thi) + this.getAngleLen();

    var arcBack = d3.arc()
      .innerRadius(this.thi_radius_in)
      .outerRadius(this.thi_radius_out)
      .startAngle(this.start_angle)
      .endAngle(this.end_angle)
      .cornerRadius(6);

    var backgroundValue = this.svg.append("path")
      .style('fill', '#000')
      .style('opacity', '0.2')
      .attr("d", arcBack)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');


    var arc = d3.arc()
      .innerRadius(this.thi_radius_in)
      .outerRadius(this.thi_radius_out)
      .startAngle(startAngle)
      .endAngle(endAngle)
      .cornerRadius(6);

    this.svg.append("path")
      .style("fill", color)
      // .style('stroke', '#999')
      // .style('stroke', '#000')
      // .style('stroke-width', '2px')
      .style('opacity', '1')
      .attr("d", arc)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');

    this.svg.append('g')
      .classed('thi-class', true)
      .append('text')
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2 + 24) +')')
      .text('THI');

    // const color = this.getThiColor(this.regler.sensor_data[0].thi, true);
    this.svg.append('g')
      .classed('thi-value', true)
      .append('text')
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2 + 10) +')')
      .style('fill', '#515b5e')
      .text(''+this.sensor.sensor_data[0].thi.toFixed(0));
  }

  drawChart() {
    this.drawChartPartOverlay(this.svg);
    for (let i=this.thi_start; i<=this.thi_end; i++) {
      this.drawChartPart(i, this.svg, false);
    }

    var arc = d3.arc()
      .innerRadius(this.thi_radius_in)
      .outerRadius(this.thi_radius_out)
      .startAngle(this.start_angle)
      .endAngle(this.end_angle)
      .cornerRadius(0);

    var background = this.svg.append("path")
      .style("fill", "none")
      // .style('stroke', '#515b5e')
      // .style('stroke-width', '2px')
      .style('shape-rendering','auto')
      .attr("d", arc)
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2+ 1) + ')');

    this.svg.append('g')
      .classed('thi-class', true)
      .append('text')
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2 + 44) +')')
      .text('THI');

    const color = this.muuService.getThiColor(this.sensor.sensor_data[0].thi, true);
    this.svg.append('g')
      .classed('thi-value', true)
      .append('text')
      .attr('transform', 'translate(' + (this.width/2) +',' + (this.height/2 + 30) +')')
      .style('fill', '#515b5e')
      .text(''+this.sensor.sensor_data[0].thi.toFixed(0));
  }



  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

  changeStyleBar($event) {
    if ($event.type != 'mouseover') {
      this.hoveredBar = false;
    } else {
      this.hoveredBar = true;
    }
  }

  openThisDetails() {
    this.openDetails.emit(this.sensor);
  }

  // editSensorName(event) {
  //   event.stopPropagation();
  //   const dialogRef = this.dialog.open(AddEditDialogComponent);
  //   dialogRef.componentInstance.placeholder = 'Standort bearbeiten';
  //   dialogRef.componentInstance.ok_value = 'Speichern';
  //   dialogRef.componentInstance.edit = true;
  //   dialogRef.componentInstance.namevalue = this.sensor.name_sensor;
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != false) {
  //       const apiaryname = result;
  //       console.log('editSensorName:' + apiaryname);
  //       // TODO - Namen speichern
  //       this.sensor.name_sensor = apiaryname;
  //       const res = this.muuService.setSensorName(this.sensor);
  //       // res is a promise!!!
  //       console.log('res setSensorName:' + res);
  //     } else {
  //       console.error('abbruch');
  //     }
  //   });
  // }

  sendAktorBefehl(event) {
    event.stopPropagation();
    console.log('sendAktorBefehl');
    if (this.sensor.modus == 1) {

      this.snackBar.open("Automatischer Modus aktiv - manuelles Schalten nicht möglich. Klicken Sie" +
        " auf 'Umschalten' um den Modus zu wechseln. Achtung - Regelung nur im automatischen Modus aktiviert!", "", {
        duration: 5000,
      });
    } else {
      this.muuService.setAktorValue(this.sensor);
    }

    // if (this.sensorVal == 0) {
    //   this.sensorVal = 1;
    // } else {
    //   this.sensorVal = 0;
    // }
  }


  switchAktorModus() {
    // event.stopPropagation();
    console.log("switchAktorModus: "+this.sensor.checked);
    console.log("modus: "+this.sensor.modus);
    this.sensor.modus = 2; //Modus 2 = warten auf modus
    this.muuService.switchAktorModus(this.sensor);
  }

  openThisApiary() {
      this.openApiary.emit(this.sensor);
  }
}
