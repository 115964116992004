import { Input } from '@angular/core';
import {Sensor} from "./sensor";
import {MuuService} from "../_services/muu.service";
import * as moment from 'moment';

export class SensorStatus {
  name: string;
  sensor_error: boolean;
  lastStatusTime: string;
  lastStatusMillis: number;
  newestStatusTime: string;
  newestStatusMillis: number;
  thi_status: string;
  thi_status_long: string;
  thi_color: string;
  highest_thi: number;
  error_sensor: string;
  sensor_count: number;
  sensor_not_ok: number;
  aktor_not_ok: number;
  sensor_status: string;
  sensor_status_sub: string;

  //TODO anzeigen was der höchste THI bedeutet
  //anzeigen der letzten Aktionen!
  //Zusammenfassung Sensorstatus -> arbeiten alle Sensoren richtig??

  constructor () {
    this.highest_thi = 0;
    this.sensor_count = 0;
    this.sensor_not_ok = 0;
    this.aktor_not_ok = 0;
    this.thi_status = "";
    this.sensor_error = false;
    this.lastStatusMillis = -1;
    this.newestStatusMillis = -1;
    this.error_sensor = "";
  }

  addSensor(sensor: Sensor, muuService: MuuService) {
    // console.log('addSensor typ: '+sensor.typ_sensor+ " name: "+sensor.name_sensor);
    if (sensor.typ_sensor == 2) {
      // console.log('add aktor this.sensor_count: '+this.sensor_count);
      this.sensor_count++;
      if (sensor.aktor_data == null) {
        this.aktor_not_ok++;
        this.error_sensor = sensor.name_sensor;
        this.updateErrorText();
        console.error('sensor.aktor_data == null');
        return;
      }
      let statusOk = muuService.isSensorOk(sensor.signal_time, sensor.time);
      if (!statusOk) {
        this.aktor_not_ok++;
        this.error_sensor = sensor.name_sensor;
      }

      // console.log('add aktor - statusOk: '+statusOk+ " time: "+sensor.signal_time);

      if ((this.lastStatusMillis == -1) || (moment(sensor.aktor_data[0].signal_time).valueOf() < this.lastStatusMillis)) {
        this.lastStatusMillis = moment(sensor.aktor_data[0].signal_time).valueOf();
        this.lastStatusTime = sensor.aktor_data[0].signal_time;
      }
      if ((this.newestStatusMillis == -1) || (moment(sensor.aktor_data[0].signal_time).valueOf() > this.newestStatusMillis)) {
        this.newestStatusMillis = moment(sensor.aktor_data[0].signal_time).valueOf();
        this.newestStatusTime = sensor.aktor_data[0].signal_time;
      }
      this.updateErrorText();
      return;
    }

    // console.log('addSensor: '+JSON.stringify(sensor));
    this.sensor_count++;
    if (sensor.sensor_data == null) {
      console.error('sensor.sensor_data == null');
      return;
    }
    if (sensor.sensor_data[0].thi > this.highest_thi) {
      this.highest_thi = sensor.sensor_data[0].thi;
    }
    let statusOk = muuService.isSensorOk(sensor.sensor_data[0].signal_time, sensor.time);
    if (!statusOk) {
      this.sensor_not_ok++;
      this.error_sensor = sensor.name_sensor;
    }

    // console.log('addSensor : statusOk'+statusOk);
    // console.log('add sensor - statusOk: '+statusOk+ " time: "+sensor.sensor_data[0].signal_time);
    if ((this.lastStatusMillis == -1) || (moment(sensor.sensor_data[0].signal_time).valueOf() < this.lastStatusMillis)) {
      this.lastStatusMillis = moment(sensor.sensor_data[0].signal_time).valueOf();
      this.lastStatusTime = sensor.sensor_data[0].signal_time;
    }
    if ((this.newestStatusMillis == -1) || (moment(sensor.sensor_data[0].signal_time).valueOf() > this.newestStatusMillis)) {
      this.newestStatusMillis = moment(sensor.sensor_data[0].signal_time).valueOf();
      this.newestStatusTime = sensor.sensor_data[0].signal_time;
    }

    this.updateErrorText();

    this.thi_color = muuService.getThiColor(this.highest_thi, false);
    if (this.highest_thi > 89.5) {
      this.thi_status = "Gefahr";
      this.thi_status_long = "Todesfälle können auftreten";
    } else if (this.highest_thi > 79.5) {
      this.thi_status = "starker Hitzestress";
      this.thi_status_long = "<ul><li>Unwohlsein auf Grund der ansteigenden Symptome</li></ul>";
    } else if (this.highest_thi > 71.5) {
      this.thi_status = "mäßiger Hitzestress";
      this.thi_status_long = "<ul>" +
        "<li>Erhöhte Speichelproduktion</li>" +
        "<li>Erhöhte Atmungsrate</li>" +
        "<li>Erhöhte Herzfrequenz</li>" +
        "<li>Rückgang der Futteraufnahme</li>" +
        "<li>Erhöhte Wasseraufnahme</li>" +
        "<li><b>Rückgang der Milchproduktion</b></li>" +
        "<li><b>Rückgang der Fruchtbarkeit</b></li>" +
        "</ul>";
    } else if (this.highest_thi > 67.5) {
      this.thi_status = "milder Hitzestress";
      this.thi_status_long = "<ul>" +
        "<li>Aufsuchen von Schattenplätzen</li>" +
        "<li>Erhöhte Atmungsrate</li>" +
        "<li>Erweiterung der Blutgefäße</li>" +
        "<li>Erste Auswirkung auf die Milchleistung</b></li>" +
        "</ul>";
    } else {
      this.thi_status = "kein Hitzestress";
      this.thi_status_long = "";
    }
  }

  private updateErrorText() {



    if ((this.aktor_not_ok > 0) && (this.sensor_not_ok > 0)) {
      this.sensor_error = true;
      this.sensor_status = "Sensor- und Aktorfehler";
      this.sensor_status_sub = this.aktor_not_ok + " Fehler bei Aktoren";
      this.sensor_status_sub += " und "+ this.sensor_not_ok + " Fehler bei Sensoren.";

    } else if (this.aktor_not_ok > 0) {
      this.sensor_error = true;
      this.sensor_status = "Aktorfehler";
      if (this.aktor_not_ok < 1) {
        this.sensor_status_sub = "Fehler bei Aktor: " + this.error_sensor;
      } else {
        this.sensor_status_sub = this.aktor_not_ok + " Aktoren haben ein Problem.";
      }
    } else if (this.sensor_not_ok > 0) {
      this.sensor_error = true;
      this.sensor_status = "Sensorfehler";
      if (this.sensor_not_ok < 1) {
        this.sensor_status_sub = "Fehler bei einem Sensor: " + this.error_sensor;
      } else {
        this.sensor_status_sub = this.sensor_not_ok + " Sensoren haben ein Problem.";
      }
    } else {
      this.sensor_status = "OK";
      this.sensor_status_sub = "Alle Sensoren funktionieren einwandfrei.";
    }
  }
}
