import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from "../_services/muu.service";
import {MenuItem} from "../_models/menu";
import {Bedingung} from "../_models/bedingung";

@Component({
  selector: 'modb-bedingung',
  templateUrl: './bedingung.component.html',
  styleUrls: ['./bedingung.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BedingungComponent implements OnInit, OnChanges {
  @Input() bedingung: Bedingung;
  @Input() vorschau: number;
  @Input() reihe: number;
  @Output() editBedingung: EventEmitter<any> = new EventEmitter();
  @Output() deleteBedingung: EventEmitter<any> = new EventEmitter();
  gueltig: boolean;

  constructor(
    private muuService: MuuService,
    private ngZone: NgZone) {
    this.gueltig = false;
  }

  ngOnInit() {
    this.UpdateBeschreibung();
  }
  ngOnChanges() {
    console.log("ngOnChanges()");
    this.UpdateBeschreibung();
  }
  askDeleteBedingung() {
    this.deleteBedingung.emit(this.bedingung);
  }
  editThisBedingung() {
    this.editBedingung.emit(this.bedingung);
  }

  UpdateBeschreibung() {
    if (this.bedingung != undefined) {
      this.muuService.setBeschriebung(this.bedingung);
    }
    //
    //
    //   this.trigger_name = this.muuService.getSensorName(this.bedingung);
    //   this.beschreibung = "";
    //
    //   if (this.bedingung.typ_trigger == 0) {
    //     /* *************************************
    //       SENSOR
    //      */
    //     if (this.bedingung.trigger_port == 0) {
    //       this.beschreibung += "die Temperatur";
    //     } else if (this.bedingung.trigger_port == 1) {
    //       this.beschreibung += "die Luftfeuchtigkeit";
    //     } else if (this.bedingung.trigger_port == 2) {
    //       this.beschreibung += "der THI-Wert";
    //     } else if (this.bedingung.trigger_port == 3) {
    //       this.beschreibung += "der Lichtsensor";
    //     } else if (this.bedingung.trigger_port == 4) {
    //       this.beschreibung += "die Lagesensoren";
    //     }
    //
    //     this.beschreibung += " von " + this.trigger_name;
    //
    //     //Größer oder kleiner
    //     if (this.bedingung.operator == 1) {
    //       this.beschreibung += " den Wert von "+this.bedingung.trigger_value.toFixed(2);
    //     } else if (this.bedingung.operator == 0) {
    //       this.beschreibung += " den Wert von "+this.bedingung.trigger_value.toFixed(2);
    //     } else if (this.bedingung.operator == 6) {
    //       this.beschreibung += " sich um den Wert von +/-"+this.bedingung.trigger_value.toFixed(2);
    //     } else if (this.bedingung.operator == 2) {
    //       this.beschreibung += " vollkommene Dunkelheit";
    //     } else if (this.bedingung.operator == 3) {
    //       this.beschreibung += " geringe Helligkeit ";
    //     } else if (this.bedingung.operator == 4) {
    //       this.beschreibung += " extreme Helligkeit ";
    //     } else if (this.bedingung.operator == 5) {
    //       this.beschreibung += " einen stark veränderten Wert ";
    //     } else if (this.bedingung.operator == 10) {
    //       this.beschreibung += " eine leichte Positionsveränderung ";
    //     } else if (this.bedingung.operator == 11) {
    //       this.beschreibung += " eine mittlere Positionsveränderung ";
    //     } else if (this.bedingung.operator == 12) {
    //       this.beschreibung += " eine starke Positionsveränderung ";
    //     }
    //
    //
    //     if (this.bedingung.trigger_port == 0) {
    //       this.beschreibung += "°C ";
    //     } else if (this.bedingung.trigger_port == 1) {
    //       this.beschreibung += "% ";
    //     } else if (this.bedingung.trigger_port == 2) {
    //       this.beschreibung += " ";
    //     } else if (this.bedingung.trigger_port == 3) {
    //       this.beschreibung += " misst";
    //     } else if (this.bedingung.trigger_port == 4) {
    //       this.beschreibung += " messen";
    //     }
    //
    //     if (this.bedingung.operator == 0) {
    //       this.beschreibung += " überschreitet";
    //     } else if (this.bedingung.operator == 1) {
    //       this.beschreibung += " unterschreitet";
    //     } else if (this.bedingung.operator == 6) {
    //       this.beschreibung += " ändert";
    //     }
    //
    //
    //   } else if (this.bedingung.typ_trigger == 1) {
    //     /* *************************************
    //       AKTOR
    //      */
    //     this.beschreibung += this.trigger_name;
    //
    //     if (this.bedingung.trigger_port == 100) {
    //       this.beschreibung += " eingeschalten wird";
    //     } else if (this.bedingung.trigger_port == 101) {
    //       this.beschreibung += " ausgeschalten wird";
    //     } else if (this.bedingung.trigger_port == 102) {
    //       this.beschreibung += " für "+this.muuService.getTimeStringDesc(this.bedingung.trigger_value)+" eingeschalten ist";
    //     } else if (this.bedingung.trigger_port == 103) {
    //       this.beschreibung += " für "+this.muuService.getTimeStringDesc(this.bedingung.trigger_value)+" ausgeschalten ist";
    //     } else if (this.bedingung.trigger_port == 104) {
    //       this.beschreibung += " für "+this.muuService.getTimeStringDesc(this.bedingung.trigger_value)+" offline ist";
    //     }
    //   } else if (this.bedingung.typ_trigger == 2) {
    //     /* *************************************
    //       UHRZEIT
    //      */
    //     if (this.bedingung.trigger_port == 200) {
    //       this.beschreibung += "es vor "+this.muuService.getTimeString(this.bedingung.trigger_value)+" Uhr ist";
    //     } else if (this.bedingung.trigger_port == 201) {
    //       this.beschreibung += "es nach "+this.muuService.getTimeString(this.bedingung.trigger_value)+" Uhr ist";
    //     } else if (this.bedingung.trigger_port == 202) {
    //       this.beschreibung += "es genau "+this.muuService.getTimeString(this.bedingung.trigger_value)+" Uhr ist";
    //     } else {
    //       this.beschreibung += "noch nicht definiert";
    //     }
    //   }
    // }
  }
}
