import {Regel} from "./regel";

export class Szene {
  id_szenen: number;
  id_regler: number;
  name_szene: string;
  time_start: number;
  time_end: number;
  color: string;
  description: string;
  regeln: Regel[];
  x: number;
  y: number;
  time_start_date: any;
  time_end_date: any;
  selected: boolean;

  constructor(obj?: Object) {
    this.id_szenen =obj['id_szenen'];;
    this.id_regler = obj['id_regler'];;
    this.name_szene = obj['name_szene'];;
    this.time_start = obj['time_start'];;
    this.time_end = obj['time_end'];;
    this.color = obj['color'];;
    this.description = obj['description'];;
    this.selected = false;
    this.x = 0;
    this.y = 0;
    this.calcVars();
  }

  static fromJSONArray(array: Array<Object>): Szene[] {
    return array.map(obj => new Szene(obj));
  }

  calcVars() {
    this.time_start_date = +new Date("Sun Dec 09 00:00:00 GMT+0100 2012") + +(this.time_start * 60 * 1000);
    this.time_end_date = +new Date("Sun Dec 09 00:00:00 GMT+0100 2012") + +(this.time_end * 60 * 1000);
  }

  getRegeln(muuService, id_aktiv) {
    const self = this;
    muuService.getRegelnBySzene(this.id_szenen)
      .subscribe(regeln => {
          // console.log('getRegelnBySzene ende: '+JSON.stringify(regeln));
          if (regeln != null) {
            self.regeln = regeln;
            if (id_aktiv != 0) {
              //TODO - aktive Regel markieren
            }
          } else {
            self.regeln = [];
          }
        },
        err => {
          console.error('There was an error: ' + err);
          self.regeln = [];
        });
  }
}

