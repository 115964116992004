import {EventEmitter, Input, Output} from '@angular/core';
import {Gateway} from './gateway';
import {SensorData} from "./sensorData";
import {HeaderMenuComponent} from "../home/header_menu/headermenu.component";

export class HeaderData {
  id: number;
  title: string;
  menu_items: HeaderMenuComponent[];
}
