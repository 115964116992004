import { Input } from '@angular/core';

export class Aktion {
  id_aktionen: number;
  id_regel: number;
  id_trigger: number;
  typ_trigger: number;
  trigger_value: number;
  trigger_port: number;
  trigger_name: string;
  delay: number;
  level: number;
  reihenfolge: number;
  last_trigger: string;
  message: string;
  beschreibung: string;

  constructor () {
    this.beschreibung = "";
  }
}