import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MuuService} from '../_services/muu.service';
import {CalcService} from '../_services/calc.service';
import * as moment from 'moment';
import {now} from "moment";
import {Gateway} from "../_models/gateway";

declare var d3: any;

@Component({
  selector: 'modb-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class GatewayComponent implements OnInit, AfterViewInit {
  @Input() gateway: Gateway;
  hovered = false;

  constructor(
    public muuService: MuuService) {
  }

  ngOnInit() {
    // console.log('id: chart'+this.regler.id_sensoren );
  }

  ngAfterViewInit() {

  }



  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }


}
