import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatCardModule, MatCheckboxModule, MatRadioModule, MatSidenavModule, MatSlideToggleModule,
  MatMenuModule,
  MatIconModule, MatChipsModule, MatDialogModule, MatSnackBarModule, MatToolbarModule, MatInputModule,
  MatExpansionModule, MatDatepickerIntl, MatDatepickerModule, MatSelectModule, MatStepperModule, MatButtonToggleModule,
  MatTabsModule, MatDividerModule, MatListModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import {HiveComponent} from './hive/hive.component';
import { SignupComponent } from './auth/signup/signup.component';
import { SigninComponent } from './auth/signin/signin.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {AppRoutingModule} from './app-routing.module';
import { HeaderComponent } from './home/header/header.component';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth-guard.service';
import {MuuService} from './_services/muu.service';
import { WeightComponent } from './hive/weight/weight.component';
import {DashComponent,  SnackbarComponent} from './dash/dash.component';
import {StatisticComponent} from './statistic/statistic.component';
import {AddEditDialogComponent} from './dialogs/add-appiary-dialog.component';
import {AppConfig} from './app.config';
import {CalcService} from './_services/calc.service';
import {ChartComponent} from './charts/chart.component';
import {HeaderService} from './_services/header.service';
import {OrderByWeight} from './_pipes/orderbyweight';
import {ServiceModule} from './_services/ServiceModule';
import {SensorComponent} from "./sensor/sensor.component";
import {WeatherComponent} from "./weather/weather.component";
import {DayComponent} from "./weather/day/day.component";
import {MenuComponent} from "./home/menu/menu.component";
import {SensorStatusComponent} from "./sensor/status/sensorstatus.component";
import {SensorenComponent} from "./sensoren/sensoren.component";
import {AktorenComponent} from "./aktoren/aktoren.component";
import {GatewayComponent} from "./gateway/gateway.component";
import {AlarmeComponent, AddActionDialog} from "./alarme/alarme.component";
import {TimersComponent} from "./zeitsteuerung/timers.component";
import {SettingsComponent} from "./settings/settings.component";
import {HeaderMenuComponent} from "./home/header_menu/headermenu.component";
import {ReglerComponent} from "./regler/regler.component";
import {QuestionDialogComponent} from "./dialogs/question-dialog.component";
import {CreateNewDialogComponent} from "./dialogs/create-new-dialog.component";
import {RegelungsDialogComponent} from "./dialogs/regelungs-dialog.component";
import {BedingungComponent} from "./bedingung/bedingung.component";
import {RegelComponent} from "./regel/regel.component";
import {AktionComponent} from "./aktion/aktion.component";
import {SelectedRegelPipe} from "./_pipes/selected_regel";
import {OnlyNumber} from "./_directives/onlynumber";
import {AktionenDialogComponent} from "./dialogs/aktionen-dialog.component";
import {DetailsComponent} from "./details/details.component";
import {SocketService} from "./_services/socket.service";
import {PushNotificationsModule} from "ng-push";
import {MomentModule} from "ngx-moment";
import {EditTimeDialogComponent} from "./dialogs/edit-time-dialog.component";
import {EventsComponent} from "./events/events.component";


@NgModule({
    declarations: [
      AppComponent,
      HiveComponent,
      SensorComponent,
      ReglerComponent,
      MenuComponent,
      HeaderMenuComponent,
      WeatherComponent,
      DayComponent,
      SignupComponent,
      HomeComponent,
      PageNotFoundComponent,
      SigninComponent,
      HeaderComponent,
      WeightComponent,
      DashComponent,
      StatisticComponent,
      DetailsComponent,
      AddEditDialogComponent,
      SnackbarComponent,
      ChartComponent,
      OrderByWeight,
      SensorStatusComponent,
      SensorenComponent,
      AktorenComponent,
      GatewayComponent,
      AlarmeComponent,
      EventsComponent,
      TimersComponent,
      SettingsComponent,
      AddActionDialog,
      QuestionDialogComponent,
      CreateNewDialogComponent,
      RegelungsDialogComponent,
      BedingungComponent,
      RegelComponent,
      AktionComponent,
      SelectedRegelPipe,
      OnlyNumber,
      AktionenDialogComponent,
      EditTimeDialogComponent
    ],
    imports: [
      BrowserModule,
      FormsModule,
      HttpModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      MatCardModule,
      MatCheckboxModule,
      MatRadioModule,
      MatButtonModule,
      MatSlideToggleModule,
      FlexLayoutModule,
      PushNotificationsModule,
      ServiceModule,
      MomentModule,
      MatSidenavModule,
      MatMenuModule,
      MatIconModule,
      MatChipsModule,
      MatDialogModule,
      MatSnackBarModule,
      MatToolbarModule,
      MatInputModule,
      MatExpansionModule,
      MatDatepickerModule,
      MatSelectModule,
      MatStepperModule,
      ReactiveFormsModule,
      MatButtonToggleModule,
      MatTabsModule,
      MatDividerModule,
      MatListModule
    ],
    entryComponents: [
      AddEditDialogComponent,
      SnackbarComponent,
      AddActionDialog,
      QuestionDialogComponent,
      CreateNewDialogComponent,
      RegelungsDialogComponent,
      AktionenDialogComponent,
      EditTimeDialogComponent

    ],
    // exports: [
    //   AddEditDialogComponent,
    // ],
    providers: [
      AuthGuard,
      MuuService,
      AppConfig,
      CalcService,
      SocketService,
      HeaderService],
    bootstrap: [AppComponent]
})
export class AppModule {

}
