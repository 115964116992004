import {
  AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MuuService} from '../_services/muu.service';
import {CalcService} from '../_services/calc.service';
import {Sensor} from "../_models/sensor";
import * as moment from 'moment';
import {now} from "moment";
import {Regler} from "../_models/regler";
import {MatDialog, MatSnackBar} from "@angular/material";
import {Szene} from "../_models/szene";
import {FormControl} from '@angular/forms';
import {QuestionDialogComponent} from "../dialogs/question-dialog.component";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarComponent} from "../dash/dash.component";
import {CreateNewDialogComponent} from "../dialogs/create-new-dialog.component";
import {RegelungsDialogComponent} from "../dialogs/regelungs-dialog.component";
import {Regel} from "../_models/regel";
import {Bedingung} from "../_models/bedingung";
import {Aktion} from "../_models/aktion";
import 'd3';

declare var d3: any;

@Component({
  selector: 'modb-regler',
  templateUrl: './regler.component.html',
  styleUrls: ['./regler.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    QuestionDialogComponent,
    CreateNewDialogComponent
  ]
})

export class ReglerComponent implements OnInit, AfterViewInit {
  @Input() regler: Regler;
  @Output() reloadRegler: EventEmitter<any> = new EventEmitter();
  hovered = false;
  szenen: Szene[] = [];
  dummySzene: Szene;
  dragSzene: Szene;
  // editRegelung = false;

  // d3Array;
  margin = {
    top: 16,
    bottom: 32,
    left: 16,
    right: 16
  };
  left_border = 0;
  right_border = 0;
  rect_width_start = 0;
  start_x = 0;
  rect_start_x = 0;
  rect_end_x = 0;

  width = 0;
  height = 0;
  svg = undefined;
  // dragrect;
  dragbarw = 8;
  start_y = 24;
  regelungsTyp = 0;


  constructor(
    public muuService: MuuService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

    this.dummySzene = new Szene('{"id_szenen":0,"id_regler":0,"name_szene":" -","time_start":0,"time_end":0}');
    this.dummySzene.name_szene = " - ";
  }

  ngOnInit() {
    this.loadSzenes();
  }


  ngAfterViewInit() {
    if (document.getElementById('chart'+this.regler.id_regler) == null) {
      return
    }

    // this.initChart('chart'+this.regler.id_sensoren);
    // this.drawChart();
  }


  setActiveSzene(szene: Szene) {
    console.log('setActiveSzene id:' + szene.id_szenen);

    for (let sze of this.szenen){
      if (sze.id_szenen == szene.id_szenen) {
        sze.selected = true;
        let tempRect = this.svg.select("rect[id='szene"+szene.id_szenen+"']");
        if (tempRect) {
          tempRect.attr('stroke', "#1d5f8b");
          tempRect.attr('fill-opacity', "0.6");

          d3.select("#dragleft"+szene.id_regler)
            .attr("x", this.getXFromTime(szene.time_start)-this.dragbarw/2);
          d3.select("#dragleft"+szene.id_regler).style("fill-opacity", 0);
          d3.select("#dragright"+szene.id_regler)
            .attr("x", this.getXFromTime(szene.time_end)-this.dragbarw/2);
          d3.select("#dragright"+szene.id_regler).style("fill-opacity", 0);

          d3.select("#polyleft"+szene.id_regler)
            .attr('transform', 'translate('+(this.getXFromTime(szene.time_start)-this.dragbarw/2)+',0)');
          d3.select("#polyleft"+szene.id_regler).style("fill-opacity", 1);

          d3.select("#polyright"+szene.id_regler)
            .attr('transform', 'translate('+(this.getXFromTime(szene.time_end)-this.dragbarw/2)+',0)');
          d3.select("#polyright"+szene.id_regler).style("fill-opacity", 1);

          d3.select("#title"+szene.id_szenen)
            .attr("fill", "#0A5E0E");
        }
      } else {
        if (sze.selected) {
          let tempRect = this.svg.select("rect[id='szene"+sze.id_szenen+"']");
          if (tempRect) {
            tempRect.attr('stroke', "#FFFFFF");
            tempRect.attr('fill-opacity', "1");
          }
          d3.select("#title"+sze.id_szenen)
            .attr("fill", "white");
        }
        sze.selected = false;
      }
    }
  }

  deactiveSzene(szene: Szene) {
    // for (let sze of this.szenen){
    //   if (sze.selected) {
    //     let tempRect = this.svg.select("rect[id='szene"+sze.id_szenen+"']");
    //     if (tempRect) {
    //       tempRect.attr('stroke', "#FFFFFF");
    //       tempRect.attr('fill-opacity', "1");
    //     }
    //     d3.select("#title"+sze.id_szenen)
    //       .attr("fill", "white");
    //   }
    //   sze.selected = false;
    // }
  }

  setActiveRegel(regel: Regel, deselect: boolean) {
    console.log("setActiveRegel deselect; "+deselect);
    for (let sze of this.szenen){
      if (sze.selected) {
        for (let reg of sze.regeln) {
          if (deselect) {
            reg.selected = false;
          } else {
            if (reg.id_regel == regel.id_regel) {
              reg.selected = true;
            } else {
              reg.selected = false;
            }
          }
        }
        break;
      }
    }
    // for (let sze of this.szenen){
    //   if (sze.selected) {
    //     for (let reg of sze.regeln) {
    //       // console.log("reg.selected: "+reg.selected);
    //     }
    //     break;
    //   }
    // }
  }

  getActiveRegel() : Regel {
    for (let sze of this.szenen){
      if (sze.selected) {
        for (let reg of sze.regeln) {
          if (reg.selected) {
            return reg;
          }
        }
      }
    }
    return null;
  }

  getTimeFromX(x_value) {
    return Math.round(((60*24) /this.width) *  x_value);
  }

  getXFromTime(time_value) {
    return Math.round((this.width /(60*24)) * time_value); //coords[0];
  }

  getXFromCoord(coord_value) {
    return Math.round(((60*24) / this.width) * coord_value); //coords[0];
  }

  getMouseSzene(coords) {
    let myX = this.getXFromCoord(coords[0]);   //= ((60*24) / this.width) * coords[0];
    for (let szene of this.szenen){
      if(szene.time_start <= myX && (szene.time_end >= myX)) {
        // console.log('getMouseSzene: '+szene.name_szene);
        return szene;
      }
    }
    console.error('KEINE Szene gefunden: '+myX);
    return null;
  }

  dragStart(coords, resize) {
    let thisHelper = this;
    let mySzene = null;
    if (resize) {
      for (let sze of this.szenen) {
        if (sze.selected) {
          mySzene = sze;
          break;
        }
      }
    } else {
      mySzene = thisHelper.getMouseSzene(coords);
      this.setActiveSzene(mySzene);
    }
    if (mySzene != null) {
      thisHelper.dragSzene = mySzene;
      // console.log('drag start: '+mySzene.name_szene);
      thisHelper.start_x = coords[0];
      thisHelper.rect_start_x = thisHelper.getXFromTime(mySzene.time_start);
      thisHelper.rect_end_x = thisHelper.getXFromTime(mySzene.time_end);
      thisHelper.left_border = 0;
      thisHelper.right_border = thisHelper.width;
      const sortedSzenes = thisHelper.szenen.sort(function (a, b) {
        return a.time_start - b.time_start;
      });
      for (let szene of sortedSzenes){
        if (szene != mySzene) {
          if (szene.time_start < mySzene.time_start) {
            thisHelper.left_border = thisHelper.getXFromTime(szene.time_end);
          } else {
            thisHelper.right_border = thisHelper.getXFromTime(szene.time_start);
            break; //es muss nur die nächstgrößere Szene betrachtet werden - daher break;
          }
        }
      }
      thisHelper.rect_width_start = thisHelper.getXFromTime(mySzene.time_end) -
        thisHelper.getXFromTime(mySzene.time_start);
      // console.log('dragstart ok: '+thisHelper.rect_width_start);
      return true;
    } else {
      console.error('dragstart null');
      return false;
    }
  }

  createNewSzene(id_regler, event) {
    event.stopPropagation();
    //richtige Position bestimmen und positionieren!
    let time_start = 0;
    let time_end = 500;
    let gefunden = false;
    let last_time_end = 0;
    const sortedSzenes = this.szenen.sort(function (a, b) {
      return a.time_start - b.time_start;
    });
    for (let szene of sortedSzenes) {
      if (szene.time_start > time_start) {
        gefunden = true;
        time_end = szene.time_start;
        break;
      } else {
        time_start = szene.time_end;
      }
    }
    if (!gefunden) {
      if (time_start < 60*24) {
        gefunden = true;
        time_end = 60*24;
      }
    }
    if (gefunden) {
      console.log('createNewSzene');
      const szene = new Szene('{"id_szenen":0,"id_regler":0,"name_szene":"Neue Szene","time_start":'+time_start+',"time_end":'+time_end+'}');
      szene.id_szenen = 0;
      szene.id_regler = id_regler;
      szene.time_start = time_start;
      szene.time_end = time_end;
      szene.name_szene = "";
      szene.color = "#2196F3";
      szene.description = "";
      const self = this;

      let dialogRef = this.dialog.open(CreateNewDialogComponent, {
        // width: '250px',
        data: {
          hasList: false,
          title: "Abschnitt bearbeiten",
          ok_value: "Speichern",
          placeholder: "Name",
          editvalue: szene.name_szene,
          colorVisible: true,
          descriptionVisible: true,
          description: szene.description,
          color: szene.color,
          szene: szene
        }
      });
      dialogRef.afterClosed().subscribe(return_szene => {
        if ((return_szene != null) && (return_szene != false)) {
          self.muuService.createSzene(return_szene)
            .subscribe(szenen => {
                console.log('createNewSzene ende: '+JSON.stringify(szenen));
                self.loadSzenes();
              },
              err => {
                console.error('There was an error: ' + err);
              });
        }
      });


    } else {
      //Meldung, dass kein Platz gefunden wurde!
      console.error('KEIN PLATZ für neue Szene');
      this.openSnackBar("Hinzufügen nicht möglich, kein Platz für neuen Regelungsabschnitt!")
    }
  }

  openSnackBar(snackmessage){
    this.snackBar.open(snackmessage,"", {
      duration: 2000
    });
  }

  loadSzenes() {
    const self = this;
    this.muuService.getSzenenByRegler(this.regler.id_regler)
      .subscribe(szene => {
          // console.log('getSzenenByRegler ende: '+JSON.stringify(szene));
          if (szene != null) {
            self.szenen = szene;
            self.initChart('chart'+this.regler.id_regler);
            self.drawChart();
            self.getRegelnForSzenen();
          } else {
            self.szenen = [];
          }
        },
        err => {
          console.error('There was an error: ' + err);
          self.szenen = [];
          self.initChart('chart'+self.regler.id_regler);
          self.drawChart();
        });


    // this.exeEleRef.opened.subscribe(x => {
    //   // do something
    //   console.log('ngAfterViewInit opened id: '+JSON.stringify(x));
    // })
  }

  getRegelnForSzenen() {
    for (let szene of this.szenen) {
      szene.getRegeln(this.muuService, 0);
    }
  }

  editSzene(szene): void {
    let dialogRef = this.dialog.open(CreateNewDialogComponent, {
      // width: '250px',
      data: {
        hasList: false,
        title: "Abschnitt bearbeiten",
        ok_value: "Speichern",
        placeholder: "Name",
        editvalue: szene.name_szene,
        colorVisible: true,
        descriptionVisible: true,
        description: szene.description,
        color: szene.color,
        szene: szene
      }
    });
    dialogRef.afterClosed().subscribe(return_szene => {
      if ((return_szene != null) && (return_szene != false)) {
        this.saveSzene(return_szene, true);
      }
    });
  }

  saveSzene(szene, redraw) {
    console.log('saveSzene: '+JSON.stringify(szene));
    const self = this;
    const do_redraw = redraw;

    this.muuService.updateSzene(szene)
      .subscribe(szenen => {
          console.log('createdNewSzene ende: '+JSON.stringify(szenen));
          // self.loadSzenes();
          if (do_redraw) {
            self.initChart('chart'+this.regler.id_regler);
            self.drawChart();
          }
        },
        err => {
          console.error('There was an error: ' + err);
        });
  }

  editRegler(regler): void {
    let dialogRef = this.dialog.open(CreateNewDialogComponent, {
      width: '250px',
      data: {
        hasList: false,
        title: "Steuerung bearbeiten",
        ok_value: "Speichern",
        placeholder: "Name",
        editvalue: regler.name_regler
      }
    });
    dialogRef.afterClosed().subscribe(return_name => {
      if ((return_name != null) && (return_name != false)) {
        regler.name_regler = return_name;
        this.saveRegler(regler, true);
      }
    });
  }

  saveRegler(regler, redraw) {
    console.log('saveRegler: '+JSON.stringify(regler));
    const self = this;
    const do_redraw = redraw;

    this.muuService.updateRegler(regler)
      .subscribe(regler => {
          console.log('updateRegler ende: '+JSON.stringify(regler));
          if (do_redraw) {
            self.initChart('chart'+this.regler.id_regler);
            self.drawChart();
          }
        },
        err => {
          console.error('There was an error: ' + err);
        });
  }

  cancelSave(szene) {
    console.log('cancelSave: '+JSON.stringify(szene));
    this.loadSzenes();
  }

  askDeleteSzene(szene) {
    console.log('askDeleteSzene: '+JSON.stringify(szene));
    const self = this;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        question: "Soll diese Szene wirklich gelöscht werden?",
        question_title: "Szene löschen",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      console.log('askDeleteSzene:' + result);
      // this.openSnackBar('Neuen Standort erstellen: ' + apiaryname);
      if (result == true) {
        this.muuService.deleteSzene(szene)
          .subscribe(szenen => {
              self.loadSzenes();
              // console.log('deleteSzene ende: '+JSON.stringify(szenen));
            },
            err => {
              console.error('There was an error deleteSzene: ' + err);
            });
      }
    });
  }

  askDeleteRegler(regler) {
    console.log('askDeleteRegler: '+JSON.stringify(regler));
    const self = this;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        question: "Soll diese Regelung wirklich gelöscht werden?",
        question_title: "Regelung löschen",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      console.log('askDeleteRegler:' + result);
      if (result == true) {
        this.muuService.deleteRegler(regler)
          .subscribe(regler => {
              console.log('deleteRegler ende: '+JSON.stringify(regler));
              self.reloadRegler.emit();
            },
            err => {
              console.error('There was an error deleteRegler: ' + err);
            });
      }
    });
  }

  askDeleteRegel() {
    let regel = this.getActiveRegel();
    console.log('askDeleteRegler: '+JSON.stringify(regel));
    const self = this;
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      data: {
        question: "Soll diese Regel wirklich gelöscht werden?",
        question_title: "Regel löschen",
      }
    });
    const idMySzene = regel.id_szenen;

    dialogRef.afterClosed().subscribe(result => {
      const apiaryname = result;
      console.log('askDeleteRegel:' + result);
      if (result == true) {
        this.muuService.deleteRegel(regel)
          .subscribe(ergebnis => {
              console.log('askDeleteRegel ende: '+JSON.stringify(ergebnis));
              for (let szene of self.szenen) {
                if (szene.id_szenen = idMySzene) {
                  szene.getRegeln(self.muuService, 0);
                }
              }
              // self.editRegelung = false;
              self.regelungsTyp = 0;
            },
            err => {
              console.error('There was an error askDeleteRegel: ' + err);
            });
      }
    });
  }


  addRegelung(szene, event) {
    // console.log('addRegelung: '+JSON.stringify(szene));
    const regel = new Regel();
    regel.id_regel = 0;
    regel.id_szenen = szene.id_szenen;
    regel.bedingungen = [];
    regel.aktionen = [];
    const self = this;

    this.muuService.createRegel(regel)
      .subscribe(result => {
          console.log('createRegel: '+JSON.stringify(result));
          // regel.id_regel = result.id_regel;
          // self.editRegel(result);
          // editRegelung = true;
          // self.regelungsTyp = 0;
          szene.getRegeln(self.muuService, result.id_regel);
        },
        err => {
          console.error('There was an error deleteRegler: ' + err);
        });
  }

  editRegel(regel) {
    // this.editRegelung = true;
    this.regelungsTyp = 0;
    this.setActiveRegel(regel, false);
  }

  deselectRegel(regel) {
    this.setActiveRegel(regel, true);
  }

  dummy($event) {
    console.log("dummy function");
  }


  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }


  initChart(chartId) {
    const dateParser = d3.timeParse('%H:%M');
    d3.selectAll("#"+chartId+" > *").remove();

    this.width = document.getElementById(chartId).clientWidth - this.margin.left - this.margin.right;
    this.height = document.getElementById(chartId).clientHeight - this.margin.top - this.margin.bottom;

    this.svg = d3.select('#'+chartId)
      .append('svg')
      .attr('id', 'd3chart')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);
  }

  drawChart() {
    const thisHelper = this;
    // console.log(this.data_array);
    for (let szene of this.szenen){
      szene.calcVars();
    }

    var FIT_TIME_DOMAIN_MODE = "fit";
    var FIXED_TIME_DOMAIN_MODE = "fixed";
    var timeDomainMode = FIT_TIME_DOMAIN_MODE;// fixed or fit
    var tickFormat = "%H:%M";

    const chart = this.svg.append('g')
      .classed('display', true)
      .attr('transform', 'translate(' + this.margin.left + ' , ' + (this.margin.top ) + ')');

    var x,y,xAxis,yAxis;
    var timeDomainStart = new Date("Sun Dec 09 00:00:00 GMT+0100 2012")
    var timeDomainEnd = new Date("Mon Dec 10 00:00:00 GMT+0100 2012")

    x = d3.scaleTime()
      .domain([ timeDomainStart, timeDomainEnd ])
      .range([ 0, this.width])
      .clamp(true);

    y = d3.scaleBand()
      .domain(["Szenen"])
      .rangeRound([ 0, this.height], .1);

    xAxis = d3.axisBottom()
      .scale(x)
      .tickFormat(d3.timeFormat(tickFormat))
      .ticks(23)
      .tickPadding(8);

    yAxis = d3.axisLeft()
      .scale(y)
      .tickSize(0);

    chart.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0, " + (this.height) + ")")
      // .transition()
      .call(xAxis);

    chart.append("g")
      .attr("class", "y axis")
      // .transition()
      .call(yAxis)
      .selectAll('text')
      .attr('transform', 'translate(0,0) rotate(-90)')
      .attr('dy', -4)
      .attr('dx', 20);

    // DRAG AND DROP + RESIZE
    let dragright = d3.drag()
      .subject(Object)
      .on("drag", function(d) {

        if (thisHelper.dragSzene != thisHelper.dummySzene) {
          const coords = d3.mouse(this);
          let x_diff = coords[0] - thisHelper.start_x;
          //Max x on the left is x - width
          //Max x on the right is width of screen + (dragbarw/2)
          let planned_x = thisHelper.rect_end_x + x_diff;
          planned_x = Math.max(
            thisHelper.rect_start_x + thisHelper.dragbarw, // es darf nicht kleiner als die linke Seite des rechtecks sein
            Math.min(thisHelper.right_border, planned_x));
          //recalculate width
          thisHelper.dragSzene.time_end = thisHelper.getTimeFromX(planned_x);
          let rectWidth = thisHelper.getXFromTime(thisHelper.dragSzene.time_end) -
            thisHelper.getXFromTime(thisHelper.dragSzene.time_start);

          d3.select("#dragright"+thisHelper.dragSzene.id_regler)
            .attr("x", planned_x -thisHelper.dragbarw/2);

          d3.select("#polyright"+thisHelper.dragSzene.id_regler)
            .attr('transform', 'translate('+(planned_x -thisHelper.dragbarw/2)+',0)');
          // //move the right drag handle
          // //resize the drag rectangle
          // //as we are only resizing from the right, the x coordinate does not need to change
          d3.select("#szene"+thisHelper.dragSzene.id_szenen)
            .attr("width", rectWidth);

          d3.select("#title"+thisHelper.dragSzene.id_szenen)
            .attr("x", planned_x -rectWidth/2)
            .attr("width", rectWidth)
            .call(thisHelper.dotme);

        }
      })
      .on("start", function(d) {
        console.log('dragright d3.event.x: '+d3.event.x);
        thisHelper.dragStart(d3.mouse(this), true);
      })
      .on("end", function(d) {
        thisHelper.saveSzene(thisHelper.dragSzene, false);
      });

    let dragleft = d3.drag()
      .subject(Object)
      .on("drag", function(d) {
        if (thisHelper.dragSzene != thisHelper.dummySzene) {
          const coords = d3.mouse(this);
          let x_diff = coords[0] - thisHelper.start_x;

          let planned_x = thisHelper.rect_start_x + x_diff;
          planned_x = Math.max(
            thisHelper.left_border,
            Math.min(thisHelper.rect_end_x - thisHelper.dragbarw, planned_x));

          d3.select("#dragleft"+thisHelper.dragSzene.id_regler)
            .attr("x", planned_x-thisHelper.dragbarw/2);
          d3.select("#polyleft"+thisHelper.dragSzene.id_regler)
            .attr('transform', 'translate('+(planned_x-thisHelper.dragbarw/2)+',0)');

          thisHelper.dragSzene.time_start = thisHelper.getTimeFromX(planned_x);
          let rectWidth = thisHelper.getXFromTime(thisHelper.dragSzene.time_end) -
            thisHelper.getXFromTime(thisHelper.dragSzene.time_start);

          d3.select("#szene"+thisHelper.dragSzene.id_szenen)
            .attr("x", planned_x);
          d3.select("#szene"+thisHelper.dragSzene.id_szenen)
            .attr("width", rectWidth);

          d3.select("#title"+thisHelper.dragSzene.id_szenen)
            .attr("x", planned_x +rectWidth/2)
            .attr("width", rectWidth)
            .call(thisHelper.dotme);
        }
      })
      .on("start", function(d) {
        console.log('dragleft d3.event.x: '+d3.event.x);
        thisHelper.dragStart(d3.mouse(this), true);
      })
      .on("end", function(d) {
        thisHelper.saveSzene(thisHelper.dragSzene, false);
      });

    let drag = d3.drag()
      .subject(Object)
      .on("drag", function(d) {
        if (thisHelper.dragSzene != thisHelper.dummySzene) {
          const coords = d3.mouse(this);
          //TODO Grenzen für Drag/Drop abfragen!
          let x_diff = d3.mouse(this)[0] - thisHelper.start_x;
          // console.log('x_diff: '+x_diff+" coords: "+coords[0]+ " thisHelper.start_x: "+thisHelper.start_x);
          let planned_x = thisHelper.rect_start_x + x_diff;
          planned_x = Math.max(thisHelper.left_border, Math.min(thisHelper.right_border - thisHelper.rect_width_start, planned_x));

          d3.select("#dragleft"+thisHelper.dragSzene.id_regler)
            .attr("x", planned_x-thisHelper.dragbarw/2);
          d3.select("#dragright"+thisHelper.dragSzene.id_regler)
            .attr("x", planned_x + +thisHelper.getXFromTime(thisHelper.dragSzene.time_end) -
              thisHelper.getXFromTime(thisHelper.dragSzene.time_start)-thisHelper.dragbarw/2);
          d3.select("#polyright"+thisHelper.dragSzene.id_regler)
            .attr('transform', 'translate('+(planned_x + +thisHelper.getXFromTime(thisHelper.dragSzene.time_end) -
              thisHelper.getXFromTime(thisHelper.dragSzene.time_start)-thisHelper.dragbarw/2)+',0)');
          d3.select("#polyleft"+thisHelper.dragSzene.id_regler)
            .attr('transform', 'translate('+(planned_x-thisHelper.dragbarw/2)+',0)');

          d3.select("#szene"+thisHelper.dragSzene.id_szenen)
            .attr("x", planned_x); //d.x = planned_x); //d.x = Math.max(0, Math.min(thisHelper.width - thisHelper.rect_width, d3.event.x)));

          const timeDiff = thisHelper.dragSzene.time_end - thisHelper.dragSzene.time_start;
          thisHelper.dragSzene.time_start = thisHelper.getTimeFromX(planned_x);
          thisHelper.dragSzene.time_end = thisHelper.dragSzene.time_start + timeDiff;

          let rectWidth = thisHelper.getXFromTime(thisHelper.dragSzene.time_end) -
            thisHelper.getXFromTime(thisHelper.dragSzene.time_start);

          d3.select("#title"+thisHelper.dragSzene.id_szenen)
            .attr("x", planned_x +rectWidth/2)
            .attr("width", rectWidth)
            .call(thisHelper.dotme);
        }
      })
      .on("start", function(d) {
        thisHelper.dragStart(d3.mouse(this), false);
      })
      .on("end", function(d) {
        thisHelper.saveSzene(thisHelper.dragSzene, false);
      });

    // console.log('this.szenen: '+JSON.stringify(this.szenen));
    let szenenRects = chart.selectAll("szene.rect")
      .data(this.szenen)
      .enter()
      .append("rect")
      .attr("y", this.start_y)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("id",  function(szene) {
        return ("szene"+szene.id_szenen);
      })
      .attr('fill', function(szene) {
        return (""+szene.color);
      })
      .attr('stroke-width', '0')
      .attr("x", function(szene) {
        return (x(szene.time_start_date));
      })
      .attr("height", this.height - this.start_y -4)
      .attr("width", function(szene) {
        return (x(szene.time_end_date) - x(szene.time_start_date));
      })
      .attr("cursor", "pointer" ) // oder move?!
      .on("mousedown", function() {
        const coords = d3.mouse(this);
        let mySzene = thisHelper.getMouseSzene(coords);
        if (mySzene != null) {
          thisHelper.setActiveSzene(mySzene);
          // console.log('mousedown: '+mySzene.name_szene);
        }
      })
      // .on("mouseup", this.handleMouseOver)
      .on("mouseover", this.handleMouseOver)
      .on("mouseout", this.handleMouseOut)
      .on("click", function() {
        // thisHelper.dragrect = this;
        const coords = d3.mouse(this);
        let mySzene = thisHelper.getMouseSzene(coords);
        if (mySzene != null) {
          thisHelper.setActiveSzene(mySzene);
        }
      })
      .call(drag);


    let szenenTexts = chart.selectAll("szene.text")
      .data(this.szenen)
      .enter()
      .append("text")
      .attr("y", this.start_y+(this.height - this.start_y -4)/2)
      .attr("id",  function(szene) {
        return ("title"+szene.id_szenen);
      })
      .attr("x", function(szene) {
        return (x(szene.time_start_date)+(x(szene.time_end_date) - x(szene.time_start_date))/2);
      })
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("height", this.height - this.start_y-4)
      .style("font-size", "16px")
      .style("font-weight", 600)
      .attr("width", function(szene) {
        return (x(szene.time_end_date) - x(szene.time_start_date));
      })
      .text(function(szene) {
        return szene.name_szene;
      })
      .attr("data-name", function(szene) {
        return szene.name_szene;
      })
      .attr("text-shadow", "1px 1px 1px #000;")
      .call(this.dotme);



    chart.append("polygon")       // attach a polygon to the svg
      .attr("id", "polyright"+this.regler.id_regler)
      .style("stroke", "none")  // colour the line
      .style("fill", "#1d5f8b")     // remove any fill colour
      .attr("fill-opacity", 0)
      .attr("points", "0,0, 0,12, 4,20, 4,22, 3,22, 3,66, 5,66, 5,22, 4,22, 4,20, 8,12, 8,0" );
  // .attr("points", "0,0, 0,12, 4,20, 8,12, 8,0" );

    chart.append("polygon")       // attach a polygon to the svg
      .attr("id", "polyleft"+this.regler.id_regler)
      .style("stroke", "none")  // colour the line
      .style("fill", "#1d5f8b")     // remove any fill colour
      .attr("fill-opacity", 0)
      .attr("points", "0,0, 0,12, 4,20, 4,22, 3,22, 3,66, 5,66, 5,22, 4,22, 4,20, 8,12, 8,0" );
  // .attr("points", "0,0, 0,12, 4,20, 8,12, 8,0" );

    chart.append("rect")
      .attr("x", -2)
      .attr("y", 0)
      .attr("height", this.start_y-1)
      .attr("id", "dragleft"+this.regler.id_regler)
      .attr("width", this.dragbarw)
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("cursor", "ew-resize")
      .call(dragleft);

    chart.append("rect")
      .attr("x", 20)
      .attr("y", 0)
      .attr("id", "dragright"+this.regler.id_regler)
      .attr("height", this.start_y-1) //this.height-10)
      .attr("width", this.dragbarw)
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("cursor", "ew-resize")
      .call(dragright);
  }

  dotme(text) {
    text.each(function() {
      var text = d3.select(this);
      // console.log('data-name: '+JSON.stringify(text.attr("data-name")));
      var words =text.attr("data-name").split(/\s+/);//  text.text().split(/\s+/);

      var ellipsis = text.text('').append('tspan').attr('class', 'elip').text('...');
      var width = parseFloat(text.attr('width')) - ellipsis.node().getComputedTextLength();
      var numWords = words.length;

      var tspan = text.insert('tspan', ':first-child').text(words.join(' '));

      // Try the whole line
      // While it's too long, and we have words left, keep removing words
      while (tspan.node().getComputedTextLength() > width && words.length) {
        words.pop();
        tspan.text(words.join(' '));
      }
      if (words.length === numWords) {
        ellipsis.remove();
      }
    });
  }

  // Create Event Handlers for mouse
  handleMouseOver(d, i) {  // Add interactivity
    // console.log('this.handleMouseOver');
    // Use D3 to select element, change color and size
    d3.select(this).attr({
      fill: "orange"
    });
  }

  handleMouseOut(d, i) {
    // console.log('this.handleMouseOut');
    // Use D3 to select element, change color back to normal
    d3.select(this).attr({
      fill: "black"
    });
    // Select text by id and then remove
    d3.select("#t" + d.x + "-" + d.y + "-" + i).remove();  // Remove text location
  }

}
