import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CalcService} from '../_services/calc.service';
import {HeaderData} from '../_models/headerData';
import {HeaderService} from '../_services/header.service';
import {BeeChart} from '../_models/chart';
import {zoom} from 'd3-zoom';
import {BeeEvent} from "../_models/events";
import {SensorData} from "../_models/sensorData";
import {AddEditDialogComponent} from "../dialogs/add-appiary-dialog.component";
import {MatDialog} from "@angular/material";
import {Sensor} from "../_models/sensor";
import {FormBuilder, FormGroup} from "@angular/forms";
import {EditTimeDialogComponent} from "../dialogs/edit-time-dialog.component";

declare var d3: any;


@Component({
  selector: 'modb-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsComponent implements OnInit {
  mySensor = 0;
  sensor: Sensor;
  @ViewChild('donut') donut: ElementRef;

  constructor(
    public authService: AuthService,
    public muuService: MuuService,
    private calcService: CalcService,
    private route: ActivatedRoute,
    private myElement: ElementRef,
    public dialog: MatDialog,
    private headerService: HeaderService) {

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.mySensor = params['id'];

      const self = this;
      if (this.muuService.sensors != null) {
        this.muuService.sensors.forEach(function (sensor){
          if (sensor != null) {
            if (sensor.id_sensoren == self.mySensor) {
              self.sensor = sensor;
              self.addHeaderData(sensor);
            }
          }
        });
      }
    });
  }

  addHeaderData(sensor: Sensor) {
    const self = this;
    const headerData = new HeaderData();
    if (sensor.typ_sensor == 2) {
      headerData.title = 'Aktor: '+sensor.name_sensor; //+" ("+sensor.device_id+")" ;
    } else {
      headerData.title = 'Sensor: '+sensor.name_sensor; //+" ("+sensor.device_id+")" ;
    }
    headerData.id = 1;
    headerData.menu_items = [];
    this.muuService.addHeaderMenuItem(
      "edit",
      "Bearbeiten",
      "statistic",
      false,
      function(): number {
        self.menuAction(1, sensor);
        return 1;
      }, headerData);
    this.headerService.headerData.next(headerData);
    // console.log('self.myHive: ', this.mySensor);
  }



  menuAction(aufruf, sensor) {
    if (aufruf == 1) {
      console.log('Sensor bearbeiten');
      // this.addRegler();

      const self = this;
      const dialogRef = this.dialog.open(AddEditDialogComponent, {
        width: '250px',
        data: {
          hasList: false,
          title: "Bearbeiten",
          ok_value: "Speichern",
          placeholder: "Name bearbeiten",
          namevalue: sensor.name_sensor
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != false) {
          const apiaryname = result;
          sensor.name_sensor = apiaryname;
          const res = this.muuService.setSensorName(sensor);
          console.log('res setSensorName:' + res);
          self.addHeaderData(sensor);
        } else {
          console.error('abbruch');
        }
      });
    } else if (aufruf == 2) {
      console.log('Sensor Zeit');
      // this.addRegler();

      const self = this;
      const dialogRef = this.dialog.open(EditTimeDialogComponent, {
        width: '250px',
        data: {
          hasList: false,
          title: "Bearbeiten",
          ok_value: "Speichern",
          placeholder: "Zeit bearbeiten",
          time: sensor.time
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != false) {
          const new_time = result;

          if (new_time < 1) {
            // TODO - Fehler anzeigen

          } else {
            sensor.time = new_time;
            const res = this.muuService.setSensorName(sensor);
            console.log('res setSensorName:' + res);
            self.addHeaderData(sensor);
          }
        } else {
          console.error('abbruch');
        }
      });
    } else {
      console.log('unknown aufruf menuAction');
    }
  }
}
