import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DataPoint} from "../../_models/weather/dataPoint";

declare var Skycons: any;

@Component({
  selector: 'modb-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.css'],
  entryComponents: [
  ]
})

export class DayComponent implements OnInit, AfterViewInit {
  @Input() day: DataPoint;
  @Input() big: boolean;
  @Output() openApiary: EventEmitter<any> = new EventEmitter();
  hovered = false;
  fullPath = '';

  constructor() {
    this.fullPath = '../../img/regler.jpg';
  }

  ngOnInit() {
    const self = this;

  }

  ngAfterViewInit() {
    //Copy in all the js code from the script.js. Typescript will complain but it works just fine

    // main, moon, fog, fogbank, cloud, snow, leaf, rain, sun
    var skycons = new Skycons({
      "monochrome": false,
      "colors" : {
        "cloud" : "#AAA"
      }
    });

    // on Android, a nasty hack is needed: {"resizeClear": true}
    // you can add a canvas by it's ID...
    // console.log("icon"+this.day.time);
    if (this.day.icon == "clear-day") {
      skycons.add("icon"+this.day.time, Skycons.CLEAR_DAY);
    } else if (this.day.icon == "clear-night") {
      skycons.add("icon"+this.day.time, Skycons.CLEAR_NIGHT);
    } else if (this.day.icon == "partly-cloudy-day") {
      skycons.add("icon"+this.day.time, Skycons.PARTLY_CLOUDY_DAY);
    } else if (this.day.icon == "partly-cloudy-night") {
      if (this.big) {
        skycons.add("icon"+this.day.time, Skycons.PARTLY_CLOUDY_NIGHT);
      } else {
        skycons.add("icon"+this.day.time, Skycons.CLEAR_DAY);
      }
    } else if (this.day.icon == "cloudy") {
      skycons.add("icon"+this.day.time, Skycons.CLOUDY);
    } else if (this.day.icon == "rain") {
      skycons.add("icon"+this.day.time, Skycons.RAIN);
    } else if (this.day.icon == "sleet") {
      skycons.add("icon"+this.day.time, Skycons.SLEET);
    } else if (this.day.icon == "snow") {
      skycons.add("icon"+this.day.time, Skycons.SNOW);
    } else if (this.day.icon == "wind") {
      skycons.add("icon"+this.day.time, Skycons.WIND);
    }

    if (this.big) {
      skycons.play();
    } else {
      skycons.play();
    }
  }


  changeStyle($event) {
    if ($event.type != 'mouseover') {
      this.hovered = false;
    } else {
      this.hovered = true;
    }
  }

  openThisApiary() {
      // this.openApiary.emit(this.regler);
  }
}
