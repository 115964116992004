import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {MuuService} from '../_services/muu.service';
import * as moment from 'moment';
import {MatDialog, MatDialogRef, MatSnackBar, MatSidenavModule, MAT_DIALOG_DATA} from '@angular/material';
import {AddEditDialogComponent} from '../dialogs/add-appiary-dialog.component';
import {Router} from '@angular/router';
import {HeaderService} from '../_services/header.service';
import {HeaderData} from '../_models/headerData';
import {ActionItem} from "../_models/action";
import {Regler} from "../_models/regler";
import {CreateNewDialogComponent} from "../dialogs/create-new-dialog.component";

declare var d3: any;

@Component({
  selector: 'modb-timers',
  templateUrl: './timers.component.html',
  styleUrls: ['./timers.component.css'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [
    AddEditDialogComponent
  ]
})
export class TimersComponent implements OnInit {
  toggleval = 'standard';
  timers: ActionItem[] = [];

  constructor(public muuService: MuuService,
              private router: Router,
              private headerService: HeaderService,
              public dialog: MatDialog) {
    this.timers = [];
    this.muuService.addActionItem(this.timers, 1, "primary", "PUSH-Nachricht");
    this.muuService.addActionItem(this.timers, 2, "primary", "Ventilator 15 Minuten");
  }

  einschalten(value) {
    console.log('einschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 1;
  }
  ausschalten(value) {
    console.log('ausschalten: '+this.muuService.sensorVal);
    this.muuService.sensorVal = 0;
  }

  ngOnInit() {
    this.loadData();
    const headerData = new HeaderData();
    headerData.title = 'Steuerung';
    headerData.id = 2;
    headerData.menu_items = [];
    const self = this;
    // this.muuService.addHeaderMenuItem(
    //   "add",
    //   "Hinzufügen",
    //   "timers",
    //   false,
    //   function(): number {
    //     console.log('addHeaderMenuItem func 3');
    //     return 3;
    //   }, headerData);
    this.muuService.addHeaderMenuItem(
      "add",
      "Hinzufügen",
      "timers",
      false,
      function(): number {
        self.menuAction(1);
        return 1;
      }, headerData);

    this.headerService.headerData.next(headerData);
  }

  loadData() {
    const self = this;
    self.muuService.regler = [];
    this.muuService.getRegler()
      .subscribe(regler => {
          if (regler != null) {
            regler.forEach(function(reg) {
              self.muuService.regler.push(reg);
            });
          }
          console.log('getRegler: '+self.muuService.regler);
        },
        err => {
          console.error('There was an error: ' + err);
        });
  }

  openSensor(sensor) {
    console.log('openSensor event: ', sensor.id_sensoren);
    this.router.navigate(['/statistic/'+sensor.id_sensoren]);
  }

  menuAction(aufruf) {
    if (aufruf == 1) {
      console.log('Regler Hinzufügen menuAction');
      this.addRegler();
    } else {
      console.log('unknown aufruf menuAction');
    }
  }

  addRegler(): void {
    //TODO es darf kein Aktor eine Regelung bekommen, wenn er als Regler bei anderen eingesetzt wird!
    let list = [];
    const self = this;
    if (this.muuService.sensors != null) {
      this.muuService.sensors.forEach(function (sensor) {
        //TODO Liste mit den Sensoren übergeben, die noch keine Regelung haben!
        if (sensor.typ_sensor != 2) {
          self.muuService.addActionItem(list, sensor.id_sensoren, "primary", sensor.name_sensor);
        }
      });
    }

// this.addAction(this.vent_actions);
    const dialogRef = this.dialog.open(CreateNewDialogComponent, {
      // width: '250px',
      data: {
        hasList: false,
        list: list,
        title: "Neue Steuerungsgruppe",
        ok_value: "Erstellen",
        placeholder: "Name",
      }
    });
    dialogRef.afterClosed().subscribe(return_name => {
      console.log('The dialog was closed: '+return_name);
      if ((return_name != null) && (return_name != false)) {
        // list.push(regler);
        //TODO Regler in Datenbank eintragen und reload machen
        const regler = new Regler();
        regler.id_regler = 0;
        regler.name_regler = ""+return_name;
        regler.is_aktiv = 1;
        regler.create_time = "";
        // console.log('res createRegler:' + JSON.stringify(regler));
        this.muuService.createRegler(regler).then(ergebnis => {
            console.log('res createRegler:' + ergebnis);
            this.loadData();
          }
        );
      }
    });
  }
}